import React, { useEffect } from 'react';
import { Form, Input } from 'antd';

import 'moment/locale/ru';

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

export default function UpdateTranslate(props) {
    const { object } = props;

    // const gender = useSelector(REDUX_Gender.selector.array);
    // useEffect(() => {
    //     CRUDE_Gender.read(auth, (data) => {
    //         if (data.length > 0) {
    //             dispatch(REDUX_Gender.actions.setArray(data));
    //         }
    //     }, 'model');
    // }, []);
    // const options = gender.map(i => (
    //     <Option key={i.ID} value={i.ID}>{i.name}</Option>
    // ));

    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });

    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{
                ...object
            }}
        >
            <Form.Item
                name={"ru"}
                label={"Русский"}
                rules={[{ required: true, message: 'Укажите значение!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"en"}
                label={"Английский"}
                rules={[{ required: false, message: 'Укажите значение!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"es"}
                label={"Испанский"}
                rules={[{ required: false, message: 'Укажите значение!' }]}
            >
                <Input />
            </Form.Item>
        </Form>
    );
}
