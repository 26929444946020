import React, { useState, useEffect } from 'react';
import { useTranslateContext, useUserConfigContext } from "../Context";

export const POST = (auth, url, object, callback, error) => {
    auth.fetch(url, {
        method: 'POST',
        body: JSON.stringify(object)
    }).then(res => {
        if (res && res.status === true) {
            if (callback) {
                callback(res.data);
            }
        } else if (res && res.status === false) {
            if (error) {
                error(res, "fail");
            }
        }
    }).catch(function (err) {
        if (error) {
            error(err, "error");
        }
    });
};

export const GET = (auth, url, callback, error) => {
    auth.fetch(url).then(res => {
        if (res && res.status === true && res.data) {
            if (callback) {
                callback(res.data);
            }
        } else if (res && res.status === false) {
            if (error) {
                error(res, "fail");
            }
        }
    }).catch(function (err) {
        if (error) {
            error(err, "error");
        }
    });
};

export const T = (props) => {
    const { value, children } = props;
    const translate = useTranslateContext();
    const config = useUserConfigContext();
    let v = value || children;

    return <>{t(v, translate, config)}</>
};
export const t = (value, translate, config) => {
    let translateFunc = translate.bind({translate, config});
    return translateFunc(value);
};

window.dict = {};

export function translate (value) {
    window.dict[value.toLowerCase().replaceAll(' ', '')] = value;
    // console.log({
    //     key: value.toLowerCase().replaceAll(' ', ''),
    //     ru: value
    // });

    if (this.config && this.translate && value) {
        let key = value.toLowerCase().replaceAll(' ', '');
        if (this.translate[key]) {
            let v = this.translate[key][(this.config.lang) ? this.config.lang : "ru"];
            if(!v || v === ""){
                return value;
            }
            return v
        }
    }
    return value;
}