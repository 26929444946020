const initialState = {
  user: {},
}

// const mergeByProperty = (target, source, prop) => {
//   source.forEach(sourceElement => {
//     let targetElement = target.find(targetElement => {
//       return sourceElement[prop] === targetElement[prop];
//     })
//     targetElement ? Object.assign(targetElement, sourceElement) : target.unshift(sourceElement);
//   })
// }

export function homeReducer(state = initialState, action) {
  switch (action.type) {
    case 'USER':
      return { ...state, user: action.payload }
    default:
      return state
  }
}
