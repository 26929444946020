import React, { useEffect } from 'react';
import { Form, Input, InputNumber } from 'antd';

import 'moment/locale/ru';

export default function GoNextCountDancer(props) {
    const { object } = props;
    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });
    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{
                tourID: object.tourID,
                genderID: object.genderID
            }}
        >
        <Form.Item
                        preserve={"true"}
                        hidden={true}
                name={"tourID"}
                label={"Тур"}
                rules={[{ required: true, message: 'Укажите тур!' }]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                            preserve={"true"}
                            hidden={true}
                name={"genderID"}
                label={"Пол"}
                rules={[{ required: true, message: 'Укажите пол!' }]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name={"count"}
                label={"Количество"}
                rules={[{ required: true, message: 'Укажите количество!' }]}
            >
                <InputNumber min={1} style={{width: "100%"}}/>
            </Form.Item>
        </Form>
    );
}
