// https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
// https://stackoverflow.com/questions/58624200/react-hook-useeffect-has-a-missing-dependency-dispatch
import React, { useState, useEffect } from 'react';
import {
	Switch,
	Route,
	withRouter
} from "react-router-dom";
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Redirect } from 'react-router';
import { Affix, Layout, Breadcrumb, Avatar, Button, Tooltip, Menu, Badge, Divider, Dropdown, Row, Col, Tag, Typography } from 'antd';
import {
	UserOutlined,
	LogoutOutlined,
	FullscreenOutlined,
	FullscreenExitOutlined,
	CalendarOutlined,
	MenuOutlined,
	AppstoreAddOutlined,
	BuildOutlined,
	BlockOutlined,
	TeamOutlined,
	BankOutlined,
	GroupOutlined,
	ProfileOutlined,
	OrderedListOutlined,
	UploadOutlined,
	SettingOutlined,
	PieChartOutlined
} from '@ant-design/icons';
import Hotkeys from 'react-hot-keys';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

import CommonMain from '../CommonMain'
import CommonEvents from '../CommonEvents'
import CommonGroups from '../CommonGroups'
import CommonJudge from '../CommonJudge'
import CommonDancer from '../CommonDancer'
import CommonTour from '../CommonTour'
import CommonBunch from '../CommonBunch'
import CommonCommands from '../CommonCommands'
import REDUX_UserConfig from '../../objects/UserConfig/reducer'
import CRUDE_UserConfig from '../../objects/UserConfig/crude'

import CriteriontemplateTemplate from '../CriteriontemplateTemplate'
import CriteriontemplateCriterion from '../CriteriontemplateCriterion'
import CriteriontemplatePriority from '../CriteriontemplatePriority'

import RatingUpload from '../RatingUpload'
import SettingsTranslate from '../SettingsTranslate'

import REDUX_Protocol from '../../objects/Protocol/reducer'
import REDUX_Translate from '../../objects/Translate/reducer';
import CRUDE_Translate from '../../objects/Translate/crude';
// import REDUX_ZasedaniyaPravleniyaFederatsii from '../../objects/ZasedaniyaPravleniyaFederatsii/reducer';

import 'antd/dist/antd.css';
import './index.css';

import 'rc-banner-anim/assets/index.css';
import { POST, t, translate } from '../../components/Tool';
import { TranslateContext, UserConfigContext } from '../../components/Context';
var _ = require('lodash');

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

library.add(fas)

const compare = (a, b) => {
	return a.replace(" ", "").toLowerCase() === b.replace(" ", "").toLowerCase();
};
const statusForPage = (status, element, onlyNew) => {
	var deep = 1;
	var o = element;
	while (o && o.parent) {
		deep++
		o = o.parent;
	}
	return status.filter((s) => {
		if (onlyNew && s.tipNovayaInfa === false) return false;
		switch (deep) {
			case 1:
				if (element && compare(s.tipMestonakhozhdenieMenyu, element.name)) {
					return true;
				}
				break;
			case 2:
				if (element.parent && compare(s.tipMestonakhozhdenieMenyu, element.parent.name) &&
					element && compare(s.tipMestonakhozhdeniePodMenyu, element.name)) {
					return true;
				}
				break;
			case 3:
				if (element.parent.parent && compare(s.tipMestonakhozhdenieMenyu, element.parent.parent.name) &&
					element.parent && compare(s.tipMestonakhozhdeniePodMenyu, element.parent.name) &&
					element && compare(s.tipMestonakhozhdenieZakladka, element.name)) {
					return true;
				}
				break;
			default:
				return false;
		}
		return false;
	})
};
const notifyForPage = (notify, element, onlyNew) => {
	var deep = 1;
	var o = element;
	while (o && o.parent) {
		deep++
		o = o.parent;
	}
	return notify.filter((s) => {
		if (onlyNew && s.prochitano === true) return false;
		switch (deep) {
			case 1:
				if (element && compare(s.mestonakhozhdenieMenyu, element.name)) {
					return true;
				}
				break;
			case 2:
				if (element.parent && compare(s.mestonakhozhdenieMenyu, element.parent.name) &&
					element && compare(s.mestonakhozhdeniePodMenyu, element.name)) {
					return true;
				}
				break;
			case 3:
				if (element.parent.parent && compare(s.mestonakhozhdenieMenyu, element.parent.parent.name) &&
					element.parent && compare(s.mestonakhozhdeniePodMenyu, element.parent.name) &&
					element && compare(s.mestonakhozhdenieZakladka, element.name)) {
					return true;
				}
				break;
			default:
				return false;
		}
		return false;
	})
};
const menu = (status, notify, role_by_id) => {
	const badge = (element) => {
		var stat = statusForPage(status, element, true);
		var noty = notifyForPage(notify, element, true);
		return {
			count: stat.length + noty.length
		}
	};
	const repack = (menu, role_by_id, parent) => {
		var arr = [];
		for (let i = 0; i < menu.length; i++) {
			const element = menu[i];
			if (element.func) {
				var es = element.func(element, role_by_id);
				if (element.title) {
					var o = {
						uuid: element.uuid,
						path: element.path,
						title: element.title,
						role: element.role,
						group: es.map(e => {
							if (e.menu) {
								e.menu = repack(e.menu, role_by_id);
							}
							e.role = element.role;
							return e
						}),
						badge: badge,
						parent: parent
					};
					if (o.menu) {
						o.menu = repack(o.menu, role_by_id, element);
					}
					arr.push(o);
				} else {
					es.forEach(e => {
						if (e.menu) {
							e.menu = repack(e.menu, role_by_id, element);
						}
						e.role = element.role;
						e.badge = badge;
						e.parent = parent;
						arr.push(e);
					});
				}
			} else {
				if (element.menu) {
					element.menu = repack(element.menu, role_by_id, element);
				}
				element.badge = badge;
				element.parent = parent;
				arr.push(element);
			}
		}
		return arr;
	}
	const x = [
		{
			uuid: "common",
			default: true,
			name: "Общее",
			menu: [
				// {
				// 	uuid: "main",
				// 	default: true,
				// 	name: "Главная",
				// 	icon: function () {
				// 		return (<MehOutlined />)
				// 	}
				// },
				{
					uuid: "judge",
					default: true,
					name: "Судьи",
					icon: function () {
						return (<BankOutlined />)
					}
				},
				{
					uuid: "dancer",
					default: true,
					name: "Танцоры",
					icon: function () {
						return (<UserOutlined />)
					}
				},
				{
					uuid: "events",
					default: true,
					name: "Мероприятие",
					icon: function () {
						return (<CalendarOutlined />)
					}
				},
				{
					uuid: "groups",
					default: true,
					name: "Группы",
					icon: function () {
						return (<AppstoreAddOutlined />)
					}
				},
				{
					uuid: "commands",
					default: true,
					name: "Команды",
					icon: function () {
						return (<TeamOutlined />)
					}
				},
				{
					uuid: "tour",
					default: true,
					name: "Туры",
					icon: function () {
						return (<BlockOutlined />)
					}
				},
				{
					uuid: "bunch",
					default: true,
					name: "Заходы",
					icon: function () {
						return (<BuildOutlined />)
					}
				}
			]
		},
		{
			uuid: "criteriontemplate",
			default: true,
			name: "Критерии",
			menu: [
				{
					uuid: "template",
					default: true,
					name: "Шаблоны",
					icon: function () {
						return (<GroupOutlined />)
					}
				},
				{
					uuid: "criterion",
					default: true,
					name: "Критерии",
					icon: function () {
						return (<ProfileOutlined />)
					}
				},
				{
					uuid: "priority",
					default: true,
					name: "Приоритет",
					icon: function () {
						return (<OrderedListOutlined />)
					}
				}
			]
		},
		{
			uuid: "rating",
			default: true,
			name: "Рейтинг",
			menu: [
				{
					uuid: "upload",
					default: true,
					name: "Загрузка",
					icon: function () {
						return (<UploadOutlined />)
					}
				}
			]
		},
		{
			uuid: "settings",
			default: true,
			name: "Настройки",
			menu: [
				{
					uuid: "translate",
					default: true,
					name: "Перевод",
					icon: function () {
						return (<SettingOutlined />)
					}
				}
			]
		},
	]
	var m = repack(x, role_by_id);
	return m
};
function MenuLevelOne({ active, menu, onChange }) {
	if (menu.length <= 0) return (<></>);
	const elements = menu.map((element, index) => {
		const key = element.uuid || element.key || index;
		const icon = (element.icon) ? element.icon(element) : <></>;
		if (element.badge && element.badge(element).count > 0) {
			return (
				<Menu.Item key={key}>
					<Badge dot={true} offset={[5, 0]}>
						{icon}{element.name}
					</Badge>
				</Menu.Item>
			)
		}
		return (<Menu.Item key={key}>{element.name}</Menu.Item>)
	})
	const change = ({ key }) => {
		onChange(key, menu);
	}
	return (
		<Menu theme="light" mode="horizontal" style={{ float: 'left' }} onClick={change}
			defaultSelectedKeys={[active]}
			selectedKeys={[active]}>
			{elements}
		</Menu>
	)
}
function MenuLevelTwo({ active, menu, onChange }) {
	if (menu.length <= 0) return (<></>);
	const style = {
		marginBottom: "0px",
		marginTop: "0px",
		fontSize: "13px",
		paddingRight: "10px"
	}
	const l = (element, key) => {
		const count = (element.badge) ? element.badge(element).count : 0;
		const icon = (element.icon) ? element.icon(element) : <></>;
		return (
			<Menu.Item style={style} key={key}>
				<Tooltip title={element.name} mouseEnterDelay={0.8}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
							{icon}{element.name}
						</div>
						<div style={{ width: "37px", textAlign: "right" }}>
							{count > 0 && <Badge color="#ff4d4f" />}
							{/* <Badge count={count} overflowCount={99} /> */}
						</div>
					</div>
				</Tooltip>
			</Menu.Item>
		)
	}
	const elements = menu.map((element, index) => {
		const key = element.uuid || element.key || index;
		if (element.group) {
			const ui = element.group.map(e => {
				return l(e, e.uuid)
			})
			return (
				<SubMenu
					key={element.uuid}
					title={<span style={style}>{element.title}</span>}
					inlineIndent={0}
				>
					{ui}
				</SubMenu>

				// <Menu.ItemGroup title={
				// 	<>
				// 		{/* <Menu.Divider></Menu.Divider> */}
				// 		{element.title && <Divider type="horizontal"
				// 			orientation="left"
				// 			style={{ margin: "12px 0", fontSize: "13px", fontWeight: "600", padding: "0px 15px 0px 5px" }} >
				// 			{element.title}
				// 		</Divider>}
				// 	</>} key={key}>
				// 	{ui}
				// </Menu.ItemGroup>
			)
		} else {
			return l(element, key)
		}
	})
	const change = ({ key }) => {
		onChange(key, menu)
	}
	return (
		<>
			<Divider type="horizontal"
				orientation="left"
				style={{ margin: "12px 0", fontSize: "13px", fontWeight: "600", padding: "0px 15px 0px 5px" }} >
				Подменю
			</Divider>
			<Menu
				mode="inline"
				defaultSelectedKeys={[active]}
				selectedKeys={[active]}
				// defaultOpenKeys={['sub1']}
				style={{ border: "none" }}
				inlineIndent={10}
				onClick={change}
			>
				{elements}
			</Menu>
		</>
	)
}
function MenuLevelThree({ active, menu, onChange }) {
	if (menu.length <= 0) return (<></>);
	const style = {
		marginBottom: "0px",
		marginTop: "0px",
		fontSize: "13px",
		paddingRight: "10px"
	}
	const l = (element, key) => {
		const count = (element.badge) ? element.badge(element).count : 0;
		const icon = (element.icon) ? element.icon(element) : <></>;
		return (
			<Menu.Item style={style} key={key}>
				<Tooltip title={element.name} mouseEnterDelay={0.8}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
							{icon}{element.name}
						</div>
						<div style={{ width: "37px", textAlign: "right" }}>
							{/* {count > 0 && <Badge color="#ff4d4f" />} */}
							<Badge count={count} overflowCount={99} />
						</div>
					</div>
				</Tooltip>
			</Menu.Item>
		)
	}
	const elements = menu.map((element, index) => {
		const key = element.uuid || element.key || index;
		if (element.group) {
			const ui = element.group.map(e => {
				return l(e, e.uuid)
			})
			return (
				<SubMenu
					key={element.uuid}
					title={<span style={style}>{element.title}</span>}
					inlineIndent={0}
				>
					{ui}
				</SubMenu>

				// <Menu.ItemGroup title={
				// 	<>
				// 		{/* <Menu.Divider></Menu.Divider> */}
				// 		{element.title && <Divider type="horizontal"
				// 			orientation="left"
				// 			style={{ margin: "12px 0", fontSize: "13px", fontWeight: "600", padding: "0px 15px 0px 5px" }} >
				// 			{element.title}
				// 		</Divider>}
				// 	</>} key={key}>
				// 	{ui}
				// </Menu.ItemGroup>
			)
		} else {
			return l(element, key)
		}
	})
	const change = ({ key }) => {
		onChange(key, menu)
	}
	return (
		<>
			<Divider type="horizontal"
				orientation="left"
				style={{ margin: "12px 0", fontSize: "13px", fontWeight: "600", padding: "0px 15px 0px 5px" }} >
				Закладки
			</Divider>
			<Menu
				mode="inline"
				defaultSelectedKeys={[active]}
				selectedKeys={[active]}
				// defaultOpenKeys={['sub1']}
				style={{ border: "none" }}
				inlineIndent={10}
				onClick={change}
			>
				{elements}
			</Menu>
		</>
	)
}

const mergeProtocolParamValue = (protocol, protocolDancerParam, value) => {
	var ptcl = {
		...protocol,
		protocolDancer: protocol.protocolDancer.map(p => {
			return {
				...p,
				protocolDancerParams: p.protocolDancerParams.map(k => {
					if (k.ID === protocolDancerParam.ID) {
						return { ...k, value: value }
					}
					return k;
				})
			};
		}
		)
	};
	return ptcl;
};

function Home(props) {
	const { history, auth } = props;

	const dispatch = useDispatch();

	const userConfig = useSelector(REDUX_UserConfig.selector.object);
	const translates = useSelector(REDUX_Translate.selector.object);
	const t = React.useMemo(() => _.bind(translate, {
		translate: translates,
		config: userConfig
	}), [translates, userConfig]);


	// console.log(t("да"));


	const user = useSelector(state => state.home.user);
	// const [state, dispatch] = useReducer(reducer, initialArg, init);
	const [rightSider] = useState(false);
	const [fullwidth, setFullwidth] = useState(false);
	// const [] = useState('light');
	// const [] = useState('inline');

	const currentlevel1 = useSelector(state => state.menu.currentlevel1);
	const currentlevel2 = useSelector(state => state.menu.currentlevel2);
	const currentlevel3 = useSelector(state => state.menu.currentlevel3);

	useEffect(() => {
		dispatch({ type: "PATH", payload: history.location.pathname.replace(/\/$/, "") });
	}, [dispatch, history]);
	useEffect(() => {
		const onRouteChange = () => {
			history.push("/" + currentlevel1 + "/" + currentlevel2 + "/" + currentlevel3);
		}
		onRouteChange();
	}, [currentlevel1, currentlevel2, currentlevel3, history]);

	useEffect(
		() => {
			auth.fetch('/api/meta').then(res => {
				if (res && res.data && dispatch) {
					dispatch({ type: 'META', payload: res.data })
				}
			});
			auth.fetch('/api/iam').then(res => {
				if (res && res.data) {
					dispatch({ type: 'USER', payload: res.data })
					// dispatch({ type: 'ROLE', payload: res.data.vseTipiPolzovatelei })
				}
			});
			CRUDE_Translate.read(auth, (data) => {
				if (data.length > 0) {
					let o = Object.fromEntries(data.map(i => {
						return [
							i.key,
							{
								ru: i.ru,
								en: i.en,
								es: i.es
							},
						]
					}));
					dispatch(REDUX_Translate.actions.setObject(o));
				}
			}, 'model');
			CRUDE_UserConfig.read(auth, (data) => {
				if (data.length > 0) {
					var obj = Object.fromEntries(data.map(i => {
						return [
							i.key,
							i.value
						]
					}));
					dispatch(REDUX_UserConfig.actions.setObject(obj));
				}
			}, 'model', '');
			auth.openSocket("/ws/service", "service", function (type, payload) {
				if (type === "reload") {
					var href = window.location.href
					window.location.href = href;
				}
				// console.log(type, payload);
				switch (type) {
					case "protocol":
						dispatch(REDUX_Protocol.actions.updateArrayByKey(payload.judgeBunch.bunch.tour.ID, [payload]));
						break;
					case "ProtocolDancerParamsValue":
						// console.log("ProtocolDancerParamsValue", payload);
						dispatch(REDUX_Protocol.actions.updateArrayPartialGlobalByKey(payload, (protocol, protocolParam) => {
							// console.log(protocol, protocolParam);
							if (protocol.ID === protocolParam.protocolDancer.protocolID) {
								return mergeProtocolParamValue(protocol, protocolParam, protocolParam.value);
							}
							return protocol;
						}));
						break;
					default:
						break;
				}
			});
			return () => {
				auth.closeSocket("service");
			};
		},
		[auth, dispatch /* TODO: memoization parameters here */]
	);

	const item = (level, key) => {
		var levelkey = key || xdefault(level, key)
		var i = level.find((i, index) => {
			const key = i.uuid || i.key || index;
			return key === levelkey
		})
		if (!i) {
			for (let i = 0; i < level.length; i++) {
				const element = level[i];
				if (element.group) {
					i = item(element.group, levelkey)
				}
				if (i) break;
			}
		}
		return i;
	}
	const xdefault = (level, levelkey) => {
		if (level.length <= 0) return "";
		var item = level.find((item, index) => {
			const key = item.uuid || item.key || index;
			return key === levelkey
		})
		if (item) {
			const key = item.uuid || item.key;
			return key
		} else {
			var def = level.find((item) => {
				return item.default && item.default === true
			})
			if (!def) {
				for (let i = 0; i < level.length; i++) {
					const element = level[i];
					if (element.group) {
						var d = xdefault(element.group, levelkey)
						if (d) {
							return d.uuid;
						}
					}
				}
				return level[0].uuid;
			}
			if (def) {
				return def.uuid;
			}
		}
		return ""
	};
	// const roles = (item) => {
	// 	var arr = []
	// 	if (item.role) {
	// 		arr = arr.concat(item.role)
	// 	}
	// 	if (item.menu) {
	// 		arr = arr.concat(item.menu.map(element => roles(element)).flat(Infinity).filter(f => f && (!item.role || item.role.findIndex(x => x.ID === f.ID) === -1)))
	// 	}
	// 	if (item.group) {
	// 		arr = arr.concat(item.group.map(element => roles(element)).flat(Infinity).filter(f => f && (!item.role || item.role.findIndex(x => x.ID === f.ID) === -1)))
	// 	}
	// 	return arr;
	// };
	const access = () => {
		return true;
	};
	const view = (item) => {
		if (item.view) {
			return item.view();
		}
		return true;
	};
	const level = (level, levelkey) => {
		if (levelkey === "") {
			return level.filter(item => {
				return access(item) && view(item);
			});
		}
		var i = item(level, levelkey)
		if (i && i.menu) {
			return i.menu.filter(ii => {
				return access(ii) && view(ii);
			});
		}
		if (i && i.group) {
			return i.group.filter(ii => {
				return access(ii) && view(ii);
			});
		}
		return [];
	}

	const levelOneChange = (key, menu) => {
		const level2 = level(menu, key)
		const level2Key = xdefault(level2, "")
		const level3 = level(level2, level2Key)
		const level3Key = xdefault(level3, "")
		dispatch({ type: "PATH", payload: "/" + key + "/" + level2Key + "/" + level3Key });
		// setCurrentlevel1(key);
		// setCurrentlevel2(level2Key);
		// setCurrentlevel3(level3Key);
	}
	const levelTwoChange = (key, menu) => {
		const level3 = level(menu, key)
		const level3Key = xdefault(level3, "")
		dispatch({ type: "PATH", payload: "/" + currentlevel1 + "/" + key + "/" + level3Key });
		// setCurrentlevel2(key);
		// setCurrentlevel3(level3Key);
	}
	const levelThreeChange = (key) => {
		dispatch({ type: "PATH", payload: "/" + currentlevel1 + "/" + currentlevel2 + "/" + key });
		// setCurrentlevel3(key);
	}
	const changeFullwidth = () => {
		setFullwidth(!fullwidth ? true : false);
	};
	const logout = () => {
		const { auth, history } = props
		auth.logout(() => {
			history.replace('/');
			history.go(0);
		});
	}
	const onKeyDown = () => {
		setFullwidth(!fullwidth ? true : false);
	}
	const style = () => {
		return {
			fullwidth: {
				layout: (!fullwidth) ? { width: "1000px", margin: "0 auto" } : {},
				header: (!fullwidth) ? { width: "1000px", padding: "0", margin: "0 auto" } : {},
			},
			content: (!rightSider) ? { padding: "0px 0px 0px 12px" } : { padding: "0px 12px 0px 12px" }
		}
	};
	const { getState } = useStore();
	const level1 = level(menu([], [], [], getState()), "");
	const level2 = level(level1, currentlevel1);
	const level3 = level(level2, currentlevel2);
	const levelItem1 = item(level1, currentlevel1);
	const levelItem2 = item(level2, currentlevel2);
	const levelItem3 = item(level3, currentlevel3);

	const onChangeLang = (value) => {
		POST(auth, "/api/setuserconfig", {
			key: "lang",
			value: value
		},
			(data) => {
				var arr = [data];
				var obj = Object.fromEntries(arr.map(i => {
					return [
						i.key,
						i.value
					]
				}));
				dispatch(REDUX_UserConfig.actions.setObject({ ...userConfig, ...obj }));
			},
			(err, type) => {
				if (err) {
					if (type === "fail") {
						// message.error(err.message);
						if (err.exception) {
							console.error(err.exception)
						}
					}
					if (type === "error") {
						// message.error(err);
					}
				}
			});
	};

	return (
		<div>
			<TranslateContext.Provider value={translates}>
				<UserConfigContext.Provider value={userConfig}>
					<Hotkeys
						// keyName="shift+a,alt+s,shift+f"
						keyName="shift+f"
						onKeyDown={onKeyDown.bind(this)}
					>
					</Hotkeys>
					<Layout >
						<Header className="header">
							<div style={style().fullwidth.layout}>
								<div style={{ float: "left" }}>
									<div className="logo" />
									{/* <Menu theme="dark" mode="horizontal">
									<Menu.Item key="1">
										<Badge dot={true} offset={[-5, 0]} count={5}>
											<BellOutlined />
										</Badge>
									</Menu.Item>
								</Menu> */}

								</div>
								<div style={{ float: "right" }}>
									{/* <div style={{ float: "left", marginRight: "25px" }}>
								<Dropdown trigger={['click']} overlay={
									<Menu>
										<Menu.Item key="1">
											<List
												style={{ width: "450px" }}
												itemLayout="horizontal"
												dataSource={[
													{
														title: 'Новое сообщение от администратора',
														url: "https://lh4.googleusercontent.com/proxy/EQ41VLt2cX5VLtowCwy0ZRMm5CWjWHs8MAiWzbL3k4kcPZZYcU4R90XuyG5RMOSfTHZ4fuPjikPWaRUxDfHNe-s6pFoO-aBukygGqFoG0MpG2o3-4rMZiynTOgWOn48JR3ec5TpyD53x"
													},
													{
														title: 'Уведомление о изменении прав доступа',
														url: "https://www.kino-teatr.ru/news/6360/65608.jpg"
													},
												]}
												renderItem={item => (
													<List.Item style={{ paddingLeft: "15px" }}>
														<List.Item.Meta
															avatar={<Avatar shape="square" src={item.url} style={{ marginTop: "5px" }} />}
															title={<a href="https://ant.design">{item.title}</a>}
															description="Тут какой-то текст уведомления содержательный"
														/>
													</List.Item>
												)}
											/>
										</Menu.Item>
									</Menu>
								} >
									<Button ghost style={{ padding: "0 10px", marginRight: "10px", color: "rgba(255, 255, 255, 0.65)" }} type="link">
										<Badge dot={true} offset={[5, 0]} count={5}>
											<BellOutlined style={{ fontSize: "20px" }} />
										</Badge>
									</Button>
								</Dropdown>
							</div> */}

									<div style={{ float: "right" }}>
										<Dropdown overlay={
											<Menu>
												<Menu.Item key="1"><UserOutlined /> Профиль</Menu.Item>
												<Menu.Divider />
												<Menu.Item key="2" onClick={logout}>
													<LogoutOutlined />Выход
												</Menu.Item>
											</Menu>
										}>
											<Row style={{ cursor: "pointer" }}>
												<Col style={{ lineHeight: "20px", textAlign: "right" }}>
													<Typography style={{ color: "rgba(255, 255, 255, 0.65)", padding: "12px 15px 0px 0px", fontWeight: "600" }}>
														{user.lastname} {user.firstname}
													</Typography>
													<Typography style={{ color: "rgba(255, 255, 255, 0.65)", padding: "0 15px", fontSize: "12px" }}>
														{user.email}
													</Typography>
												</Col>
												<Col>
													<Avatar shape="square" size="large" icon={<UserOutlined />} />
													{/* <Avatar shape="square" size="large" src="https://images.mikadox.com/personiconnew/5a/82/33370.jpg" /> */}
													{/* <Avatar shape="square" size="large" src="https://lh4.googleusercontent.com/proxy/EQ41VLt2cX5VLtowCwy0ZRMm5CWjWHs8MAiWzbL3k4kcPZZYcU4R90XuyG5RMOSfTHZ4fuPjikPWaRUxDfHNe-s6pFoO-aBukygGqFoG0MpG2o3-4rMZiynTOgWOn48JR3ec5TpyD53x" /> */}
													{/* <Avatar shape="square" size="large" src="https://www.kino-teatr.ru/news/6360/65608.jpg" /> */}
												</Col>
											</Row>
										</Dropdown>
									</div>

								</div>
							</div>
						</Header>
						<Header className="subheader" style={{ padding: "0 25px", ...style().fullwidth.header, background: "#fff" }}>
							<MenuLevelOne active={currentlevel1} item1={levelItem1} item2={levelItem2} item3={levelItem3} menu={level1} onChange={levelOneChange} props={props} />
							<div style={{ float: 'right' }}>
								<div style={{ display: "flex", justifyContent: "flex-end", gap: "20px", alignContent: "center" }}>
									<div>
										<div>
											<Dropdown overlay={<Menu selectable={true} selectedKeys={(userConfig && userConfig.lang) ? [userConfig.lang] : []}>
												<Menu.Item key="ru" onClick={() => onChangeLang("ru")}>
													<div><Flags.RU title="Русский" style={{width:"20px"}}/> RU</div>
												</Menu.Item>
												<Menu.Item key="en" onClick={() => onChangeLang("en")}>
													<div><Flags.US title="English" style={{width:"20px"}}/> EN</div>
												</Menu.Item>
												<Menu.Item key="es" onClick={() => onChangeLang("es")}>
													<div><Flags.ES title="Español" style={{width:"20px"}}/> ES</div>
												</Menu.Item>
											</Menu>}>
												<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
													{(userConfig && userConfig.lang) && <div>
														{userConfig.lang === 'ru' && <div><Flags.RU title="Русский" style={{width:"20px"}}/> {userConfig.lang.toUpperCase()}</div>}
														{userConfig.lang === 'en' && <div><Flags.US title="English" style={{width:"20px"}}/> {userConfig.lang.toUpperCase()}</div>}
														{userConfig.lang === 'es' && <div><Flags.ES title="Español" style={{width:"20px"}}/> {userConfig.lang.toUpperCase()}</div>}
													</div>}
													{(!userConfig || (userConfig && !userConfig.lang)) && <div><Flags.RU title="Русский" style={{width:"20px"}}/> RU</div>}
												</a>
											</Dropdown>
										</div>
									</div>
									<div>
										<Dropdown trigger={['click']} overlay={
											<Menu>
												<Menu.Item key="1">
													<div style={{ minWidth: "200px", display: "flex", justifyContent: "space-between" }}>
														<div style={{ flex: "1" }}>
															<SettingOutlined style={{ marginRight: "8px" }} />Настройки
														</div>
														<div style={{ flex: "2", textAlign: "end" }}>
															{/* <Tag style={{marginRight: "0px"}}>Shift+F</Tag> */}
														</div>
													</div>
												</Menu.Item>
												<Menu.Divider />
												<Menu.Item key="2" onClick={changeFullwidth}>
													<div style={{ minWidth: "200px", display: "flex", justifyContent: "space-between" }}>
														<div style={{ flex: "1" }}>
															{!fullwidth ?
																<><FullscreenOutlined style={{ marginRight: "8px" }} />Во весь экран </> :
																<><FullscreenExitOutlined style={{ marginRight: "8px" }} />Уменьшить </>}
														</div>
														<div style={{ flex: "2", textAlign: "end" }}>
															<Tag style={{ marginRight: "0px" }}>Shift+F</Tag>
														</div>
													</div>
												</Menu.Item>
											</Menu>
										} placement="bottomRight">
											<Button style={{ padding: "0 10px", marginRight: "10px" }} type="link">
												<MenuOutlined />
											</Button>
										</Dropdown>
									</div>
								</div>
							</div>
						</Header>
						<Content style={{ padding: '25px 25px 0 25px' }}>

							<Layout style={style().fullwidth.layout}>

								<Sider width={190} theme="light">
									<Affix offsetTop={0}>
										<div>
											<MenuLevelTwo active={currentlevel2} item1={levelItem1} item2={levelItem2} item3={levelItem3} menu={level2} onChange={levelTwoChange} props={props} />
											<MenuLevelThree active={currentlevel3} item1={levelItem1} item2={levelItem2} item3={levelItem3} menu={level3} onChange={levelThreeChange} props={props} />
										</div>
									</Affix>
									{/* <BackTop>asg</BackTop> */}
								</Sider>
								{/* <Sider width={185} theme="light" style={{ borderLeft: "1px solid #f0f0f0" }}>
								<Divider type="horizontal"
									orientation="left"
									style={{ margin: "12px 0", fontSize: "13px", fontWeight: "600", padding: "0px 15px 0px 5px" }} >
									Содержание
								</Divider>
								<Anchor affix={false} onClick={(e, link) => {
									e.preventDefault();
									console.log(link);
								}}>
									<Link href="#components-anchor-demo-basic" title="Basic demo" />
									<Link href="#components-anchor-demo-static" title="Static demo" />
									<Link href="#API" title="API">
										<Link href="#Anchor-Props" title="Anchor Props" />
										<Link href="#Link-Props" title="Link Props" />
									</Link>
								</Anchor>
							</Sider> */}
								<Content style={{ minHeight: 600, ...style().content }}>
									<Row gutter={[5, 5]}>
										<Col span={24}>
											<Breadcrumb style={{ padding: '5px 8px', borderRadius: "1px", backgroundColor: "#ffffff" }}>
												{levelItem1 && <Breadcrumb.Item key={"bl1"} style={{ cursor: "pointer" }} onClick={() => levelOneChange(currentlevel1, level1)}>{levelItem1.name}</Breadcrumb.Item>}
												{levelItem2 && <Breadcrumb.Item key={"bl2"} style={{ cursor: "pointer" }} onClick={() => levelTwoChange(currentlevel2, level2)}>{levelItem2.name}</Breadcrumb.Item>}
												{levelItem3 && <Breadcrumb.Item key={"bl3"} style={{ cursor: "pointer" }} onClick={() => levelThreeChange(currentlevel3, level3)}>{levelItem3.name}</Breadcrumb.Item>}
											</Breadcrumb>
										</Col>
									</Row>
									<Switch>
										<Route exact path="/" render={() => <Redirect to="/common/events" />} />
										<Route exact path="/common/main" render={() => <CommonMain {...props} />} />
										<Route exact path="/common/events" render={() => <CommonEvents {...props} />} />
										<Route exact path="/common/groups" render={() => <CommonGroups {...props} />} />
										<Route exact path="/common/judge" render={() => <CommonJudge {...props} />} />
										<Route exact path="/common/dancer" render={() => <CommonDancer {...props} />} />
										<Route exact path="/common/tour" render={() => <CommonTour {...props} />} />
										<Route exact path="/common/bunch" render={() => <CommonBunch {...props} />} />
										<Route exact path="/common/commands" render={() => <CommonCommands {...props} />} />

										<Route exact path="/criteriontemplate/template" render={() => <CriteriontemplateTemplate {...props} />} />
										<Route exact path="/criteriontemplate/criterion" render={() => <CriteriontemplateCriterion {...props} />} />
										<Route exact path="/criteriontemplate/priority" render={() => <CriteriontemplatePriority {...props} />} />

										<Route exact path="/rating/upload" render={() => <RatingUpload {...props} />} />
										<Route exact path="/settings/translate" render={() => <SettingsTranslate {...props} />} />
									</Switch>
								</Content>
								{rightSider &&
									<Sider width={185} theme={"light"}>

									</Sider>}
							</Layout>
						</Content>
						<Footer style={{ textAlign: 'center' }}>
							Общероссийская физкультурно-спортивная общественная организация "Всероссийская федерация социальных танцев"
						</Footer>
					</Layout>
				</UserConfigContext.Provider>
			</TranslateContext.Provider>
		</div>
	);
};

export default withRouter(Home);