import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Transfer, Switch, Table, Empty, Typography, Card, message } from 'antd';
import { createUseStyles } from 'react-jss'

import Filtered from '../../components/Filtered'
import FilteredTransfer from '../../components/FilteredTransfer'

import 'moment/locale/ru';

import REDUX_CriterionTemplate from '../../objects/CriterionTemplate/reducer'
import CRUDE_CriterionTemplate from '../../objects/CriterionTemplate/crude'

import REDUX_Criterion from '../../objects/Criterion/reducer'
import CRUDE_Criterion from '../../objects/Criterion/crude'

import { POST } from '../../components/Tool'

const { Title } = Typography;

const useStyles = createUseStyles({
    transfer: {
        '&.ant-transfer': {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        '&.ant-transfer .ant-transfer-list ': {
            flex: "auto",
            height: "500px",
        }
    }
})

export default function CriterionTemplate(props) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const { auth, object } = props;

    const [loading, setLoading] = useState(false);

    const criteriontemplate = useSelector(REDUX_CriterionTemplate.selector.arrayByKey(object.ID));

    const [editing, setEditing] = useState(false);

    const criterionName = (item) => {
        return item.name + ((item.description) ? " (" + item.description + ")" : "");
    };

    useEffect(() => {
        CRUDE_CriterionTemplate.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_CriterionTemplate.actions.setArrayByKey(object.ID, data));
            }
        }, 'model', 's-criterion.name=ASC&w-template.id=' + object.ID);
    }, [object, auth, dispatch]);

    const columns = [
        {
            title: 'Критерий',
            render: (text, record) => {
                return (criterionName(record.criterion));
            }
        }
    ];

    const triggerEditing = edit => {
        setEditing(edit);
    };
    const filters = [
        {
            name: "Критерий",
            value: item => item.criterion.name,
            field: "criterion",
            type: "string",
            sort: true,
            filter: true,
            sorted: "ASC"
        }
    ];
    const filtersCriterion = [
        {
            name: "Критерий",
            value: item => item.name,
            field: "criterion",
            type: "string",
            sort: true,
            filter: true,
            sorted: "ASC"
        }
    ];
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
                <Title level={4} style={{ marginBottom: "0px" }}>{object.name}</Title>
                <div>
                    <Switch
                        unCheckedChildren="Редактировать"
                        checkedChildren="Редактировать"
                        checked={editing}
                        onChange={triggerEditing}
                    />
                </div>
            </div>
            {editing &&
                <FilteredTransfer
                    auth={auth}
                    filters={filtersCriterion}
                    REDUX_Source={REDUX_Criterion}
                    REDUX_Target={REDUX_CriterionTemplate}
                    CRUDE_Source={CRUDE_Criterion}
                    CRUDE_Target={CRUDE_CriterionTemplate}
                    FilterSource={'s-name=ASC'}
                    FilterTarget={'s-criterion.name=ASC&w-template.id=' + object.ID}
                    dropUrl={"/api/dropcriteriontemplete"}
                    addUrl={"/api/addcriteriontemplete/template/" + object.ID}
                    getSourceIDInTarget={(item) => item.criterion.ID}
                    getSourceID={(item) => item.ID}
                    Key={object.ID}
                    titlesTransfer={["Все критерии", 'Критерии шаблона']}
                    localeTransfer={{ itemUnit: "критерий", itemsUnit: "критерии", notFoundContent: "Нет критериев", searchPlaceholder: "Поиск" }}
                    renderTransfer={item => criterionName(item)}
                />
                // <Transfer
                //     className={classes.transfer}
                //     dataSource={mockData}
                //     titles={["Все критерии", 'Критерии шаблона']}
                //     locale={{ itemUnit: "критерий", itemsUnit: "критерии", notFoundContent: "Нет критериев", searchPlaceholder: "Поиск" }}
                //     targetKeys={targetKeys}
                //     selectedKeys={selectedKeys}
                //     onChange={handleChange}
                //     onSelectChange={handleSelectChange}
                //     render={item => criterionName(item)}
                //     // oneWay={true}
                //     style={{ marginBottom: 16 }}
                // />
            }

            {!editing && <Filtered title={object.name} filters={filters} data={criteriontemplate} content={items => (
                <Card size="small" className={classes.block} style={{ width: "100%" }}>
                    <Table loading={loading} pagination={false} columns={columns} dataSource={items} locale={{ emptyText: <Empty description={"Нет критериев"}></Empty> }} size="small" rowKey={(r) => r.ID} />
                </Card>
            )}
                addition={
                    (
                        <>
                            <div justify="start">
                            </div>
                        </>
                    )
                } />}

        </>
    );
}
