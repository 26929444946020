import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Select } from 'antd';

import 'moment/locale/ru';

import REDUX_Gender from '../../objects/Gender/reducer'
import CRUDE_Gender from '../../objects/Gender/crude'

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const { Option } = Select;


export default function NewJudge(props) {
    const { auth } = props;
    const dispatch = useDispatch();

    const gender = useSelector(REDUX_Gender.selector.array);
    useEffect(() => {
        CRUDE_Gender.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_Gender.actions.setArray(data));
            }
        }, 'model');
    }, [auth, dispatch]);
    const options = gender.map(i => (
        <Option key={i.ID} value={i.ID}>{i.name}</Option>
    ));

    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });

    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{
                // name: object.name || "",
            }}
        >
            <Form.Item
                name={"lastname"}
                label={"Фамилия"}
                rules={[{ required: true, message: 'Укажите фамилию!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"firstname"}
                label={"Имя"}
                rules={[{ required: true, message: 'Укажите имя!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"patronymic"}
                label={"Отчество"}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"genderID"}
                label={"Пол"}
                rules={[{ required: true, message: 'Укажите пол!' }]}
            >
                <Select showSearch
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {options}
                </Select>
            </Form.Item>
            <Form.Item
                name={"username"}
                label={"Логин в системе"}
                rules={[{ required: true, message: 'Укажите логин!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name={"password"}
                label={"Пароль в системе"}
                rules={[{ required: true, message: 'Укажите пароль!' }]}
            >
                <Input.Password
                    placeholder="Введите пароль"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </Form.Item>
        </Form>
    );
}
