import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Table, Empty, Button, Typography, Switch, Transfer, Card, message } from 'antd';
import { createUseStyles } from 'react-jss'

import Filtered from '../../components/Filtered'
import FilteredTransfer from '../../components/FilteredTransfer'

import 'moment/locale/ru';

import REDUX_JudgeBunch from '../../objects/JudgeBunch/reducer'
import CRUDE_JudgeBunch from '../../objects/JudgeBunch/crude'

import REDUX_JudgeTour from '../../objects/JudgeTour/reducer'
import CRUDE_JudgeTour from '../../objects/JudgeTour/crude'


import { POST } from '../../components/Tool'


const { Title } = Typography;

const useStyles = createUseStyles({
    transfer: {
        '&.ant-transfer': {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        '&.ant-transfer .ant-transfer-list ': {
            flex: "auto",
            height: "500px",
        }
    }
});
export default function JudgeBunch(props) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const { auth, object } = props;

    const [loading, setLoading] = useState(false);

    const judgebunch = useSelector(REDUX_JudgeBunch.selector.arrayByKey(object.ID));

    const [editing, setEditing] = useState(false);

    const judgetour = useSelector(REDUX_JudgeTour.selector.arrayByKey(object.tourID));
    useEffect(() => {
        CRUDE_JudgeBunch.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_JudgeBunch.actions.setArrayByKey(object.ID, data));
            }
        }, 'model', 'w-bunch.id=' + object.ID);
    }, [object, auth, dispatch]);

    const columns = [
        {
            title: 'Судья',
            render: (text, record) => {
                return (record.judgeTour.judgeEvent.judge.lastname + " " + record.judgeTour.judgeEvent.judge.firstname);
            }
        },
        {
            title: 'Пол',
            render: (text, record) => {
                return (record.judgeTour.judgeEvent.judge.gender.name);
            }
        }
    ];
    const { getState } = useStore()
    const update = (res) => {
        dispatch(REDUX_JudgeBunch.actions.updateArrayByKey(object.ID, res.data));
    };
    const allJudges = () => {
        auth.fetch("/api/bunch/" + object.ID + "/judgebunchgenerate").then(res => {
            if (res && res.data && dispatch) {
                update(res);
            }
        });
    };

    const triggerEditing = edit => {
        setEditing(edit);
    };
    const filters = [
        {
            name: "Судья",
            value: item => item.judgeTour.judgeEvent.judge.lastname + " " + item.judgeTour.judgeEvent.judge.firstname,
            field: "judge",
            type: "string",
            sort: true,
            filter: true,
            sorted: "ASC"
        },
        {
            name: "Пол",
            value: item => item.judgeTour.judgeEvent.judge.gender.name,
            field: "gender",
            type: "string",
            sort: true,
            filter: true
        }
    ];
    const filtersJudgeTour = [
        {
            name: "Судья",
            value: item => item.judgeEvent.judge.lastname + " " + item.judgeEvent.judge.firstname,
            field: "judge",
            type: "string",
            sort: true,
            filter: true,
            sorted: "ASC"
        },
        {
            name: "Пол",
            value: item => item.judgeEvent.judge.gender.name,
            field: "gender",
            type: "string",
            sort: true,
            filter: true
        }
    ];
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
                <Title level={4} style={{ marginBottom: "0px" }}>{object.tour.levelEvent.event.name + " (" + object.tour.levelEvent.level.name + ")"}</Title>
                <div>
                    <Switch
                        unCheckedChildren="Редактировать"
                        checkedChildren="Редактировать"
                        checked={editing}
                        onChange={triggerEditing}
                    />
                </div>
            </div>
            {editing && 
                        <FilteredTransfer
                        auth={auth}
                        filters={filtersJudgeTour} 
                        REDUX_Source={REDUX_JudgeTour}
                        REDUX_Target={REDUX_JudgeBunch}
                        CRUDE_Source={CRUDE_JudgeTour}
                        CRUDE_Target={CRUDE_JudgeBunch}
                        FilterSource={'s-judgeEvent.judge.lastname=ASC&s-judgeEvent.judge.firstname=ASC&w-tour.ID='+object.tourID}
                        FilterTarget={'w-bunch.id=' + object.ID}
                        dropUrl={"/api/dropjudgebunch"}
                        addUrl={"/api/addjudgebunch/bunch/" + object.ID}
                        getSourceIDInTarget={(item) => item.judgeTour.ID}
                        getSourceID={(item) => item.ID}
                        Key={object.ID}
                        titlesTransfer={['Все судьи', 'Судьи захода']}
                        localeTransfer={{ itemUnit: "судья", itemsUnit: "судей", notFoundContent: "Нет судей", searchPlaceholder: "Поиск" }}
                        renderTransfer={item => item.judgeEvent.judge.lastname + " " + item.judgeEvent.judge.firstname}
                    />
            }

            {!editing && <Filtered title={object.tour.levelEvent.event.name+" ("+object.tour.levelEvent.level.name+")"} filters={filters} data={judgebunch} content={items => (
                <Card size="small" className={classes.block} style={{ width: "100%" }}>
                    <Table loading={loading} pagination={false} columns={columns} dataSource={items} locale={{ emptyText: <Empty description={"Нет судей"}></Empty> }} size="small" rowKey={(r) => r.ID} />
                </Card>
            )}
                addition={
                    (
                        <>
                            <div justify="start">
                                <Button onClick={allJudges} style={{ marginLeft: "5px" }}>Добавить всех судей</Button>
                            </div>
                        </>
                    )
                } />}

        </>
    );
}
