import React, { createContext } from 'react';

export const UserConfigContext = createContext();
export function useUserConfigContext() {
    return React.useContext(UserConfigContext);
}

export const TranslateContext = createContext();
export function useTranslateContext() {
    return React.useContext(TranslateContext);
}
