import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Select } from 'antd';

import 'moment/locale/ru';

import REDUX_Translate from '../../objects/Translate/reducer'
import CRUDE_Translate from '../../objects/Translate/crude'

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const { Option } = Select;


export default function NewTranslate(props) {
    const { auth, object } = props;
    const dispatch = useDispatch();

    const translate = useSelector(REDUX_Translate.selector.array);
    useEffect(() => {
        CRUDE_Translate.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_Translate.actions.setArray(data));
            }
        }, 'model');
    }, [auth, dispatch]);
    
    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });

    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{
                ...object,
            }}
        >
            <Form.Item
                name={"ru"}
                label={"Русский"}
                rules={[{ required: true, message: 'Укажите значение!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"en"}
                label={"Английский"}
                rules={[{ required: false, message: 'Укажите значение!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"es"}
                label={"Испанский"}
                rules={[{ required: false, message: 'Укажите значение!' }]}
            >
                <Input />
            </Form.Item>
        </Form>
    );
}
