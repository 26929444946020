import {
    updateArray,
    setArray,
} from './reducer';

import { message } from 'antd';

const NAME = 'ProtocolValueType';

export const create = (auth, callback, object, dispatch, error) => { 
    auth.fetch('/api/query-create/' + NAME.toLowerCase(), {
        method: 'POST',
        body: JSON.stringify(object)
    }).then(res => {
        if (res && res.status === true) {
            if (dispatch && res.data) {
                dispatch(updateArray([res.data]));
            }
            if (callback) {
                callback(res.data);
            }
        } else if(res && res.status === false) {
            if(error) {
                error(res, "fail");
            } else {
                message.error(res.message);
                if(res.exception){
                    console.error(res.exception)
                }
            }  
        }
    }).catch(function(err) {
        if(error) {
            error(err, "error");
        } else {
            message.error(""+err);
        }
    });
};

export const read = (auth, callback, detail, filter, dispatch, error) => {
    if (!detail) detail = 'none';
    filter = (!filter) ? '' : '&' + filter;
    auth.fetch('/api/query/' + NAME.toLowerCase() + '?detail=' + detail + filter).then(res => {
        if (res && res.status === true) {
            if (dispatch && res.data) {
                dispatch(setArray(res.data));
            }
            if (callback) {
                callback(res.data);
            }
        } else if(res && res.status === false) {
            if(error) {
                error(res, "fail");
            } else {
                message.error(res.message);
                if(res.exception){
                    console.error(res.exception)
                }
            }
        }
    }).catch(function(err) {
        if(error) {
            error(err, "error");
        } else {
            message.error(""+err);
        }
    });
};
export const update = (auth, callback, object, dispatch, error) => { 
    auth.fetch('/api/query-update/' + NAME.toLowerCase(), {
        method: 'POST',
        body: JSON.stringify(object)
    }).then(res => {
        if (res && res.status === true) {
            if (dispatch && res.data) {
                dispatch(updateArray([res.data]));
            }
            if (callback) {
                callback(res.data);
            }
        } else if(res && res.status === false) {
            if(error) {
                error(res, "fail");
            } else {
                message.error(res.message);
                if(res.exception){
                    console.error(res.exception)
                }
            }  
        }
    }).catch(function(err) {
        if(error) {
            error(err, "error");
        } else {
            message.error(""+err);
        }
    });
};
export const destroy = (auth, callback, id, error) => {
    auth.fetch('/api/query-delete/' + NAME.toLowerCase() + '/' + id).then(res => {
        if (res && res.status === true && callback) {
            callback(res.data);
        } else if(res && res.status === false) {
            if(error) {
                error(res, "fail");
            } else {
                message.error(res.message);
                if(res.exception){
                    console.error(res.exception)
                }
            }   
        }
    }).catch(function(err) {
        if(error) {
            error(err, "error");
        } else {
            message.error(""+err);
        }
    });
};
export const execute = (auth, callback, detail, filter, dispatch) => { };

export default {
    create: create,
    read: read,
    update: update,
    destroy: destroy,
    execute: execute
};
