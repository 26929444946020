import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Menu, Card, Divider, Row, Col, Modal, Empty } from 'antd';

import {
	ExclamationCircleOutlined} from '@ant-design/icons';
import { createUseStyles } from 'react-jss'

import 'moment/locale/ru';

import Filtered from '../../components/Filtered'
import DropdownAction from '../../components/DropdownAction'
import Action from '../../components/Action'

import REDUX_Template from '../../objects/Template/reducer';
import CRUDE_Template from '../../objects/Template/crude';

import NewTemplate from '../../forms/NewTemplate'
import UpdateTemplate from '../../forms/UpdateTemplate'

import CriterionTemplate from '../../forms/CriterionTemplate'

const { confirm } = Modal;

function Btn(props) {
	const dispatch = useDispatch();
	const { auth, object } = props;
	const empty = (done) => {
		if (done) done();
	};
	const Templates = useSelector(REDUX_Template.selector.array);
	const odelete = () => {
		CRUDE_Template.destroy(props.auth, () => {
			dispatch(REDUX_Template.actions.setArray(Templates.filter(item => item.ID !== props.object.ID)));
		}, props.object.ID);
	};
	const update = (done, values, unlock) => {
		CRUDE_Template.update(props.auth, (data) => {
			dispatch(REDUX_Template.actions.updateArray([data]));
			done();
		}, { ...props.object, ...values });
		if (unlock)
			unlock();
	};
	return (
		<DropdownAction>
			<Action {...props} readonly={false} modal={{ title: "Изменить шаблон" }} object={props.object} mode={"MenuItem"} action={update} title={"Изменить"} form={UpdateTemplate} document={"query-update/template"} />
			<Action {...props} readonly={true} modal={{ title: "Критерии шаблона", width: "65%" }} object={object} mode={"MenuItem"} action={empty} title={"Критерии шаблона"} form={CriterionTemplate} document={"templatecriterion"} />
			<Menu.Divider></Menu.Divider>
			<Menu.Item style={{color: "red"}} onClick={()=> confirm({
				title: 'Вы действительно хотите удалить шаблон?',
				icon: <ExclamationCircleOutlined />,
				content: 'Удаление шаблона приведет к потере всех связанных с ним данных',
				okText: 'Удалить',
				cancelText: 'Отмена',
				onOk() {
					odelete();
				},
				onCancel() {
				},
			})}>Удалить</Menu.Item>
		</DropdownAction>
	)
};

const useStyles = createUseStyles({
	divider: {
		'&.ant-divider-horizontal.ant-divider-with-text-left': {
			fontFamily: "-apple-system, BlinkMacSystemFont, Roboto, 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif",
			fontSize: "13px",
			fontWeight: "400",
			color: "rgb(0, 0, 0)",
		},
		'&.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text': {
			padding: "0px",
			marginRight: "10px",
		}
	}
})

export default function CriteriontemplateTemplate(props) {
	const classes = useStyles()
	const dispatch = useDispatch();
	const { auth } = props;
	const [loading, setLoading] = useState(false);
	const filters = [
		{
			name: "Название",
			value: item => item.name,
			field: "name",
			type: "string",
			sort: true,
			filter: true,
			sorted: "ASC"
		}
	];
	useEffect(() => {
		setLoading(true);
		CRUDE_Template.read(auth, (data) => {
			setLoading(false);
			if (data.length > 0) {
				dispatch(REDUX_Template.actions.setArray(data));
			}
		}, 'model');
	}, [auth, dispatch]);

	const data = useSelector(REDUX_Template.selector.array);
	const update = (res) => {
		dispatch(REDUX_Template.actions.updateArray([res.data]));
	};
	const columns = [
		{
			title: 'Название',
			render: (text, record) => {
				return (record.name);
			}
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			fixed: 'right',
			width: 45,
			render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} />),
		},
	];
	return (
		<>
			<Filtered title={"Шаблоны"} filters={filters} data={data} content={items => (
				<Card size="small" className={classes.block} style={{ width: "100%" }}>
					<Divider className={classes.divider} type="horizontal" orientation="left">
						Шаблоны
                    </Divider>
					<Table loading={loading} pagination={false} columns={columns} dataSource={items} locale={{ emptyText: <Empty description={"Нет шаблонов"}></Empty> }} size="small" rowKey={(r) => r.ID} />
				</Card>
			)}
				addition={
					(
						<Row justify="start">
							{/* <Col flex="auto">
					</Col> */}
							<Col flex="50px">
								<Action {...props} callback={update} title={"Новый шаблон"} form={NewTemplate} document={"query-create/template"} />
							</Col>
						</Row>
					)
				} />
		</>
	);
}
