import React, { useState, useEffect } from 'react';
import {
	withRouter
} from "react-router-dom";
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Row, Col, Typography } from 'antd';
import { Radio, Picker, List, NavBar, Button, WhiteSpace, WingBlank, Modal, Tabs, Badge, TabBar, Card, Icon, Drawer, ActivityIndicator, SwipeAction } from 'antd-mobile';
import {
	AuditOutlined,
	UserOutlined,
	LogoutOutlined
} from '@ant-design/icons'
import { createUseStyles } from 'react-jss'
import { GET, translate } from '../../components/Tool'
import { POST } from '../../components/Tool'
import RenderToLayer from '../../components/RenderToLayer'
import REDUX_Protocol from '../../objects/Protocol/reducer'
import CRUDE_Protocol from '../../objects/Protocol/crude'
import REDUX_UserConfig from '../../objects/UserConfig/reducer'
import CRUDE_UserConfig from '../../objects/UserConfig/crude'
import CRUDE_ProtocolDancerParams from '../../objects/ProtocolDancerParams/crude'
import REDUX_Translate from '../../objects/Translate/reducer';
import CRUDE_Translate from '../../objects/Translate/crude';

import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

import {
	MinusOutlined,
	PlusOutlined
} from '@ant-design/icons';

import 'antd-mobile/dist/antd-mobile.css';
import './index.css';
import { TranslateContext, UserConfigContext } from '../../components/Context';

var _ = require('lodash');

const mainDark = "#001529";

const RadioItem = Radio.RadioItem;
const operation = Modal.operation;
const alert = Modal.alert;

function closest(el, selector) {
	const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
	while (el) {
		if (matchesSelector.call(el, selector)) {
			return el;
		}
		el = el.parentElement;
	}
	return null;
}
function onWrapTouchStart(e) {
	// fix touch to scroll background page on iOS
	if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
		return;
	}
	const pNode = closest(e.target, '.am-modal-content');
	if (!pNode) {
		e.preventDefault();
	}
}
const styleBtn = {
	padding: "0px 5px",
	minWidth: "45px",
	minHeight: "36px",
	fontWeight: "400",
	fontSize: "20px",
	color: "#c1c1c1",
	// textShadow: "1px 1px 2px #848484, 0 0 1em white"
};
const styleBtnActive = {
	padding: "0px 5px",
	minWidth: "45px",
	minHeight: "36px",
	backgroundColor: "#f9f9f9",
	fontWeight: "600",
	fontSize: "20px",
	color: "#000", //"#ff8405",
	textShadow: "1px 1px 2px #848484, 0 0 1em white"
};
const useStyles = createUseStyles({
	navbar: {
		height: "20px",
		'& .am-navbar-left': {
			paddingLeft: "0px"
		},
		'& .am-navbar-right': {
			marginRight: "0px"
		},
		'& .am-navbar-title': {
			flex: "auto"
		}
	},
	picker: {
		'& .am-picker-col-item': {
			fontSize: "13px"
		},
		'& .am-picker-col-item-selected': {
			fontSize: "13px"
		}
	},
	pickerItem: {
		'& .am-list-line .am-list-extra': {
			display: "none"
		},
		'& .am-list-line .am-list-content': {
			fontSize: "14px"
		},
		'& .am-list-line .am-list-arrow': {
			height: "11px"
		}
	},
	buttonBadge: {
		display: "block",
		'& .am-badge-dot': {
			right: "-10px",
			top: "0px!important"
		}
	},
	btn: styleBtn,
	btnActive: styleBtnActive
})
const dancerIconSmall = (number, color1, color2) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
		<defs>
			<linearGradient id={"textgradient" + number} x1="0%" x2="0%" y1="0%" y2="100%">
				<stop stopColor={color1 || "#ccc"} offset="0%"></stop>
				<stop stopColor={color2 || "rgba(0, 0, 0, 0.65)"} offset="100%"></stop>
			</linearGradient>
		</defs>
		<rect x="0" y="0" width="28" height="28" rx="8" ry="8" fill={"url(#textgradient" + number + ")"}></rect>
		<text fontSize="16" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fill="white" stroke="none">{number}</text>
	</svg>
);
const dancerIcon = (number) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36">
		<defs>
			<linearGradient id="textgradient" x1="0%" x2="0%" y1="0%" y2="100%">
				<stop stopColor="#ccc" offset="0%"></stop>
				<stop stopColor="rgba(0, 0, 0, 0.65)" offset="100%"></stop>
			</linearGradient>
		</defs>
		<rect x="0" y="0" width="36" height="36" rx="8" ry="8" fill="url(#textgradient)"></rect>
		<text fontSize="20" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fill="white" stroke="none">{number}</text>
	</svg>
);
const statPeople = (protocol, dancer) => {
	var s = {};
	var count = 0;
	if (!protocol) return {};
	if (protocol.judgeBunch.judgeTour.judgeEvent.judge.genderID === 2) {
		count = protocol.judgeBunch.bunch.tour.scoresInCriterionW;
	} else {
		count = protocol.judgeBunch.bunch.tour.scoresInCriterionM;
	}
	// protocol.protocolDancer.forEach(dancer => {
	dancer.protocolDancerParams.forEach(item => {
		if (item.value > 0 && protocol.judgeBunch.bunch.tour.protocolValueTypeID === 1) {
			if (!s[item.criterionParams.criterionID]) s[item.criterionParams.criterionID] = { value: 0, good: true, check: true, criterion: item.criterionParams.criterion };
			s[item.criterionParams.criterionID].value += (item.value !== null) ? item.value : 0;
			s[item.criterionParams.criterionID].good = (s[item.criterionParams.criterionID].value !== count) ? false : true;
		} else if (protocol.judgeBunch.bunch.tour.protocolValueTypeID !== 1) {
			if (!s[item.criterionParams.criterionID]) s[item.criterionParams.criterionID] = { value: 0, good: true, check: true, criterion: item.criterionParams.criterion };
			s[item.criterionParams.criterionID].value += (item.value !== null) ? item.value : 0;
			s[item.criterionParams.criterionID].good = (!s[item.criterionParams.criterionID].good || !item.value) ? false : true;

			if (!s[item.criterionParams.criterionID][item.criterionParamsID]) {
				s[item.criterionParams.criterionID][item.criterionParamsID] = { count: {} };
			}
			var countValue = s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value];
			if (!countValue) {
				s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value] = 1;
			} else {
				s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value]++;
			}

			if (s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value] > protocol.judgeBunch.bunch.tour.maxCountEqualScores) {
				s[item.criterionParams.criterionID].check = false;
			}
		}
	});
	// });
	return s;
};
const statPrepair = (protocol) => {
	var s = {};
	var count = 0;
	if (!protocol) return {};
	if (protocol.judgeBunch.judgeTour.judgeEvent.judge.genderID === 2) {
		count = protocol.judgeBunch.bunch.tour.scoresInCriterionW;
	} else {
		count = protocol.judgeBunch.bunch.tour.scoresInCriterionM;
	}
	protocol.protocolDancer.forEach(dancer => {
		dancer.protocolDancerParams.forEach(item => {
			if (item.value > 0 && protocol.judgeBunch.bunch.tour.protocolValueTypeID === 1) {
				if (!s[item.criterionParams.criterionID]) s[item.criterionParams.criterionID] = { value: 0, good: true, check: true, criterion: item.criterionParams.criterion };
				s[item.criterionParams.criterionID].value += (item.value !== null) ? item.value : 0;
				s[item.criterionParams.criterionID].good = (s[item.criterionParams.criterionID].value !== count) ? false : true;
			} else if (protocol.judgeBunch.bunch.tour.protocolValueTypeID !== 1) {
				if (!s[item.criterionParams.criterionID]) s[item.criterionParams.criterionID] = { value: 0, good: true, check: true, criterion: item.criterionParams.criterion };
				s[item.criterionParams.criterionID].value += (item.value !== null) ? item.value : 0;
				s[item.criterionParams.criterionID].good = (!s[item.criterionParams.criterionID].good || !item.value) ? false : true;

				if (!s[item.criterionParams.criterionID][item.criterionParamsID]) {
					s[item.criterionParams.criterionID][item.criterionParamsID] = { count: {} };
				}
				var countValue = s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value];
				if (!countValue) {
					s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value] = 1;
				} else {
					s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value]++;
				}

				if (s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value] > protocol.judgeBunch.bunch.tour.maxCountEqualScores) {
					s[item.criterionParams.criterionID].check = false;
				}
			}
		});
	});
	return s;
};
const protocolCriterionParamsCount = (protocol, protocolCriterionParams) => {
	var count = 0;
	// console.log(protocolCriterionParams)
	protocolCriterionParams.forEach(protocolCriterian => {
		if (protocol.judgeBunch.judgeTour.judgeEvent.judge.genderID === 1) {
			if (protocolCriterian.ProtocolParamsMan && protocolCriterian.ProtocolParamsMan.length) {
				count++;
			}
		} else {
			if (protocolCriterian.ProtocolParamsWoman && protocolCriterian.ProtocolParamsWoman.length) {
				count++;
			}
		}
	});
	return count;
};
const stat = (protocol) => {
	var s = {};
	if (!protocol || !protocol.Protocol) return {};

	s = statPrepair(protocol.Protocol);

	var criterionCount = protocolCriterionParamsCount(protocol.Protocol, protocol.ProtocolCriterian)
	var good = true;
	var check = true;
	if (Object.keys(s).length !== criterionCount) {
		good = false;
	} else {
		Object.keys(s).forEach(i => {
			if (!s[i].good) {
				good = false;
			}
		});
	}

	Object.keys(s).forEach(i => {
		if (!s[i].check) {
			check = false;
		}
	});

	// console.log({
	// 	...s,
	// 	good: good
	// });
	return {
		...s,
		good: good,
		check: check
	}
};
const mergeProtocolParamValue = (protocol, protocolDancerParam, value) => {
	var ptcl = {
		...protocol,
		protocolDancer: protocol.protocolDancer.map(p => {
			return {
				...p,
				protocolDancerParams: p.protocolDancerParams.map(k => {
					if (k.ID === protocolDancerParam.ID) {
						return { ...k, value: value }
					}
					return k;
				})
			};
		}
		)
	};
	return ptcl;
};

const setCurrentProtocolParamValue = (dispatch, arrayProtocol, protocol, protocolParam, value) => {
	var pr = { ...protocol };
	if (pr.Protocol && pr.Protocol.protocolDancer) {
		for (let item = 0; item < pr.Protocol.protocolDancer.length; item++) {
			const protocolDancer = pr.Protocol.protocolDancer[item];
			for (let p = 0; p < protocolDancer.protocolDancerParams.length; p++) {
				const protocolDancerParam = protocolDancer.protocolDancerParams[p];
				if (protocolDancerParam.ID === protocolParam.ID /*&& protocolDancerParam.protocolID === protocolParam.protocolID*/) {
					var ptcl = mergeProtocolParamValue(pr.Protocol, protocolParam, value);
					setCurrentProtocol(dispatch, arrayProtocol, {
						...pr,
						Protocol: ptcl
					});
					return;
				}
			}
		}
	}
	// if (pr.EditedProtocols) {
	// 	setCurrentProtocol(dispatch, arrayProtocol, {
	// 		...pr,
	// 		EditedProtocols: pr.EditedProtocols.map(p => {
	// 			if (p.ID === protocolParam.protocolID) {
	// 				var ptcl = mergeProtocolParamValue(p, protocolParam, value);
	// 				return ptcl
	// 			}
	// 			return p;
	// 		})
	// 	});
	// 	return;
	// }
};

const setCurrentProtocol = (dispatch, arrayProtocol, protocol) => {
	dispatch(REDUX_Protocol.actions.setObject(protocol));
	if (protocol && arrayProtocol && arrayProtocol.length > 0) {
		dispatch(REDUX_Protocol.actions.setArray(arrayProtocol.map(i => {
			if (i.Protocol.ID === protocol.Protocol.ID) {
				return protocol;
			}
			return i;
		})));
	} else {
		dispatch(REDUX_Protocol.actions.setArray([]));
	}
	dispatch({ type: 'stat', payload: stat(protocol) });
};
const mycurrentprotocol = (auth, dispatch, current, showModal) => {
	dispatch({ type: 'loading', payload: true });
	GET(auth, "/api/mycurrentprotocol",
		(xdata) => {
			//setData(xdata);
			if (xdata.length) {
				// setCurrentProtocol(xdata[0]);
				var p = xdata[0];
				if (current) {
					var pp = xdata.find(item => item.Protocol.ID === current.ID);
					// console.log("pp",pp);
					if (pp) {
						p = pp;
					}
				}
				if (showModal) {
					showModal(p, xdata);
				}
				setCurrentProtocol(dispatch, xdata, p);
				// dispatch(REDUX_Protocol.actions.setArray(xdata));
			} else {
				if (showModal) {
					showModal(p, xdata);
				}
				setCurrentProtocol(dispatch, [], undefined);
				// dispatch(REDUX_Protocol.actions.setArray([]));
			}
			dispatch({ type: 'loading', payload: false });
		},
		(err, type) => {
			if (err) {
				if (type === "fail") {
					console.log(err.message);
					// message.error(err.message);
					if (err.exception) {
						console.error(err.exception)
					}
				}
				if (type === "error") {
					console.log(err);
					// message.error(err);
				}
			}
			dispatch({ type: 'loading', payload: false });
		});
};

function ValueButton({ auth, value, protocol, protocolDancerParam, protocolDancer, userConfig, criterion, multiparam }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [modalVible, setModalVisible] = useState({});
	const curProt = useSelector(REDUX_Protocol.selector.object);
	const arrayProtocol = useSelector(REDUX_Protocol.selector.array);

	const translates = useSelector(REDUX_Translate.selector.object);
	const t = React.useMemo(() => _.bind(translate, {
		translate: translates,
		config: userConfig
	}), [translates, userConfig]);

	// console.log(protocolDancerParam, protocolDancer);

	if (!protocolDancerParam) {
		return <></>
	}
	const currentUiMode = () => {
		return (userConfig && userConfig.ui) ? userConfig.ui : "2";
	};
	const mode = currentUiMode();
	// 0 - auto
	// 1 - [btn]
	// 2 - [btn][1][2][3][4]
	// 3 - operation
	// 4 - modal
	// 5 - modal-fullscreen
	// 6 - popup

	const change = (value, protocolDancerParam) => {
		if (!protocol.completed) {
			dispatch({ type: 'loading', payload: true });
			if (!value) value = 0;
			var object = { ID: protocolDancerParam.ID, value: value };
			CRUDE_ProtocolDancerParams.update(auth, () => {
				setCurrentProtocolParamValue(dispatch, arrayProtocol, curProt, protocolDancerParam, value);
				dispatch({ type: 'loading', payload: false });
			}, object, undefined, () => {
				dispatch({ type: 'loading', payload: false });
			});
		} else {
			alert(t('Протокол заполнен'), t('Нельзя менять завершенный протокол'), [
				{
					text: t('Ok'),
					onPress: () => { }
				},
			])
		}
	};

	const PlusMinus = (val) => {
		switch (val) {
			case 1:
				return (<PlusOutlined />);
			case -1:
				return (<MinusOutlined />);
			default:
				return (<></>);
		}
	};
	const nextPlusMinusValue = (val) => {
		switch (val) {
			case 0:
				return 1;
			case null:
				return 1;
			case undefined:
				return 1;
			case 1:
				return -1;
			case -1:
				return undefined;
			default:
				return undefined;
		}
	};
	const nextDigitValue = (val) => {
		if (val >= criterion.maxScoreValue) {
			return undefined;
		}
		var step = criterion.step || 1;
		step = Math.round(step * 100) / 100;
		switch (val) {
			case null:
				return step;
			case undefined:
				return step;
			case 0:
				return step;
			case criterion.maxScoreValue:
				return undefined;
			default:
				return Math.round((val + step) * 100) / 100;
		}
	};
	const valuesArray = () => {
		var arr = [];
		var step = criterion.step || 1;
		step = Math.round(step * 100) / 100
		var val = step;
		while (val <= criterion.maxScoreValue) {
			// while (val <= 10) {
			arr.push(val);
			val += step;
			val = Math.round(val * 100) / 100
		}
		return arr
	};
	const closeModal = key => () => {
		setModalVisible({ ...modalVible, [key]: false })
	}
	const showModal = key => (e) => {
		e.preventDefault();
		setModalVisible({ ...modalVible, [key]: true })
	}
	const getModal = key => {
		return (modalVible[key]) ? modalVible[key] : false;
	}

	if (protocol.judgeBunch.bunch.tour.protocolValueTypeID === 1) {
		if (multiparam) {
			return (
				<Button style={{ height: "36px", lineHeight: "39px" }} onClick={() => change(nextPlusMinusValue(value), protocolDancerParam, criterion)}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div style={{ maxWidth: "75%", padding: "7px 7px", wordWrap: "break-word", overflowWrap: "break-word", whiteSpace: "break-spaces", fontSize: "12px", lineHeight: "12px", textAlign: "left" }}>{t(protocolDancerParam.criterionParams.name)}</div>
						<div style={{ padding: "0px 15px", minWidth: "60px", minHeight: "36px", backgroundColor: "#f9f9f9", fontWeight: "600", fontSize: "20px", textShadow: "1px 1px 2px #848484, 0 0 1em white" }}>{PlusMinus(value)}</div>
					</div>
				</Button>
			);
		} else {
			return (
				<Button style={{ height: "36px", lineHeight: "39px" }} onClick={() => change(nextPlusMinusValue(value), protocolDancerParam, criterion)}>
					<div style={{ fontWeight: "600", fontSize: "20px", textShadow: "1px 1px 2px #848484, 0 0 1em white" }}>{PlusMinus(value)}</div>
				</Button>
			);
		}
	} else {
		if (mode === "1") { // 1 - [btn]
			if (multiparam) {
				var step = criterion.step || 1;
				return (
					<Button style={{ height: "36px", lineHeight: "39px" }} onClick={() => change(nextDigitValue(value), protocolDancerParam, criterion)}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ maxWidth: "75%", padding: "7px 7px", wordWrap: "break-word", overflowWrap: "break-word", whiteSpace: "break-spaces", fontSize: "12px", lineHeight: "12px", textAlign: "left" }}>
								{t(protocolDancerParam.criterionParams.name)}
								<span style={{ fontStyle: "italic", color: "rgb(0,0,0,0.5)" }}>{" (" + step + "-" + criterion.maxScoreValue + ")"}</span>
							</div>
							<div style={{ padding: "0px 15px", minWidth: "60px", minHeight: "36px", backgroundColor: "#f9f9f9", fontWeight: "600", fontSize: "20px", textShadow: "1px 1px 2px #848484, 0 0 1em white" }}>{(value) ? value : undefined}</div>
						</div>
					</Button>
				);
			} else {
				return (
					<Button style={{ height: "36px", lineHeight: "39px" }} onClick={() => change(nextDigitValue(value), protocolDancerParam, criterion)}>
						<div style={{ fontWeight: "600", fontSize: "20px", textShadow: "1px 1px 2px #848484, 0 0 1em white" }}>{(value) ? value : undefined}</div>
					</Button>
				);
			}
		}
		if (mode === "2") { // 2 - [btn][1][2][3][4]
			if (multiparam) {
				var step = criterion.step || 1;
				return (
					<Button style={{ height: "36px", lineHeight: "39px" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div onClick={() => change(nextDigitValue(value), protocolDancerParam, criterion)} style={{ color: "#000", flex: "auto", padding: "7px 7px", wordWrap: "break-word", overflowWrap: "break-word", whiteSpace: "break-spaces", fontSize: "12px", lineHeight: "12px", textAlign: "left" }}>
								{t(protocolDancerParam.criterionParams.name)}
							</div>
							<div style={{ display: "flex", justifyContent: "right" }}>
								{valuesArray().map((item, idx) =>
									<div key={idx} className={(value == item) ? classes.btnActive : classes.btn}
										onClick={() => (value == item) ? change(undefined, protocolDancerParam, criterion) : change(item, protocolDancerParam, criterion)}
										style={{ borderLeft: "1px solid rgb(234 234 234)" }}>
										{item}
									</div>
								)}
							</div>
						</div>
					</Button>
				);
			} else {
				return (
					<Button style={{ height: "36px", lineHeight: "39px" }}
					//onClick={() => change(nextDigitValue(value), protocolDancerParam, criterion)}
					>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							{valuesArray().map((item, idx) =>
								<div className={(value == item) ? classes.btnActive : classes.btn}
									onClick={() => (value == item) ? change(undefined, protocolDancerParam, criterion) : change(item, protocolDancerParam, criterion)}
									style={{ flex: "auto", borderLeft: "1px solid rgb(234 234 234)" }}>
									{item}
								</div>
							)}
						</div>
					</Button>
				);
			}
		}
		if (mode === "3") { // 3 - operation
			if (multiparam) {
				var step = criterion.step || 1;
				return (
					<Button style={{ height: "36px", lineHeight: "39px" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div onClick={() => change(nextDigitValue(value), protocolDancerParam, criterion)} style={{ color: "#000", flex: "auto", padding: "7px 7px", wordWrap: "break-word", overflowWrap: "break-word", whiteSpace: "break-spaces", fontSize: "12px", lineHeight: "12px", textAlign: "left" }}>
								{t(protocolDancerParam.criterionParams.name)}
							</div>
							<div style={{ display: "flex", justifyContent: "right" }}>
								<div className={classes.btnActive}
									onClick={() => operation(
										valuesArray().map((item, idx) => {
											var stl = (value == item) ? {
												backgroundColor: "#f9f9f9",
												fontWeight: "600",
												fontSize: "20px",
												color: "#000", //"#ff8405",
												textShadow: "1px 1px 2px #848484, 0 0 1em white",
												textAlign: "center"
											} : {
												fontWeight: "400",
												fontSize: "20px",
												color: "#c1c1c1",
												textAlign: "center"
											};
											return { text: item, style: stl, onPress: () => (value == item) ? change(undefined, protocolDancerParam, criterion) : change(item, protocolDancerParam, criterion) }
										}
										)
									)}
									style={{ borderLeft: "1px solid rgb(234 234 234)" }}>
									{(value) ? value : undefined}
								</div>
							</div>
						</div>
					</Button>
				);
			} else {
				return (
					<Button style={{ height: "36px", lineHeight: "39px" }}
						onClick={() => operation(
							valuesArray().map((item, idx) => {
								var stl = (value == item) ? {
									backgroundColor: "#f9f9f9",
									fontWeight: "600",
									fontSize: "20px",
									color: "#000", //"#ff8405",
									textShadow: "1px 1px 2px #848484, 0 0 1em white",
									textAlign: "center"
								} : {
									fontWeight: "400",
									fontSize: "20px",
									color: "#c1c1c1",
									textAlign: "center"
								};
								return { text: item, style: stl, onPress: () => (value == item) ? change(undefined, protocolDancerParam, criterion) : change(item, protocolDancerParam, criterion) }
							}
							)
						)}>
						<div className={classes.btnActive}>
							{(value) ? value : undefined}
						</div>
					</Button>
				);
			}
		}
		if (mode === "4" || mode === "5" || mode === "6") { // 4 - modal   5 - modal-fullscreen   6 - popup
			// const findProtocolDancer = (protocol, protocolDancerParam) => {
			// 	var pr = { ...protocol };
			// 	if (pr.Protocol && pr.Protocol.protocolDancer) {
			// 		for (let item = 0; item < pr.Protocol.protocolDancer.length; item++) {
			// 			const protocolDancer = pr.Protocol.protocolDancer[item];
			// 			for (let p = 0; p < protocolDancer.protocolDancerParams.length; p++) {
			// 				const protocolDancerParam = protocolDancer.protocolDancerParams[p];
			// 				if (protocolDancerParam.ID === protocolParam.ID) {
			// 					return protocolDancer;
			// 				}
			// 			}
			// 		}
			// 	}
			// };
			// const title = (protocol, protocolDancerParam) => {

			// };
			if (multiparam) {
				var step = criterion.step || 1;
				return (
					<>
						<RenderToLayer>
							<Modal
								visible={getModal(protocolDancerParam.criterionParams.ID)}

								popup={(mode === "6") ? true : false}
								animationType={"slide-up"}
								transparent={(mode === "4") ? true : false}

								onClose={closeModal(protocolDancerParam.criterionParams.ID)}
								title={<div style={{ display: "flex", justifyContent: "center" }}>
									<div style={{ paddingRight: "13px", paddingTop: "3px" }}>{dancerIconSmall(protocolDancer.dancerBunch.dancerTour.dancerEvent.number)}</div>
									<div style={{ textAlign: "left", paddingTop: "2px", lineHeight: "31px" }}>
										<div>{protocolDancer.dancerBunch.dancerTour.dancerEvent.dancer.lastname} {protocolDancer.dancerBunch.dancerTour.dancerEvent.dancer.firstname}</div>
									</div>
								</div>}
								wrapProps={{ onTouchStart: onWrapTouchStart }}
							>
								<div>
									<div style={{ fontWeight: "600" }}>{t(criterion.criterion.name)}</div>
									<div style={{ paddingBottom: "10px" }}>{t(protocolDancerParam.criterionParams.name)}</div>
									<Button size={"small"} onClick={() => {
										change(undefined, protocolDancerParam, criterion);
										closeModal(protocolDancerParam.criterionParams.ID)();
									}}>{t("Очистить")}</Button>
									<div style={{ maxHeight: "400px", overflowY: "scroll" }}>
										{valuesArray().map((item, idx) => {
											var stl = (value == item) ? {
												backgroundColor: "#f9f9f9",
												fontWeight: "600",
												fontSize: "20px",
												color: "#000", //"#ff8405",
												textShadow: "1px 1px 2px #848484, 0 0 1em white",
												textAlign: "center"
											} : {
												fontWeight: "400",
												fontSize: "20px",
												color: "#c1c1c1",
												textAlign: "center"
											};
											return (
												<SwipeAction
													key={idx}
													disabled={(value !== item)}
													style={{ backgroundColor: 'gray' }}
													autoClose
													right={[
														{
															text: <Icon type="cross" />,
															onPress: () => {
																change(undefined, protocolDancerParam, criterion);
																closeModal(protocolDancerParam.criterionParams.ID)();
															},
															style: { backgroundColor: '#F4333C', color: 'white' },
														},
													]}
												>
													<List.Item style={{ ...stl, width: "100%" }} onClick={(e) => {
														if (value !== item) {
															change(item, protocolDancerParam, criterion);
														}
														closeModal(protocolDancerParam.criterionParams.ID)();
													}}>
														<div style={{ width: "100%", textAlign: "center" }}>{item}</div>
													</List.Item>
												</SwipeAction>
											);
										}
										)}
									</div>
								</div>
							</Modal>
						</RenderToLayer>
						<Button style={{ height: "36px", lineHeight: "39px" }}>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<div onClick={() => change(nextDigitValue(value), protocolDancerParam, criterion)} style={{ color: "#000", flex: "auto", padding: "7px 7px", wordWrap: "break-word", overflowWrap: "break-word", whiteSpace: "break-spaces", fontSize: "12px", lineHeight: "12px", textAlign: "left" }}>
									{t(protocolDancerParam.criterionParams.name)}
								</div>
								<div style={{ display: "flex", justifyContent: "right" }}>
									<div className={classes.btnActive}
										onClick={showModal(protocolDancerParam.criterionParams.ID)}
										style={{ borderLeft: "1px solid rgb(234 234 234)" }}>
										{(value) ? value : undefined}
									</div>
								</div>
							</div>
						</Button>
					</>
				);
			} else {
				return (
					<>
						<RenderToLayer>
							<Modal
								visible={getModal(protocolDancerParam.criterionParams.ID)}
								popup={(mode === "6") ? true : false}
								animationType={"slide-up"}
								transparent={(mode === "4") ? true : false}
								onClose={closeModal(protocolDancerParam.criterionParams.ID)}
								title={<div style={{ display: "flex", justifyContent: "center" }}>
									<div style={{ paddingRight: "13px", paddingTop: "3px" }}>{dancerIconSmall(protocolDancer.dancerBunch.dancerTour.dancerEvent.number)}</div>
									<div style={{ textAlign: "left", paddingTop: "2px", lineHeight: "31px" }}>
										<div>{protocolDancer.dancerBunch.dancerTour.dancerEvent.dancer.lastname} {protocolDancer.dancerBunch.dancerTour.dancerEvent.dancer.firstname}</div>
									</div>
								</div>}
								wrapProps={{ onTouchStart: onWrapTouchStart }}
							>
								<div>
									<div style={{ fontWeight: "600" }}>{t(criterion.criterion.name)}</div>
									<div style={{ paddingBottom: "10px" }}>{t(protocolDancerParam.criterionParams.name)}</div>
									<Button size={"small"} onClick={() => {
										change(undefined, protocolDancerParam, criterion);
										closeModal(protocolDancerParam.criterionParams.ID)();
									}}>{t("Очистить")}</Button>
									<div style={{ maxHeight: "400px", overflowY: "scroll" }}>
										{valuesArray().map((item, idx) => {
											var stl = (value == item) ? {
												backgroundColor: "#f9f9f9",
												fontWeight: "600",
												fontSize: "20px",
												color: "#000", //"#ff8405",
												textShadow: "1px 1px 2px #848484, 0 0 1em white",
												textAlign: "center"
											} : {
												fontWeight: "400",
												fontSize: "20px",
												color: "#c1c1c1",
												textAlign: "center"
											};
											return (
												<SwipeAction
													key={idx}
													disabled={(value !== item)}
													style={{ backgroundColor: 'gray' }}
													autoClose
													right={[
														{
															text: <Icon type="cross" />,
															onPress: () => {
																change(undefined, protocolDancerParam, criterion);
																closeModal(protocolDancerParam.criterionParams.ID)();
															},
															style: { backgroundColor: '#F4333C', color: 'white' },
														},
													]}
												>
													<List.Item style={{ ...stl, width: "100%" }} onClick={(e) => {
														if (value !== item) {
															change(item, protocolDancerParam, criterion);
														}
														closeModal(protocolDancerParam.criterionParams.ID)();
													}}>
														<div style={{ width: "100%", textAlign: "center" }}>{item}</div>
													</List.Item>
												</SwipeAction>
											);
										}
										)}
									</div>
								</div>
							</Modal>
						</RenderToLayer>
						<Button style={{ height: "36px", lineHeight: "39px" }}
							onClick={showModal(protocolDancerParam.criterionParams.ID)}>
							<div className={classes.btnActive}>
								{(value) ? value : undefined}
							</div>
						</Button>
					</>

				);
			}
		}
	}

}
function BadgeWrapper(prop) {
	const { children, compare, style } = prop;
	const classes = useStyles()
	if (compare()) {
		return (<Badge dot className={classes.buttonBadge} style={{ ...style, background: "red" }}>{children}</Badge>);
	}
	return children;
};
function HomeMobile(props) {
	const { auth } = props;

	const userConfig = useSelector(REDUX_UserConfig.selector.object);
	const translates = useSelector(REDUX_Translate.selector.object);
	const t = React.useMemo(() => _.bind(translate, {
		translate: translates,
		config: userConfig
	}), [translates, userConfig]);

	// console.log(t("да"));

	const logout = () => {
		const { auth, history } = props
		auth.logout(() => {
			history.replace('/');
			history.go(0);
		});
	}
	const [open, setOpen] = useState(false);
	const onOpenChange = (args) => {
		setOpen(!open);
	}
	const loading = useSelector(state => state.mobile.loading);
	const user = useSelector(state => state.mobile.user);
	const [selectedTab, setSelectedTab] = useState('blueTab');
	const [hidden] = useState(true);
	const [online, setOnline] = useState(false);
	const [openModalVisible, setOpenModalVisible] = useState(false);
	const [closeModalVisible, setCloseModalVisible] = useState(false);
	const [openModalObjects, setOpenModalObjects] = useState([]);
	const [closeModalObjects, setCloseModalObjects] = useState([]);
	const dispatch = useDispatch();
	const currentProtocol = useSelector(REDUX_Protocol.selector.object);
	const arrayProtocol = useSelector(REDUX_Protocol.selector.array);

	const statistic = useSelector(state => state.mobile.stat) || stat(currentProtocol);
	const { getState } = useStore();
	var peopleOnTabs = false;
	if (currentProtocol && currentProtocol.Protocol && currentProtocol.Protocol.judgeBunch.bunch.tour.protocolValueTypeID !== 1) {
		peopleOnTabs = true;
	}

	const onWrapTouchStart = (e) => {
		// fix touch to scroll background page on iOS
		if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
			return;
		}
		const pNode = closest(e.target, '.am-modal-content');
		if (!pNode) {
			e.preventDefault();
		}
	}
	useEffect(
		() => {
			auth.fetch('/api/iam').then(res => {
				if (res && res.data) {
					dispatch({ type: 'USER', payload: res.data })
				}
			});
			CRUDE_Translate.read(auth, (data) => {
				if (data.length > 0) {
					let o = Object.fromEntries(data.map(i => {
						return [
							i.key,
							{
								ru: i.ru,
								en: i.en,
								es: i.es
							},
						]
					}));
					dispatch(REDUX_Translate.actions.setObject(o));
				}
			}, 'model');
			CRUDE_UserConfig.read(auth, (data) => {
				if (data.length > 0) {
					var obj = Object.fromEntries(data.map(i => {
						return [
							i.key,
							i.value
						]
					}));
					dispatch(REDUX_UserConfig.actions.setObject(obj));
				}
			}, 'model', '');
			auth.openSocket("/ws/service", "service", function (type, payload) {
				if (type === "reload") {
					var href = window.location.href;
					window.location.href = href;
				}
				var currentProtocol = REDUX_Protocol.selector.object(getState());
				var arrayProtocol = REDUX_Protocol.selector.array(getState());
				switch (type) {
					case "call-mycurrentprotocol":
						mycurrentprotocol(auth, dispatch, (currentProtocol) ? currentProtocol.Protocol : undefined);
						break;
					case "call-mycurrentprotocol-open":
						mycurrentprotocol(auth, dispatch, (currentProtocol) ? currentProtocol.Protocol : undefined, (protocol, array) => {
							showInformation(protocol, arrayProtocol, array, payload, "open");
						});
						break;
					case "call-mycurrentprotocol-close":
						mycurrentprotocol(auth, dispatch, (currentProtocol) ? currentProtocol.Protocol : undefined, (protocol, array) => {
							showInformation(protocol, arrayProtocol, array, payload, "close");
						});
						break;
					case "ProtocolDancerParamsValue":
						setCurrentProtocolParamValue(dispatch, arrayProtocol, currentProtocol, payload, payload.value);
						break;
					default:
						break;
				}
			},
				//onopen
				function () {
					setOnline(true);
				},
				//onclose
				function () {
					setOnline(false);
				});
			return () => {
				auth.closeSocket("service");
			};
		},
		[auth, dispatch, getState]
	);
	const showInformation = (protocol, oldarray, newarray, payload, event) => {
		if (event === "open") {
			// var protocols = oldarray.filter(item => payload.find(i => i.ID===item.Protocol.judgeBunch.bunchID) !== undefined);
			setOpenModalObjects(newarray);
			setOpenModalVisible(true);
		} else if (event === "close") {
			var protocols = oldarray.filter(item => payload.find(i => i.ID === item.Protocol.judgeBunch.bunchID) !== undefined);
			setCloseModalObjects(protocols);
			setCloseModalVisible(true);
		}
	};
	useEffect(() => {
		mycurrentprotocol(auth, dispatch, (currentProtocol) ? currentProtocol.Protocol : undefined);
	}, [auth, dispatch]);

	const findParam = (protocolDancer, p) => {
		var res = undefined;
		protocolDancer.protocolDancerParams.forEach(param => {
			if (param.criterionParamsID === p.ID) {
				res = param;
				return;
			}
		});
		return res;
	};
	const badgedProtocolPeople = (current, protocol, people, style) => {
		var st = statPeople(protocol, people);
		if (
			(!st[current.Criterion.ID] || !st[current.Criterion.ID].good)) {
			return (<Badge dot style={{ background: "red", ...style }}>{t(current.Criterion.name)}</Badge>);
		}
		st = statPrepair(protocol);
		if (
			(!st[current.Criterion.ID] || !st[current.Criterion.ID].check)) {
			return (<Badge dot style={{ background: "orange", ...style }}>{t(current.Criterion.name)}</Badge>);
		}
		return t(current.Criterion.name);
	};
	const badgedProtocol = (current, protocol, style) => {
		var st = statPrepair(protocol);
		if (
			(!st[current.Criterion.ID] || !st[current.Criterion.ID].good)) {
			return (<Badge dot style={{ background: "red", ...style }}>{t(current.Criterion.name)}</Badge>);
		}
		if (
			(!st[current.Criterion.ID] || !st[current.Criterion.ID].check)) {
			return (<Badge dot style={{ background: "orange", ...style }}>{t(current.Criterion.name)}</Badge>);
		}
		return t(current.Criterion.name);
	};
	const badged = (current) => {
		if (
			(!statistic[current.Criterion.ID] || !statistic[current.Criterion.ID].good)) {
			return (<Badge dot style={{ background: "red" }}>{t(current.Criterion.name)}</Badge>);
		}
		if (
			(!statistic[current.Criterion.ID] || !statistic[current.Criterion.ID].check)) {
			return (<Badge dot style={{ background: "orange" }}>{t(current.Criterion.name)}</Badge>);
		}
		return t(current.Criterion.name);
	};
	const parametersLineByCriterion = (protocolCriterian, protocol) => {
		var arr = [];
		if (protocol.judgeBunch.judgeTour.judgeEvent.judge.genderID === 1) {
			if (protocolCriterian.ProtocolParamsMan) {
				protocolCriterian.ProtocolParamsMan.forEach(param => {
					arr.push({ ...param, title: t(param.CriterionParams.name) });
				});
			}
		} else {
			if (protocolCriterian.ProtocolParamsWoman) {
				protocolCriterian.ProtocolParamsWoman.forEach(param => {
					arr.push({ ...param, title: t(param.CriterionParams.name) });
				});
			}
		}
		return (arr);
	};
	const criterionLine = () => {
		var arr = [];
		if (currentProtocol) {
			currentProtocol.ProtocolCriterian.forEach(current => {
				var cp = parametersLineByCriterion(current, (currentProtocol) ? currentProtocol.Protocol : undefined);
				if (cp.length) {
					arr.push({
						...current,
						title: badged(current)
					});
				}
			});
		}
		return (arr);
	};
	const peopleLine = () => {
		var arr = [];
		currentProtocol.Protocol.protocolDancer.forEach(el => {
			arr.push({
				...el,
				_protocol: currentProtocol.Protocol,
				title: (
					<div style={{ display: "flex", justifyContent: "start", flexDirection: "column" }}>
						<div style={{ display: "flex", justifyContent: "start" }}>
							<div style={{ paddingRight: "13px", paddingTop: "3px" }}>
								<BadgeWrapper compare={() => {
									var s = statPeople(currentProtocol.Protocol, el);
									var good = true;
									Object.keys(s).forEach(i => {
										if (!s[i].good) {
											good = false;
										}
									});
									return !good;
								}}>
									{dancerIconSmall(el.dancerBunch.dancerTour.dancerEvent.number, "#8a8a8a", "#717171")}
								</BadgeWrapper>
							</div>
							<div style={{ textAlign: "left", paddingTop: "2px" }}>
								<div>{el.dancerBunch.dancerTour.dancerEvent.dancer.lastname}</div>
								<div>{el.dancerBunch.dancerTour.dancerEvent.dancer.firstname}</div>
							</div>
						</div>
						<div style={{ minHeight: "15px", paddingLeft: "40px" }}>
							{currentProtocol.Protocol.judgeBunch.bunch.tour.protocolValueTypeID === 1 &&
								<div style={{ textAlign: "left", backgroundColor: "transparent", fontSize: "12px", color: "rgb(0,0,0,0.65)" }}>
									<div>
										<div style={{ color: "green", display: "flex", justifyContent: "space-between" }}>
											<div style={{}}>
												<span style={{ marginLeft: "-1px" }}>+</span>
												<span style={{ paddingLeft: "1px" }}>:</span>
											</div>
											<div>{sumPlus(el.protocolDancerParams.map(item => item.value))}</div>
										</div>
										<div style={{ color: "red", display: "flex", justifyContent: "space-between" }}>
											<div style={{}}>
												<span>-</span>
												<span style={{ paddingLeft: "3px" }}>:</span>
											</div>
											<div>{sumMinus(el.protocolDancerParams.map(item => item.value))}</div>
										</div>
									</div>
								</div>
							}
							{currentProtocol.Protocol.judgeBunch.bunch.tour.protocolValueTypeID !== 1 &&
								<div style={{ textAlign: "left", backgroundColor: "transparent", fontSize: "12px", color: "rgb(0,0,0,0.65)" }}>
									<div>
										<div style={{ display: "flex", justifyContent: "space-between" }}>
											<div>{el.protocolDancerParams.map(item => (item.value) ? item.value : 0).reduce(reducer)}</div>
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				)
			});
		})
		return (arr);
	};

	const sumPlus = (arr) => {
		var acc = 0;
		arr.forEach(item => (item > 0) ? acc += 1 : acc);
		return acc;
	}
	const sumMinus = (arr) => {
		var acc = 0;
		arr.forEach(item => (item < 0) ? acc += 1 : acc);
		return acc;
	}
	const reducer = (accumulator, currentValue) => accumulator + currentValue;

	const contentByCriterion = protocolCriterian => {
		var mult = parametersLineByCriterion(protocolCriterian, (currentProtocol) ? currentProtocol.Protocol : undefined).length > 1;
		const dancerStyle = (mult) ? {
			padding: "5px 0",
			borderBottom: "1px solid transparent",
			borderImage: "radial-gradient(rgb(0,0,0,0.65), transparent) 1",
			// background-image: linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
			// background-image: -o-linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
			// background-image: -moz-linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
			// background-image: -webkit-linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
			// background-image: -ms-linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
			// background-image: -webkit-gradient( linear, left bottom, right bottom, color-stop(0.02, white), color-stop(0.5, gray), color-stop(0.98, white) );

		} : {};
		return (
			<>
				{currentProtocol &&
					<div>
						<WhiteSpace size={"lg"} />
						{currentProtocol.Protocol.protocolDancer.map(protocolDancer =>
							<div key={protocolDancer.ID} style={dancerStyle}>
								<div style={{ minHeight: "40px", border: "none", display: "flex", justifyContent: "space-between" }}>
									<div style={{ paddingRight: "13px" }}>{dancerIcon(protocolDancer.dancerBunch.dancerTour.dancerEvent.number)}</div>
									{currentProtocol.Protocol.judgeBunch.bunch.tour.protocolValueTypeID === 1 &&
										<div style={{ textAlign: "left", paddingRight: "10px", minWidth: "35px", minHeight: "40px", backgroundColor: "transparent", fontSize: "12px", color: "rgb(0,0,0,0.65)" }}>
											<div>
												<div style={{ color: "green", display: "flex", justifyContent: "space-between" }}>
													<div style={{}}>
														<span style={{ marginLeft: "-1px" }}>+</span>
														<span style={{ paddingLeft: "1px" }}>:</span>
													</div>
													<div>{sumPlus(protocolDancer.protocolDancerParams.map(item => item.value))}</div>
												</div>
												<div style={{ color: "red", display: "flex", justifyContent: "space-between" }}>
													<div style={{}}>
														<span>-</span>
														<span style={{ paddingLeft: "3px" }}>:</span>
													</div>
													<div>{sumMinus(protocolDancer.protocolDancerParams.map(item => item.value))}</div>
												</div>
											</div>
										</div>
									}
									{currentProtocol.Protocol.judgeBunch.bunch.tour.protocolValueTypeID !== 1 &&
										<div style={{ textAlign: "left", paddingRight: "10px", minWidth: "35px", minHeight: "40px", backgroundColor: "transparent", fontSize: "12px", color: "rgb(0,0,0,0.65)" }}>
											<div>
												<div style={{ display: "flex", justifyContent: "space-between" }}>
													<div>{protocolDancer.protocolDancerParams.map(item => (item.value) ? item.value : 0).reduce(reducer)}</div>
												</div>
											</div>
										</div>
									}
									<div style={{ flex: "auto" }}>
										{parametersLineByCriterion(protocolCriterian, currentProtocol.Protocol).map(param => {
											var pp = findParam(protocolDancer, param.CriterionParams);
											return (<div key={param.CriterionParams.ID} style={{ paddingBottom: "1px" }}>
												<ValueButton auth={auth} value={((pp) ? pp.value : undefined)} protocolDancer={protocolDancer} userConfig={userConfig} protocol={currentProtocol.Protocol} protocolDancerParam={pp} criterion={param.CriterionParams} multiparam={mult}></ValueButton>
											</div>);
										})}
									</div>
								</div>
							</div>)}
					</div>
				}
			</>
		);
	};
	const contentByPeouple = people => {
		var mult = false;
		const _p = people._protocol;
		currentProtocol.ProtocolCriterian.forEach(criterian => {
			if (parametersLineByCriterion(criterian, _p).length > 1) {
				mult = true;
			}
		});
		const dancerStyle = (mult) ? {
			padding: "5px 0",
			borderBottom: "1px solid transparent",
			borderImage: "radial-gradient(rgb(0,0,0,0.65), transparent) 1",
		} : {};
		return (
			<>
				{_p &&
					<div key={_p.ID + "p"}>
						{/* {currentProtocol.Protocol.judgeBunch.bunch.tour.protocolValueTypeID === 1 &&
							<div style={{ textAlign: "left", paddingRight: "10px", minWidth: "25px", minHeight: "40px", backgroundColor: "transparent", fontSize: "12px", color: "rgb(0,0,0,0.65)" }}>
								<div>
									<div style={{ color: "green", display: "flex", justifyContent: "space-between" }}>
										<div style={{}}>
											<span style={{ marginLeft: "-1px" }}>+</span>
											<span style={{ paddingLeft: "1px" }}>:</span>
										</div>
										<div>{sumPlus(people.protocolDancerParams.map(item => item.value))}</div>
									</div>
									<div style={{ color: "red", display: "flex", justifyContent: "space-between" }}>
										<div style={{}}>
											<span>-</span>
											<span style={{ paddingLeft: "3px" }}>:</span>
										</div>
										<div>{sumMinus(people.protocolDancerParams.map(item => item.value))}</div>
									</div>
								</div>
							</div>
						}
						{currentProtocol.Protocol.judgeBunch.bunch.tour.protocolValueTypeID !== 1 &&
							<div style={{ textAlign: "left", paddingRight: "10px", minWidth: "25px", minHeight: "40px", backgroundColor: "transparent", fontSize: "12px", color: "rgb(0,0,0,0.65)" }}>
								<div>
									<div style={{ display: "flex", justifyContent: "space-between" }}>
										<div>{people.protocolDancerParams.map(item => (item.value) ? item.value : 0).reduce(reducer)}</div>
									</div>
								</div>
							</div>
						} */}
						<WhiteSpace size={"lg"} />
						<div key={_p.ID}>
							{criterionLine().map((criterion, idx) => (
								<div key={idx} style={dancerStyle}>
									{mult == true && <div style={{ textAlign: "left", fontWeight: "600" }}>
										{badgedProtocolPeople(criterion, _p, people, { marginLeft: "5px" })}
									</div>}
									<div style={{ minHeight: "40px", borderLeft: (mult == true) ? "1px solid #1890ff" : "", display: "flex", justifyContent: "space-between" }}>
										{mult == true && <div style={{ paddingRight: "3px" }}></div>}
										<div style={{ flex: "auto" }}>
											{parametersLineByCriterion(criterion, _p).map(param => {
												var pp = findParam(people, param.CriterionParams);
												return (<div key={param.CriterionParams.ID} style={{
													paddingBottom: "1px",

													// paddingLeft: "3px",
													// borderLeft: (statistic[pp.criterionParams.criterionID] && statistic[pp.criterionParams.criterionID][pp.criterionParamsID] && statistic[pp.criterionParams.criterionID][pp.criterionParamsID].good === false) ? "2px solid red" : ""
												}}>
													{/* <Badge text={"9"} corner> */}
													<BadgeWrapper compare={() => statistic[pp.criterionParams.criterionID] && statistic[pp.criterionParams.criterionID][pp.criterionParamsID] && statistic[pp.criterionParams.criterionID][pp.criterionParamsID].good === false}>
														<ValueButton style={{ width: "100%" }} auth={auth} value={((pp) ? pp.value : undefined)} protocolDancer={people} userConfig={userConfig} protocol={_p} protocolDancerParam={pp} criterion={param.CriterionParams} multiparam={true} completed={currentProtocol.Protocol.completed}></ValueButton>
													</BadgeWrapper>
													{/* </Badge> */}
												</div>);
											})}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				}
			</>
		);
	};
	const completed = () => {
		alert(t('Готово?'), t('Вы уверены что хотите завершить заполнение протокола?'), [
			{ text: t('Отмена'), onPress: () => { } },
			{
				text: t('Да'), onPress: () => {
					var object = { ...currentProtocol.Protocol, completed: true };
					CRUDE_Protocol.update(auth, () => {
						mycurrentprotocol(auth, dispatch, (currentProtocol) ? currentProtocol.Protocol : undefined);
					}, object);
				}
			},
		])
	};
	const classes = useStyles()

	const badge = (statistic) => {
		if (!statistic.good) {
			return <Icon key={"1.2"} type="cross-circle" size={"xxs"} style={{ color: "red" }} />
		} else {
			if (!statistic.check) {
				return <Icon key={"1.3"} type="cross-circle" size={"xxs"} style={{ color: "orange" }} />
			} else {
				return <Icon key={"1.1"} type="check-circle-o" size={"xxs"} style={{ color: "green" }} />
			}
		}
	}

	const uiMode = [
		{ value: "1", label: "Одна кнопка" },// 1 - [btn]
		{ value: "2", label: "Группа кнопок" },// 2 - [btn][1][2][3][4]
		// { value: "3", label: "Компактный диалог", disabled: true},// 3 - operation
		{ value: "4", label: "Диалог" },// 4 - modal
		{ value: "5", label: "Полноэкранный диалог" },// 5 - modal-fullscreen
		{ value: "6", label: "Всплывающий диалог" },// 6 - popup
	];
	const onChangeUiMode = (value) => {
		POST(auth, "/api/setuserconfig", {
			key: "ui",
			value: value
		},
			(data) => {
				var arr = [data];
				var obj = Object.fromEntries(arr.map(i => {
					return [
						i.key,
						i.value
					]
				}));
				dispatch(REDUX_UserConfig.actions.setObject({ ...userConfig, ...obj }));
				setOpen(false);
			},
			(err, type) => {
				if (err) {
					if (type === "fail") {
						// message.error(err.message);
						if (err.exception) {
							console.error(err.exception)
						}
					}
					if (type === "error") {
						// message.error(err);
					}
				}
			});
	};
	const currentUiMode = () => {
		return (userConfig && userConfig.ui) ? userConfig.ui : "2";
	};
	const onChangeLang = (value) => {
		POST(auth, "/api/setuserconfig", {
			key: "lang",
			value: value
		},
			(data) => {
				var arr = [data];
				var obj = Object.fromEntries(arr.map(i => {
					return [
						i.key,
						i.value
					]
				}));
				dispatch(REDUX_UserConfig.actions.setObject({ ...userConfig, ...obj }));
			},
			(err, type) => {
				if (err) {
					if (type === "fail") {
						// message.error(err.message);
						if (err.exception) {
							console.error(err.exception)
						}
					}
					if (type === "error") {
						// message.error(err);
					}
				}
			});
	};
	
	const sidebar = (
		<div>
			<Card>
				<Card.Header
					title={<div>
						{online && <span style={{ color: "green", paddingLeft: "5px", paddingTop: "3px", fontSize: "14px" }}>
							{t("Online")}
						</span>}
						{!online && <span style={{ color: "red", paddingLeft: "5px", paddingTop: "3px", fontSize: "14px" }}>
							{t("Offline")}
						</span>}
					</div>}
					// thumb={<Icofont icon="user" style={{ color: mainDark }} />}
					extra={<a onClick={logout}>{t("Выход")}</a>}
				/>
				{user && <Card.Body>
					<div style={{ display: "flex", flexDirection: "row" }}>
						<div className={"unknown-avatar"}>
						</div>
						<div style={{ flex: "auto", paddingLeft: "15px" }}>
							<div>{user.lastname + " " + user.firstname}</div>
							<div style={{ color: "#9a9a9ab5" }}>{user.email}</div>
						</div>
					</div>
				</Card.Body>}
				{/* <Card.Footer content={user.email} /> */}
			</Card>
			{/* <MenuLevelOne active={currentlevel1} item1={levelItem1} item2={levelItem2} item3={levelItem3} menu={level1} onChange={levelOneChange} props={props} /> */}
			<List style={{ backgroundColor: 'white' }} className="picker-list">
				<Picker
					title={t("Language")}
					okText={t("Accept")}
					dismissText={t("Cancel")}
					extra={
						<div>
							{(userConfig && userConfig.lang) && <div>
								{userConfig.lang === 'ru' && <div><Flags.RU title="Русский" style={{ width: "20px" }} /> {userConfig.lang.toUpperCase()}</div>}
								{userConfig.lang === 'en' && <div><Flags.US title="English" style={{ width: "20px" }} /> {userConfig.lang.toUpperCase()}</div>}
								{userConfig.lang === 'es' && <div><Flags.ES title="Español" style={{ width: "20px" }} /> {userConfig.lang.toUpperCase()}</div>}
							</div>}
							{(!userConfig || (userConfig && !userConfig.lang)) && <div><Flags.RU title="Русский" style={{ width: "20px" }} /> RU</div>}
						</div>
					}
					data={[
						{
							label:
								(<div key="1"><Flags.RU title="Русский" style={{ width: "20px" }} /> RU</div>),
							value: 'ru',
						},
						{
							label:
								(<div key="2"><Flags.US title="English" style={{ width: "20px" }} /> EN</div>),
							value: 'en',
						},
						{
							label:
								(<div key="3"><Flags.ES title="Español" style={{ width: "20px" }} /> ES</div>),
							value: 'es',
						},
					]}
					value={[(userConfig && userConfig.lang) ? userConfig.lang : 'ru']}
					cols={1}
					onChange={(value) => onChangeLang(value[0])}
				>
					<List.Item key="1" arrow="horizontal">{t("Language")}</List.Item>
				</Picker>
			</List>
			<List renderHeader={() => t('Выбор способа отображения')}>
				{uiMode.map(i => (
					<RadioItem disabled={i.disabled} key={i.value} checked={currentUiMode() === i.value} onChange={() => onChangeUiMode(i.value)}>
						{t(i.label)}
					</RadioItem>
				))}
			</List>
		</div>
	);
	return (
		<div style={{ backgroundColor: "white", position: 'fixed', height: '100%', width: '100%', top: 0, display: "flex", flexDirection: "column" }}>
			<TranslateContext.Provider value={translates}>
				<UserConfigContext.Provider value={userConfig}>
					<div>
						<NavBar
							icon={<div className="logo" />}
							rightContent={[
								<Icon key="1" type="ellipsis" onClick={onOpenChange} />,
							]}
							style={{ backgroundColor: mainDark }}
						></NavBar>
						<WhiteSpace size="sm" style={{ backgroundColor: "white" }} />
					</div>
					<Drawer
						style={{ flex: "auto" }}
						className="my-drawer"
						sidebar={sidebar}
						open={open}
						onOpenChange={onOpenChange}
						position="right"
						sidebarStyle={{ border: '1px solid #ddd' }}
					>
						<TabBar
							unselectedTintColor="#949494"
							tintColor="#33A3F4"
							barTintColor="white"
							hidden={hidden}
						>
							<TabBar.Item
								title={t("Протокол")}
								key="Protocol"
								icon={<AuditOutlined style={{ fontSize: '24px' }} />}
								selectedIcon={<AuditOutlined style={{ fontSize: '24px' }} />}
								selected={selectedTab === 'blueTab'}
								onPress={() => {
									setSelectedTab('blueTab');
								}}
								data-seed="logId"
							>
								{
									<div style={{ backgroundColor: 'white', textAlign: 'center' }}>
										<WingBlank>
											<WhiteSpace />
											{currentProtocol &&
												<div>
													<div>
														{statistic && <NavBar
															className={classes.navbar}
															mode="light"
															leftContent={[
																<div key={"1"}>
																	{badge(statistic)}
																</div>,
															]}
														>
															<Picker
																className={classes.picker}
																data={arrayProtocol.map(p => {
																	return {
																		...p,
																		label: "№ " + p.Protocol.judgeBunch.bunch.tour.number + "-" + p.Protocol.number + ", " + t(p.Protocol.judgeBunch.bunch.tour.levelEvent.event.name),
																		value: p.Protocol.ID
																	}
																})}
																value={[currentProtocol.Protocol.ID]}
																cols={1}
																extra={<></>}
																okText={t("Выбрать")}
																dismissText={t("Отмена")}
																title={t("Протокол")}
																onOk={e => {
																	if (e.length) {
																		setCurrentProtocol(dispatch, arrayProtocol, arrayProtocol.find(item => item.Protocol.ID === e[0]))
																	}
																}}>
																<List.Item className={classes.pickerItem} arrow="horizontal">{t("Протокол")} № {currentProtocol.Protocol.judgeBunch.bunch.tour.number}-{currentProtocol.Protocol.number}</List.Item>
															</Picker>
														</NavBar>}

														<Row gutter={[5, 5]}>
															<Col span={24} style={{ fontSize: "12px", textAlign: "center" }}>
																<Typography>{t("Судья")}: {currentProtocol.Protocol.judgeBunch.judgeTour.judgeEvent.judge.lastname + " " + currentProtocol.Protocol.judgeBunch.judgeTour.judgeEvent.judge.firstname}</Typography>
																<Typography>{t("Тур")} {currentProtocol.Protocol.judgeBunch.bunch.tour.number + " (" + t(currentProtocol.Protocol.judgeBunch.bunch.tour.tourType.name) + "), " + t(currentProtocol.Protocol.judgeBunch.bunch.tour.levelEvent.event.name) + " (" + t(currentProtocol.Protocol.judgeBunch.bunch.tour.levelEvent.level.name) + ")"}</Typography>
																<Typography>{t("Заход")} {currentProtocol.Protocol.judgeBunch.bunch.number}</Typography>
															</Col>
														</Row>
														{/* {arrayProtocol.length > 1 && <Pagination mode="pointer" total={arrayProtocol.length} current={1} />} */}
													</div>
													{statistic && <div style={{ minHeight: "50px", width: "100%", backgroundColor: "#f5f5f9", borderRadius: "8px" }}>
														<Row gutter={[5, 5]}>
															<Col span={24}>
																<Button onClick={completed} disabled={!statistic.good || currentProtocol.Protocol.completed} style={{ margin: "0 2px" }}>
																	{!currentProtocol.Protocol.completed ? t("Готово?") : t("Протокол заполнен")}
																</Button>
															</Col>
														</Row>
													</div>}
													{/* <Tabs swipeable={false} tabs={criterionLine()} renderTabBar={props => <Tabs.DefaultTabBar {...props} page={3} />}
												tabBarTextStyle={{ lineHeight: "17.5px", fontSize: "13px" }}>
												{contentByCriterion}
											</Tabs> */}
													{peopleOnTabs === false && <Tabs swipeable={false} tabs={criterionLine()} renderTabBar={props => <Tabs.DefaultTabBar {...props} page={3} />}
														tabBarTextStyle={{ lineHeight: "17.5px", fontSize: "13px" }}>
														{contentByCriterion}
													</Tabs>}
													{peopleOnTabs === true && <Tabs swipeable={false} tabs={peopleLine()} renderTabBar={props => <Tabs.DefaultTabBar {...props} page={3} />}
														tabBarTextStyle={{ lineHeight: "17.5px", fontSize: "13px", marginTop: "5px" }}>
														{contentByPeouple}
													</Tabs>}
												</div>
											}
											{!currentProtocol && <>
												<Row gutter={[5, 5]}>
													<Col span={24} style={{ fontSize: "12px", textAlign: "center" }}>
														<Typography style={{ fontSize: "14px", textAlign: "center", paddingBottom: "15px" }}>{t("Нет доступных протоколов")}</Typography>
														<Button onClick={() => mycurrentprotocol(auth, dispatch, (currentProtocol) ? currentProtocol.Protocol : undefined)}>{t("Обновить")}</Button>
													</Col>
													<Col span={24} style={{ fontSize: "12px", textAlign: "center" }}>
														<div style={{ paddingTop: "25px" }}>
															<a href="#" onClick={logout}><LogoutOutlined style={{ paddingRight: "5px" }} />{t("Выход")}</a>
														</div>
													</Col>
												</Row>
											</>}
											<ActivityIndicator
												toast
												text={t("Загрузка...")}
												animating={loading}
											/>
										</WingBlank>
									</div>
								}
							</TabBar.Item>
							<TabBar.Item
								icon={<UserOutlined style={{ fontSize: '24px' }} />}
								selectedIcon={<UserOutlined style={{ fontSize: '24px' }} />}
								title={t("Судья")}
								key="judge"
								selected={selectedTab === 'yellowTab'}
								onPress={() => {
									setSelectedTab('yellowTab');
								}}
							>
								{
									<div style={{ backgroundColor: 'white', height: '100%', textAlign: 'center' }}>
										<WingBlank>
											<WhiteSpace />
											<Button type="primary" onClick={logout}>{t("Выход")}</Button><WhiteSpace />
											<WhiteSpace />
										</WingBlank>
									</div>
								}
							</TabBar.Item>
						</TabBar>
					</Drawer>
					<Modal
						visible={openModalVisible}
						transparent
						maskClosable={false}
						onClose={() => { setOpenModalVisible(false); }}
						title={t("Информация")}
						footer={[{ text: t('Ok'), onPress: () => { setOpenModalVisible(false); } }]}
						wrapProps={{ onTouchStart: onWrapTouchStart }}
					>
						<div >
							{openModalObjects.map(protocol => <>
								<div >
									<Row gutter={[5, 5]}>
										<Col span={24} style={{ fontSize: "14px", textAlign: "center" }}>
											<Typography style={{ fontWeight: "600" }}>{t("Заход")} №{protocol.Protocol.judgeBunch.bunch.number} {t("начат")}.</Typography>
											<Typography>{t("Протокол") + " №"+ protocol.Protocol.judgeBunch.bunch.tour.number + "-" + protocol.Protocol.number + " "+t("доступен для выставления оценок")}</Typography>
										</Col>
									</Row>

								</div>
							</>)}
						</div>
					</Modal>
					<Modal
						visible={closeModalVisible}
						transparent
						maskClosable={false}
						onClose={() => { setCloseModalVisible(false); }}
						title={t("Информация")}
						footer={[{ text: t('Ok'), onPress: () => { setCloseModalVisible(false); } }]}
						wrapProps={{ onTouchStart: onWrapTouchStart }}
					>
						<div >
							{closeModalObjects.map(protocol => <>
								<div >
									<Row gutter={[5, 5]}>
										<Col span={24} style={{ fontSize: "14px", textAlign: "center" }}>
											<Typography>{t(protocol.Protocol.judgeBunch.bunch.tour.levelEvent.event.name) + " (" + t(protocol.Protocol.judgeBunch.bunch.tour.levelEvent.level.name) + "), Тур №" + protocol.Protocol.judgeBunch.bunch.tour.number + " (" + t(protocol.Protocol.judgeBunch.bunch.tour.tourType.name) + ") "}</Typography>
											<Typography style={{ fontWeight: "600" }}>{t("Заход")} №{protocol.Protocol.judgeBunch.bunch.number} {t("завершен")}.</Typography>
											<Typography>{t("Протокол") + " №" + protocol.Protocol.judgeBunch.bunch.tour.number + "-" + protocol.Protocol.number + " "+t("принят")+"."}</Typography>
										</Col>
									</Row>
								</div>
							</>)}
						</div>
					</Modal>
				</UserConfigContext.Provider>
			</TranslateContext.Provider>
		</div>
	);
};

export default withRouter(HomeMobile);