import { combineReducers } from 'redux';
import { homeReducer } from './home'
import { metaReducer } from './meta'
import { menuReducer } from './menu'
import { mobileReducer } from './mobile'

import Bunch from '../objects/Bunch/reducer'

import City from '../objects/City/reducer'
import Criterion from '../objects/Criterion/reducer'
import CriterionLevel from '../objects/CriterionLevel/reducer'
import CriterionParams from '../objects/CriterionParams/reducer'
import Dancer from '../objects/Dancer/reducer'
import DancerBunch from '../objects/DancerBunch/reducer'
import Document from '../objects/Document/reducer'
import DocumentType from '../objects/DocumentType/reducer'
import Event from '../objects/Event/reducer'
import LevelEvent from '../objects/LevelEvent/reducer'
import Gender from '../objects/Gender/reducer'
import Judge from '../objects/Judge/reducer'
import JudgeBunch from '../objects/JudgeBunch/reducer'
import LevelDancer from '../objects/LevelDancer/reducer'
import Protocol from '../objects/Protocol/reducer'
import ProtocolDancer from '../objects/ProtocolDancer/reducer'
import ProtocolDancerParams from '../objects/ProtocolDancerParams/reducer'
import Region from '../objects/Region/reducer'
import Right from '../objects/Right/reducer'
import Role from '../objects/Role/reducer'
import RoleRight from '../objects/RoleRight/reducer'
import RoleUser from '../objects/RoleUser/reducer'
import Tour from '../objects/Tour/reducer'
import TourType from '../objects/TourType/reducer'
import User from '../objects/User/reducer'
import UserRight from '../objects/UserRight/reducer'
import Command from '../objects/Command/reducer'
import DancerCommand from '../objects/DancerCommand/reducer'

import DancerEvent from '../objects/DancerEvent/reducer'
import DancerTour from '../objects/DancerTour/reducer'
import JudgeEvent from '../objects/JudgeEvent/reducer'
import JudgeTour from '../objects/JudgeTour/reducer'

import ProtocolValueType from '../objects/ProtocolValueType/reducer'
import ResultEventLevel from '../objects/ResultEventLevel/reducer'

import CriterionTemplate from '../objects/CriterionTemplate/reducer'
import Template from '../objects/Template/reducer'
import CriterionParamsPriority from '../objects/CriterionParamsPriority/reducer'
import UserConfig from '../objects/UserConfig/reducer'
import Translate from '../objects/Translate/reducer'

const rootReducer = combineReducers({
    meta: metaReducer,
    menu: menuReducer,
    home: homeReducer,
    mobile: mobileReducer,

    Bunch: Bunch.reducer,
    Command: Command.reducer,
    DancerCommand: DancerCommand.reducer,
    City: City.reducer,
    Criterion: Criterion.reducer,
    CriterionLevel: CriterionLevel.reducer,
    CriterionParams: CriterionParams.reducer,
    Dancer: Dancer.reducer,
    DancerBunch: DancerBunch.reducer,
    Document: Document.reducer,
    DocumentType: DocumentType.reducer,
    Gender: Gender.reducer,
    Judge: Judge.reducer,
    JudgeBunch: JudgeBunch.reducer,
    Event: Event.reducer,
    LevelEvent: LevelEvent.reducer,
    LevelDancer: LevelDancer.reducer,
    Protocol: Protocol.reducer,
    ProtocolDancer: ProtocolDancer.reducer,
    ProtocolDancerParams: ProtocolDancerParams.reducer,
    Region: Region.reducer,
    Right: Right.reducer,
    Role: Role.reducer,
    RoleRight: RoleRight.reducer,
    RoleUser: RoleUser.reducer,
    Tour: Tour.reducer,
    TourType: TourType.reducer,
    User: User.reducer,
    UserRight: UserRight.reducer,
    DancerEvent: DancerEvent.reducer,
    DancerTour: DancerTour.reducer,
    JudgeEvent: JudgeEvent.reducer,
    JudgeTour: JudgeTour.reducer,

    ResultEventLevel: ResultEventLevel.reducer,
    ProtocolValueType: ProtocolValueType.reducer,

    CriterionTemplate: CriterionTemplate.reducer,
    Template: Template.reducer,
    CriterionParamsPriority: CriterionParamsPriority.reducer,
    UserConfig: UserConfig.reducer,
    Translate: Translate.reducer,
});

export default rootReducer;