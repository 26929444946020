const initialState = {
  currentlevel1: "common",
  currentlevel2: "events",
  currentlevel3: ""
}

export function menuReducer(state = initialState, action) {
  switch (action.type) {
    case 'PATH':
      var path = action.payload.replace(/\/$/, "");
      var split = path.replace("/", "").replace("/app", "").split("/");
      if (split.length > 1) {
        return {
          ...state,
          currentlevel1: split[0] || "",
          currentlevel2: split[1] || "",
          currentlevel3: split.slice(2).join("/") || "",
        }
      } else return { ...state, ...initialState }
    case 'CURRENT_LEVEL_1':
      return { ...state, currentlevel1: action.payload }
    case 'CURRENT_LEVEL_2':
      return { ...state, currentlevel2: action.payload }
    case 'CURRENT_LEVEL_3':
      return { ...state, currentlevel3: action.payload }
    default:
      return state
  }
}