import React, { useEffect } from 'react';
import { Form, Input } from 'antd';

import 'moment/locale/ru';

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

export default function UpdateJudge(props) {
    const { object } = props;

    // const gender = useSelector(REDUX_Gender.selector.array);
    // useEffect(() => {
    //     CRUDE_Gender.read(auth, (data) => {
    //         if (data.length > 0) {
    //             dispatch(REDUX_Gender.actions.setArray(data));
    //         }
    //     }, 'model');
    // }, []);
    // const options = gender.map(i => (
    //     <Option key={i.ID} value={i.ID}>{i.name}</Option>
    // ));

    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });

    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{
                ...object
                // name: object.name || "",
            }}
        >
            <Form.Item
                name={"lastname"}
                label={"Фамилия"}
                rules={[{ required: true, message: 'Укажите фамилию!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"firstname"}
                label={"Имя"}
                rules={[{ required: true, message: 'Укажите имя!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"patronymic"}
                label={"Отчество"}
            >
                <Input />
            </Form.Item>
            {/* <Form.Item
                name={"genderID"}
                label={"Пол"}
                rules={[{ required: true, message: 'Укажите пол!' }]}
            >
                <Select showSearch
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {options}
                </Select>
            </Form.Item> */}
            <Form.Item
                name={"username"}
                label={"Логин в системе"}
                rules={[{ required: true, message: 'Укажите логин!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"password"}
                label={"Пароль в системе"}
                rules={[{ required: true, message: 'Укажите пароль!' }]}
            >
                <Input.Password
                    placeholder="Введите пароль"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </Form.Item>
        </Form>
    );
}
