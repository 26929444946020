import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Table, Button, Menu, Card, Dropdown, Row, Col, Tabs, Tag, Modal, Select, message, Empty, Tooltip } from 'antd';

import {
	ExclamationCircleOutlined,
	CloseCircleOutlined,
	OrderedListOutlined,
	CloseOutlined,
	PrinterOutlined,
	StarOutlined,
	CheckOutlined,
	CheckCircleOutlined,
	DownOutlined,
	IssuesCloseOutlined
} from '@ant-design/icons';
import { createUseStyles } from 'react-jss'

import 'moment/locale/ru';

import DropdownAction from '../../components/DropdownAction'
import Action from '../../components/Action'
import Filtered from '../../components/Filtered'

import REDUX_Bunch from '../../objects/Bunch/reducer';
import CRUDE_Bunch from '../../objects/Bunch/crude';
import REDUX_Tour from '../../objects/Tour/reducer';
import CRUDE_Tour from '../../objects/Tour/crude';
// import REDUX_Event from '../../objects/Event/reducer';
// import CRUDE_Event from '../../objects/Event/crude';
import REDUX_LevelEvent from '../../objects/LevelEvent/reducer';
import CRUDE_LevelEvent from '../../objects/LevelEvent/crude';

import REDUX_DancerTour from '../../objects/DancerTour/reducer'
import CRUDE_DancerTour from '../../objects/DancerTour/crude'

import REDUX_DancerBunch from '../../objects/DancerBunch/reducer'
import CRUDE_DancerBunch from '../../objects/DancerBunch/crude'

import REDUX_Protocol from '../../objects/Protocol/reducer';
import CRUDE_Protocol from '../../objects/Protocol/crude';

import NewBunch from '../../forms/NewBunch'
import Protocol from '../../forms/Protocol'

import JudgeBunch from '../../forms/JudgeBunch'
import GoNextCountDancer from '../../forms/GoNextCountDancer'

import { GET } from '../../components/Tool'

const { Text, Link } = Typography;
const { confirm } = Modal;
const { Option } = Select;
const { TabPane } = Tabs;
const { CheckableTag } = Tag;
const protocolCriterionParamsCount = (protocol, protocolCriterionParams) => {
    var count = 0;
    protocolCriterionParams.forEach(protocolCriterian => {
        if (protocol.judgeBunch.judgeTour.judgeEvent.judge.genderID === 1) {
            if (protocolCriterian.ParamsMan && protocolCriterian.ParamsMan.length) {
                    count++;
            }
        } else {
            if (protocolCriterian.ParamsWoman && protocolCriterian.ParamsWoman.length) {
                    count++;
            }
        }
    });
    return count;
};
const stat = (protocol, criterionCount) => {
	criterionCount = criterionCount || 0;
	var count = 0;
	var s = {};
	if (!protocol) return;
	if (protocol.judgeBunch.judgeTour.judgeEvent.judge.genderID === 2) {
		count = protocol.judgeBunch.bunch.tour.scoresInCriterionW;
	} else {
		count = protocol.judgeBunch.bunch.tour.scoresInCriterionM;
	}

	protocol.protocolDancer.forEach(dancer => {
		dancer.protocolDancerParams.forEach(item => {
			if (item.value > 0 && protocol.judgeBunch.bunch.tour.protocolValueTypeID === 1) {
				if (!s[item.criterionParams.criterionID]) s[item.criterionParams.criterionID] = { value: 0, good: true, check: true, criterion: item.criterionParams.criterion };
				s[item.criterionParams.criterionID].value += (item.value !== null) ? item.value : 0;
				s[item.criterionParams.criterionID].good = (s[item.criterionParams.criterionID].value !== count) ? false : true;
			} else if (protocol.judgeBunch.bunch.tour.protocolValueTypeID !== 1) {
				if (!s[item.criterionParams.criterionID]) s[item.criterionParams.criterionID] = { value: 0, good: true, check: true, criterion: item.criterionParams.criterion };
				s[item.criterionParams.criterionID].value += (item.value !== null) ? item.value : 0;
				s[item.criterionParams.criterionID].good = (!s[item.criterionParams.criterionID].good || !item.value) ? false : true;

				if (!s[item.criterionParams.criterionID][item.criterionParamsID]) {
					s[item.criterionParams.criterionID][item.criterionParamsID] = { count: {} };
				}
				var countValue = s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value];
				if (!countValue) {
					s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value] = 1;
				} else {
					s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value]++;
				}

				if (s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value] > protocol.judgeBunch.bunch.tour.maxCountEqualScores) {
					s[item.criterionParams.criterionID].check = false;
				}
			}
		});
	});
	var good = true;
	var check = true;
	if (Object.keys(s).length !== criterionCount) {
		good = false;
	} else {
		Object.keys(s).forEach(i => {
			if (!s[i].good) {
				good = false;
			}
		});
	}

	Object.keys(s).forEach(i => {
		if (!s[i].check) {
			check = false;
		}
	});

	return {
		...s,
		good: good,
		check: check
	}
};

function Btn(props) {
	const dispatch = useDispatch();
	const { auth, object } = props;
	const empty = (done) => {
		if (done) done();
	};
	const bunchs = useSelector(REDUX_Bunch.selector.arrayByKey(object.tour.ID));
	const odelete = () => {
		CRUDE_Bunch.destroy(props.auth, () => {
			dispatch(REDUX_Bunch.actions.setArrayByKey(object.tour.ID, bunchs.filter(item => item.ID !== props.object.ID)));
		}, props.object.ID);
	};
	const makecurrent = () => {
		GET(auth, "/api/setcurrentbunch/" + object.ID,
			() => {
				if (object.tour) {
					CRUDE_Bunch.read(auth, (data) => {
						if (data.length > 0) {
							dispatch(REDUX_Bunch.actions.setArrayByKey((object.tour) ? object.tour.ID : 0, data));
						}
					}, 'model', "s-number=ASC&w-tour.id=" + ((object.tour) ? object.tour.ID : 0));
				}
			},
			(err, type) => {
				if (err) {
					if (type === "fail") {
						message.error(err.message);
						if (err.exception) {
							console.error(err.exception)
						}
					}
					if (type === "error") {
						message.error(err);
					}
				}
			});
	};
	return (
		<DropdownAction>
			<Menu.Item style={{ color: "blue" }} onClick={() => confirm({
				title: (props.object.current) ? 'Вы действительно хотите убрать у захода статус текущий?' : 'Вы действительно хотите назначить заход текущим?',
				icon: <ExclamationCircleOutlined />,
				content: 'При назначении захода текущим у всех судей в мобильном интерфейсе будут отображаться протоколы этого захода для заполнения',
				okText: (props.object.current) ? 'Отменить статус текущего' : 'Сделать текущим',
				cancelText: 'Отмена',
				onOk() {
					makecurrent();
				},
				onCancel() {
				},
			})}>{(props.object.current) ? 'Отменить статус текущего' : 'Сделать текущим'}</Menu.Item>
			{/* <Action {...props} readonly={true} modal={{ title: "Танцоры захода", width: "65%" }} object={props.object} mode={"MenuItem"} action={empty} title={"Танцоры захода"} form={DancerBunch} document={"dancerBunch"} /> */}
			<Action {...props} readonly={true} modal={{ title: "Судьи захода", width: "65%" }} object={props.object} mode={"MenuItem"} action={empty} title={"Судьи захода"} form={JudgeBunch} document={"judgeBunch"} />
			<Menu.Divider></Menu.Divider>
			<Menu.Item style={{ color: "red" }} onClick={() => confirm({
				title: 'Вы действительно хотите удалить заход тура?',
				icon: <ExclamationCircleOutlined />,
				content: 'Удаление захода приведет к потере всех связанных с ним данных',
				okText: 'Удалить',
				cancelText: 'Отмена',
				onOk() {
					odelete();
				},
				onCancel() {
				},
			})}>Удалить</Menu.Item>
		</DropdownAction>
	)
};

function BtnProtocol(props) {
	const dispatch = useDispatch();
	const { auth, object } = props;
	const empty = (done) => {
		if (done) done();
	};
	const prt = useSelector(REDUX_Protocol.selector.arrayByKey(object.judgeBunch.bunch.tourID));
	const odelete = () => {
		auth.fetch("/api/clearprotocolparams/" + props.object.ID).then(res => {
			if (res && res.status) {
				dispatch(REDUX_Protocol.actions.updateArrayByKey(object.judgeBunch.bunch.tourID, [res.data]));
			} else if (res && !res.status) {
				message.error(res.message);
			}
		});
	};
	return (
		<DropdownAction>
			{/* <Action {...props} readonly={true} modal={{ title: "Танцоры захода", width: "65%" }} object={props.object} mode={"MenuItem"} action={empty} title={"Танцоры захода"} form={DancerBunch} document={"dancerBunch"} /> */}
			<Action {...props} readonly={true} modal={{ title: "Судейский протокол № " + object.judgeBunch.bunch.tour.number + "-" + object.number, width: "1100px" }} object={props.object} mode={"MenuItem"} action={empty} title={"Протокол"} form={Protocol} document={"protocol"} />
			<Menu.Item key="print" icon={<PrinterOutlined />}>
				<a target="_blank" rel="noopener noreferrer" href={"/printprotocol/" + ((object) ? object.ID : 0)}>Печать</a>
			</Menu.Item>
			<Menu.Divider></Menu.Divider>
			<Menu.Item style={{ color: "red" }} onClick={() => confirm({
				title: 'Вы действительно хотите удалить оценки из протокола?',
				icon: <ExclamationCircleOutlined />,
				content: 'Удаление оценок протокола приведет к потере всех внесенных ранее данных',
				okText: 'Удалить',
				cancelText: 'Отмена',
				onOk() {
					odelete();
				},
				onCancel() {
				},
			})}>Удалить все оценки</Menu.Item>
		</DropdownAction>
	)
};

const useStyles = createUseStyles({
	divider: {
		'&.ant-divider-horizontal.ant-divider-with-text-left': {
			fontFamily: "-apple-system, BlinkMacSystemFont, Roboto, 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif",
			fontSize: "13px",
			fontWeight: "400",
			color: "rgb(0, 0, 0)",
		},
		'&.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text': {
			padding: "0px",
			marginRight: "10px",
		}
	},
	link: {
		'&:hover': {
			color: "#40a9ff !important"
		}
	}
})

export default function CommonBunch(props) {
	const classes = useStyles()
	const dispatch = useDispatch();
	const { auth } = props;
	const [loading, setLoading] = useState(false);
	const [loadingProtocol, setLoadingProtocol] = useState(false);
	const [deleteProtocols, setDeleteProtocols] = useState(false);
	const [dancerResultW, setDancerResultW] = useState([]);
	const [dancerResultM, setDancerResultM] = useState([]);
	const columns = [
		{
			title: 'Номер',
			render: (text, record) => {
				return (record.number);
			}
		},
		{
			title: 'Текущий заход',
			render: (text, record) => {
				return ((record.current === true) ? <CheckOutlined /> : <></>);
			}
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			fixed: 'right',
			width: 45,
			render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} />),
		},
	];

	useEffect(() => {
		setLoading(true);
		CRUDE_LevelEvent.read(auth, (data) => {
			setLoading(false);
			if (data.length > 0) {
				dispatch(REDUX_LevelEvent.actions.setArray(data));
			}
		}, 'model');
	}, [auth, dispatch]);

	const evdata = useSelector(REDUX_LevelEvent.selector.array);

	const [levelEvent, setLevelEvent] = useState(undefined);
	const levelEventChange = (value) => {
		var ev = evdata.find(item => item.ID === value);
		setLevelEvent(ev);
		setTour(undefined);
		if (ev) {
			CRUDE_Tour.read(auth, (data) => {
				setLoading(false);
				if (data.length > 0) {
					dispatch(REDUX_Tour.actions.setArrayByKey((ev) ? ev.ID : 0, data));
				}
			}, 'model', 's-number=ASC&w-levelEvent.id=' + ((ev) ? ev.ID : 0));
		}
	};
	const levelEvents = evdata.map(item => {
		return (
			<Option value={item.ID} key={item.ID}>{item.event.name + " (" + item.level.name + ")"}</Option>
		);
	});

	const edata = useSelector(REDUX_Tour.selector.arrayByKey((levelEvent) ? levelEvent.ID : 0));

	const [tour, setTour] = useState(undefined);
	const tourChange = (value) => {
		var t = edata.find(item => item.ID === value);
		setTour(t);
		CRUDE_DancerTour.read(auth, (data) => {
			if (data.length > 0) {
				dispatch(REDUX_DancerTour.actions.setArrayByKey(t.ID, data));
			}
		}, 'model', 'w-tour.id=' + t.ID);
		CRUDE_DancerBunch.read(auth, (data) => {
			if (data.length > 0) {
				dispatch(REDUX_DancerBunch.actions.setArrayByKey(-99, data));
			}
		}, 'model', 'w-bunch.tour.id=' + t.ID);
		CRUDE_Protocol.read(auth, (data) => {
			if (data.length > 0) {
				dispatch(REDUX_Protocol.actions.setArrayByKey(t.ID, data));
			}
		}, 'model', 's-number=ASC&w-judgeBunch.judgeTour.tour.id=' + t.ID);
		setDancerResultW([]);
		setDancerResultM([]);
	};
	const tours = edata.map(item => {
		return (
			<Option value={item.ID} key={item.ID}>{"Тур " + item.number + " (" + item.tourType.name + ")"}</Option>
		);
	});

	const [protocolCriterionParams, setProtocolCriterionParams] = useState([]);
	useEffect(() => {
		if (tour) {
			setLoading(true);
			CRUDE_Bunch.read(auth, (data) => {
				setLoading(false);
				if (data.length > 0) {
					dispatch(REDUX_Bunch.actions.setArrayByKey((tour) ? tour.ID : 0, data));
				}
			}, 'model', "s-number=ASC&w-tour.id=" + ((tour) ? tour.ID : 0));

			auth.fetch("/api/tour/" + ((tour) ? tour.ID : 0) + "/protocolcriterionparams").then(res => {
				if (res && res.data) {
					setProtocolCriterionParams(res.data);
				}
			});
		}
	}, [tour, auth, dispatch]);

	const data = useSelector(REDUX_Bunch.selector.arrayByKey((tour) ? tour.ID : 0));
	const update = (res) => {
		dispatch(REDUX_Bunch.actions.updateArrayByKey(tour.ID, res.data));
	};

	const dancers = useSelector(REDUX_DancerTour.selector.arrayByKey((tour) ? tour.ID : 0));
	const dancers_on_bunch = useSelector(REDUX_DancerBunch.selector.arrayByKey(-99));
	const findBunch = (dancerTour) => {
		var d = dancers_on_bunch.find(item => dancerTour.ID === item.dancerTour.ID);
		if (d) {
			return d.bunch;
		} else return undefined;
	};
	const newbunch = (done, values) => {
		if (!tour) { done(); return; }
		auth.fetch("/api/tour/" + tour.ID + "/bunchgenerate/" + values.count).then(res => {
			if (res && res.data) {
				update(res);
			} else if (res && !res.status) {
				message.error(res.message);
			}
			done();
		});
	};
	const bunch = data.map(item => {
		return (
			<Option value={item.ID} key={item.ID}>{item.number}</Option>
		);
	});
	const change = (value, dancerTour) => {
		if (!dancerTour) return;
		if (value) {
			auth.fetch("/api/dancerbunch/" + value + "/" + dancerTour.ID).then(res => {
				if (res && res.data) {
					dispatch(REDUX_DancerBunch.actions.updateArrayByKey(-99, [res.data]));
					CRUDE_Protocol.read(auth, (data) => {
						if (data.length > 0) {
							dispatch(REDUX_Protocol.actions.setArrayByKey(tour.ID, data));
						}
					}, 'model', 's-number=ASC&w-judgeBunch.judgeTour.tour.id=' + tour.ID);
				} else if (res && !res.status) {
					message.error(res.message);
				}
			});
		} else {
			auth.fetch("/api/dancerbunch/0/" + dancerTour.ID).then(res => {
				if (res && res.data) {
					dispatch(REDUX_DancerBunch.actions.setArrayByKey(-99, dancers_on_bunch.filter(item => item.ID !== res.data.ID)));
					CRUDE_Protocol.read(auth, (data) => {
						if (data.length > 0) {
							dispatch(REDUX_Protocol.actions.setArrayByKey(tour.ID, data));
						}
					}, 'model', 's-number=ASC&w-judgeBunch.judgeTour.tour.id=' + tour.ID);
				} else if (res && !res.status) {
					message.error(res.message);
				}
			});
		}
	};
	const dancerColumns = [
		{
			title: 'Танцор',
			render: (text, record) => {
				return (record.dancerEvent.dancer.lastname + " " + record.dancerEvent.dancer.firstname);
			}
		},
		{
			title: 'Пол',
			render: (text, record) => {
				return (record.dancerEvent.dancer.gender.name);
			}
		},
		{
			title: 'Номер',
			render: (text, record) => {
				return (record.dancerEvent.number);
			}
		},
		{
			title: 'Заход',
			dataIndex: '',
			key: 'x',
			fixed: 'right',
			width: 65,
			render: (text, record) => {
				var onebunch = findBunch(record);
				return (
					<Select allowClear style={{ width: "100%" }} value={(onebunch) ? ("" + onebunch.number) : ""} onChange={(value) => change(value, record)}>
						{bunch}
					</Select>
					// <InputNumber  defaultValue={(onebunch)?onebunch.number:undefined} onChange={(value)=>change(value, record)}></InputNumber>
				)
			},
		},
	];
	const dancerFilters = [
		{
			name: "Танцор",
			value: item => item.dancerEvent.dancer.lastname + " " + item.dancerEvent.dancer.firstname,
			field: "dancer",
			type: "string",
			sort: true,
			filter: true
		},
		{
			name: "Пол",
			value: item => item.dancerEvent.dancer.gender.name,
			field: "gender",
			type: "string",
			sort: true,
			filter: true
		},
		{
			name: "Номер",
			value: item => item.dancerEvent.number,
			field: "number",
			type: "string",
			sort: true,
			filter: true,
			sorted: "ASC"
		},
		{
			name: "Заход",
			value: item => {
				var onebunch = findBunch(item);
				return (onebunch) ? ("" + onebunch.number) : "";
			},
			field: "bunch",
			type: "string",
			sort: true,
			filter: true
		}
	];
	const badge = (statistic) => {
		if (!statistic.good) {
			return <CheckCircleOutlined style={{ color: "red" }} />
		} else {
			if (!statistic.check) {
				return <CheckCircleOutlined style={{ color: "orange" }} />
			} else {
				return <CheckCircleOutlined style={{ color: "green" }} />
			}
		}
	}

	const protocolColumns = [
		{
			title: 'Номер протокола',
			render: (text, record) => {
				return (record.judgeBunch.bunch.tour.number + "-" + record.number);
			}
		},
		{
			title: 'Заход',
			render: (text, record) => {
				return (record.judgeBunch.bunch.number);
			}
		},
		{
			title: 'Судья',
			render: (text, record) => {
				var judge = record.judgeBunch.judgeTour.judgeEvent.judge;
				return (judge.lastname + " " + judge.firstname);
			}
		},
		{
			title: 'Значения',
			render: (text, record) => {
				if (record.protocolDancer) {
					for (let i = 0; i < record.protocolDancer.length; i++) {
						const element = record.protocolDancer[i];
						if (element.protocolDancerParams) {
							for (let j = 0; j < element.protocolDancerParams.length; j++) {
								const p = element.protocolDancerParams[j];
								if (p.value) {
									return <Tooltip title="Есть значения">
										<IssuesCloseOutlined style={{ color: "green" }} />
									</Tooltip>

								}
							}
						}
					}
				}
				return <></>
			}
		},
		{
			title: 'Проверка',
			render: (text, record) => {
				var statistic = stat(record, protocolCriterionParamsCount(record, protocolCriterionParams))
				return (
					<span style={{ fontSize: "12px", marginRight: "10px" }}>
						{badge(statistic)}
					</span>)
			}
		},
		{
			title: 'Готово',
			render: (text, record) => {
				return (
					<span style={{ fontSize: "12px", marginRight: "10px" }}>
						{record.completed && <CheckOutlined style={{ color: "green" }} />}
						{!record.completed && <CloseOutlined style={{ color: "red" }} />}
					</span>)
			}
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			fixed: 'right',
			width: 45,
			render: (text, record, index) => (<BtnProtocol auth={auth} index={index + 1} object={record} />),
		},
	];
	const protocols = useSelector(REDUX_Protocol.selector.arrayByKey((tour) ? tour.ID : 0));
	const protocolgenerate = () => {
		if (!tour) return;

		if (tour) {
			setLoadingProtocol(true);
			auth.fetch("/api/tour/" + tour.ID + "/protocolgenerate").then(res => {
				if (res && res.data) {
					dispatch(REDUX_Protocol.actions.setArrayByKey(tour.ID, res.data));
					auth.fetch("/api/tour/" + ((tour) ? tour.ID : 0) + "/protocolcriterionparams").then(res => {
						if (res && res.data) {
							setProtocolCriterionParams(res.data);
						}
					});
				} else if (res && !res.status) {
					message.error(res.message);
				}
				setLoadingProtocol(false);
			});
		}

	};
	const deleteprotocols = () => {
		if (!tour) return;
		setDeleteProtocols(true);
		auth.fetch("/api/tour/" + tour.ID + "/deleteprotocols").then(res => {
			if (res && res.status) {
				dispatch(REDUX_Protocol.actions.setArrayByKey(tour.ID, []));
			} else if (res && !res.status) {
				message.error(res.message);
			}
			setDeleteProtocols(false);
		});
	};
	const star = (record, goNextTour) => {
		const update = (done, checked) => {
			CRUDE_DancerTour.update(props.auth, (data) => {
				dispatch(REDUX_DancerTour.actions.updateArrayByKey(tour.ID, [data]));
				// tourresultm();
				// tourresultw();
				setDancerResultM(dancerResultM.map(e => {
					if(e.DancerTour.ID === record.ID){
						e.DancerTour.goNextTour = checked;
						return e;
					}
					return e;
				}));
				setDancerResultW(dancerResultW.map(e => {
					if(e.DancerTour.ID === record.ID){
						e.DancerTour.goNextTour = checked;
						return e;
					}
					return e;
				}));
			}, { ...record, goNextTour: checked });
		};
		return (
			<CheckableTag style={{ backgroundColor: "transparent" }}
				checked={goNextTour}
				onChange={checked => update(record, checked)}>
				{goNextTour && <StarOutlined style={{ color: "orange", fontSize: "16px" }} />}
				{!goNextTour && <StarOutlined style={{ fontSize: "16px" }} />}
			</CheckableTag>
		);
	};
	// const columnsDancerResult = [
	//     {
	//         title: 'Танцор',
	//         render: (text, record, index) => {
	//             return (record.DancerTour.dancerEvent.dancer.lastname + " " + record.DancerTour.dancerEvent.dancer.firstname);
	//         }
	//     },
	//     {
	//         title: 'Пол',
	//         render: (text, record, index) => {
	//             return (record.DancerTour.dancerEvent.dancer.gender.name);
	//         }
	//     },
	//     {
	//         title: 'Номер',
	//         render: (text, record, index) => {
	//             return (record.DancerTour.dancerEvent.number);
	//         }
	//     },
	//     {
	//         title: 'Финалист',
	//         render: (text, record, index) => {
	//             return (
	//                 star(record.DancerTour, record.DancerTour.goNextTour)
	//             );
	//         }
	// 	},
	// 	{
	//         title: 'Оценка',// ()=>(<PlusCircleOutlined style={{ color: "green" }} />),
	//         render: (text, record, index) => {
	//             return (record.Plus);
	//         }
	//     },
	// ];

	const buildColumnsDancerResult = (dancerResult, tourID) => {
		// Прошел в следующий тур?
		// Финалист?
		// record.DancerTour.tour.tourTypeID == 1 // "Отборочный"
		// record.DancerTour.tour.tourTypeID == 2 // "Полуфинал"
		// record.DancerTour.tour.tourTypeID == 3 // "Финал"
		var head = [
			{
				title: 'Танцор',
				width: 180,
				fixed: 'left',
				render: (text, record) => {
					return (record.DancerTour.dancerEvent.dancer.lastname + " " + record.DancerTour.dancerEvent.dancer.firstname);
				}
			},
			{
				title: 'Номер',
				width: 100,
				fixed: 'left',
				render: (text, record) => {
					return (record.DancerTour.dancerEvent.number);
				}
			},
		];
		if (tourID === 1) {
			head.push({
				title: 'Прошел в следующий тур?',
				width: 100,
				fixed: 'left',
				render: (text, record) => {
					return (
						star(record.DancerTour, record.DancerTour.goNextTour)
					);
				}
			});
		}
		if (tourID === 2) {
			head.push({
				title: 'Финалист?',
				width: 100,
				fixed: 'left',
				render: (text, record) => {
					return (
						star(record.DancerTour, record.DancerTour.goNextTour)
					);
				}
			});
		}
		var body = [

		];
		if (dancerResult && dancerResult.length > 0) {
			dancerResult[0].TableSort.forEach((element, idx) => {
				body.push({
					title: element.Name,// ()=>(<PlusCircleOutlined style={{ color: "green" }} />),
					width: 100,
					render: (text, record) => {
						return (Math.round(record.TableSort[idx].Value * 100) / 100);
					}
				});
			});
		}
		var tail = [
			// {
			// 	title: 'Оценка',// ()=>(<PlusCircleOutlined style={{ color: "green" }} />),
			// 	render: (text, record, index) => {
			// 		return (record.Plus);
			// 	}
			// },
		];
		return [...head, ...body, ...tail];
	};
	const tourresultm = () => {
		if (!tour) return;
		auth.fetch("/api/tour/" + tour.ID + "/1/tourresult").then(res => {
			if (res && res.data) {
				setDancerResultM(res.data.filter(e => e.DancerTour.dancerEvent.dancer.genderID === 1));
				// setDancerResultW(res.data.filter(e => e.DancerTour.dancerEvent.dancer.genderID===2));
			} else if (res && !res.status) {
				message.error(res.message);
			}
		});
	};
	const tourresultw = () => {
		if (!tour) return;
		auth.fetch("/api/tour/" + tour.ID + "/2/tourresult").then(res => {
			if (res && res.data) {
				// setDancerResultM(res.data.filter(e => e.DancerTour.dancerEvent.dancer.genderID===1));
				setDancerResultW(res.data.filter(e => e.DancerTour.dancerEvent.dancer.genderID === 2));
			} else if (res && !res.status) {
				message.error(res.message);
			}
		});
	};
	const bunchSchemeCreate = (alg, gender) => {
		if (!tour) return;
		confirm({
			title: 'Вы действительно хотите переразместить танцоров на заходах?',
			icon: <ExclamationCircleOutlined />,
			content: '',
			okText: 'Да',
			cancelText: 'Отмена',
			onOk() {
				setLoading(true);
				auth.fetch("/api/autoadddancerbunch/tour/" + tour.ID + "/alg/" + alg + "/gender/" + gender).then(res => {
					if (res && res.data) {
						dispatch(REDUX_DancerBunch.actions.setArrayByKey(-99, res.data));
					} else if (res && !res.status) {
						message.error(res.message);
					}
					setLoading(false);
				});
			},
			onCancel() {
			},
		})

	};
	const bunchSchemeDelete = () => {
		if (!tour) return;
		confirm({
			title: 'Вы действительно хотите удалить танцоров на заходах?',
			icon: <ExclamationCircleOutlined />,
			content: '',
			okText: 'Да',
			cancelText: 'Отмена',
			onOk() {
				setLoading(true);
				auth.fetch("/api/dropalldancerbunch/" + tour.ID).then(res => {
					if (res && res.status) {
						dispatch(REDUX_DancerBunch.actions.setArrayByKey(-99, []));
					} else if (res && !res.status) {
						message.error(res.message);
					}
					setLoading(false);
				});
			},
			onCancel() {
			},
		})
	};
	// const print = (tourID) => {
	// 	history.push("/tour/"+tourID+"/printprotocols");
	// };

	const printMenu = (
		<Menu>
			<Menu.Item key="1" icon={<PrinterOutlined />}>
				<a target="_blank" rel="noopener noreferrer" href={"/tour/" + ((tour) ? tour.ID : 0) + "/printdataprotocols"}>Заполненные протоколы</a>
			</Menu.Item>
			<Menu.Item key="2" icon={<PrinterOutlined />}>
				<a target="_blank" rel="noopener noreferrer" href={"/tour/" + ((tour) ? tour.ID : 0) + "/printemptyprotocols"}>Пустые бланки протоколов</a>
			</Menu.Item>
		</Menu>
	);
	const bunchScheme = (prefix) => {
		if (!data) return "";
		var arr = data.map(i => i.number + "");
		return prefix + arr.join(',') + "," + arr.join(',') + ",..."
	};
	const gonextalldancer = (tourID, genderID) => {
		GET(auth, "/api/tour/" + tourID + "/gonextalldancer/gender/" + genderID,
			(xdata) => {
				dispatch(REDUX_DancerTour.actions.updateArrayByKey(tourID, xdata));
				if (genderID === 1) {
					tourresultm();
				}
				if (genderID === 2) {
					tourresultw();
				}
			},
			(err, type) => {
				if (err) {
					if (type === "fail") {
						message.error("" + err.message);
						if (err.exception) {
							console.error(err.exception)
						}
					}
					if (type === "error") {
						console.log(err);
						message.error("" + err);
					}
				}
			});
	};
	const gonextcountdancer = (done, values, unlock, close) => {
		GET(auth, "/api/tour/" + values.tourID + "/gonextcountdancer/gender/" + values.genderID + "/count/" + values.count,
			(xdata) => {
				dispatch(REDUX_DancerTour.actions.updateArrayByKey(values.tourID, xdata));
				if (values.genderID === 1) {
					tourresultm();
				}
				if (values.genderID === 2) {
					tourresultw();
				}
				done();
			},
			(err, type) => {
				if (err) {
					if (type === "fail") {
						message.error("" + err.message);
						if (err.exception) {
							console.error(err.exception)
						}
					}
					if (type === "error") {
						console.log(err);
						message.error("" + err);
					}
					unlock();
				}
			});
	};
	return (
		<>
			<Card size="small" className={classes.block} style={{ width: "100%", marginBottom: "4px" }}>
				<Row justify="start">
					<Col flex="auto">
						<Select value={(levelEvent) ? levelEvent.ID : undefined} style={{ width: "100%" }} onChange={levelEventChange}>
							{levelEvents}
						</Select>
					</Col>
					<Col flex="200px" style={{ paddingLeft: "10px" }}>
						<Select value={(tour) ? tour.ID : undefined} style={{ width: 200 }} onChange={tourChange}>
							{tours}
						</Select>
					</Col>
					{/* <Col style={{ paddingLeft: "10px" }}>
						<Action {...props} object={tour} action={newbunch} title={"Формировать заходы"} form={NewBunch} document={"query-create/bunch"} />
					</Col> */}
				</Row>
				<Row justify="start" style={{ paddingTop: "10px" }}>
					{/* <Col flex="350px">
						<Select value={(levelEvent) ? levelEvent.ID : undefined} style={{ width: 350 }} onChange={levelEventChange}>
							{levelEvents}
						</Select>
					</Col>
					<Col flex="200px" style={{ paddingLeft: "10px" }}>
						<Select value={(tour) ? tour.ID : undefined} style={{ width: 200 }} onChange={tourChange}>
							{tours}
						</Select>
					</Col> */}
					<Col>
						<Action {...props} object={tour} action={newbunch} title={"Формировать заходы"} form={NewBunch} document={"query-create/bunch"} />
					</Col>
				</Row>
			</Card>
			<Card size="small" className={classes.block} style={{ width: "100%" }}>
				<Tabs defaultActiveKey="1">
					<TabPane tab="Заходы" key="1">
						<Table loading={loading} pagination={false} columns={columns} dataSource={data} locale={{ emptyText: <Empty description={"Нет заходов"}></Empty> }} size="small" rowKey={(r) => r.ID} />
					</TabPane>
					<TabPane tab="Танцоры на заходах" key="2">
						<Filtered title={"Танцоры тура"} noPagination={true} /*quickFilter={true}*/ filters={dancerFilters} data={dancers} content={items => (
							<Card size="small" className={classes.block} style={{ width: "100%" }}>
								<Table loading={loading} pagination={false} columns={dancerColumns} dataSource={items} locale={{ emptyText: <Empty description={"Нет танцоров"}></Empty> }} size="small" rowKey={(r) => r.ID} />
							</Card>
						)}
							addition={
								(
									<Row justify="start">
										<Col>
											<Tooltip title={bunchScheme("Схема: ")}>
												<Dropdown overlay={<Menu onClick={() => { }} size="small">
													<Menu.Item key="1" onClick={() => bunchSchemeCreate(1, 1)}>
														Распределить мужчин последовательно
													</Menu.Item>
													<Menu.Item key="2" onClick={() => bunchSchemeCreate(1, 2)}>
														Распределить женщин последовательно
													</Menu.Item>
													{(tour && tour.prevTourID) && <Menu.Item key="3" onClick={() => bunchSchemeCreate(2, 1)}>
														Распределить мужчин по результатам предыдущего тура
													</Menu.Item>}
													{(tour && tour.prevTourID) && <Menu.Item key="4" onClick={() => bunchSchemeCreate(2, 2)}>
														Распределить женщин по результатам предыдущего тура
													</Menu.Item>}
													<Menu.Divider></Menu.Divider>
													<Menu.Item style={{ color: "red" }} key="5" onClick={bunchSchemeDelete}>Удалить распределение по заходам</Menu.Item>
												</Menu>}>
													<Button type="text" size="small">
														<OrderedListOutlined />
														<DownOutlined />
													</Button>
												</Dropdown>
											</Tooltip>
										</Col>
										{/* <Col>
											<Tooltip title={"Удалить распределение по заходам"}>
												<Button loading={loading} size="small" onClick={bunchSchemeDelete} style={{ border: "none" }}>
													<CloseCircleOutlined />
												</Button>
											</Tooltip>
										</Col> */}
									</Row>
								)
							} />
					</TabPane>
					<TabPane tab="Протоколы тура" key="3">
						<Row gutter={[5, 5]}>
							<Col span={24}>
								<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
									<div>
										{!protocols.length && <Button loading={loadingProtocol} onClick={protocolgenerate} style={{ marginBottom: "10px" }}>Формировать протоколы</Button>}
										{!!protocols.length && <Button loading={deleteProtocols} onClick={deleteprotocols} style={{ marginBottom: "10px" }}>Удалить протоколы</Button>}
									</div>
									<div>
										<Dropdown.Button style={{ marginBottom: "10px" }} overlay={printMenu}>
											<a target="_blank" rel="noopener noreferrer" href={"/tour/" + ((tour) ? tour.ID : 0) + "/printprotocols"}><PrinterOutlined /></a>
										</Dropdown.Button>
									</div>
								</div>
							</Col>
						</Row>
						<Table pagination={false} columns={protocolColumns} dataSource={protocols} locale={{ emptyText: <Empty description={"Нет протоколов"}></Empty> }} size="small" rowKey={(r) => r.ID} />
					</TabPane>
					<TabPane tab="Итоги Мужчины" key="4">
						<Row gutter={[5, 5]}>
							<Col span={24}>
								<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
									<div>
										<Button onClick={tourresultm} style={{ marginBottom: "10px" }}>Обновить</Button>
										{(tour && tour.tourTypeID !== 3) &&

											<DropdownAction button={() => (
												<Button style={{ marginLeft: "10px" }}>
													<StarOutlined /> <DownOutlined />
												</Button>)}>
												<Menu.Item key="1" onClick={() => gonextalldancer(((tour) ? tour.ID : 0), 1)}>
													Пропустить всех в следующий тур
    										</Menu.Item>
												<Action {...props}
													modal={{ width: "300px" }}
													action={gonextcountdancer}
													mode={"MenuItem"}
													auth={auth}
													object={{ tourID: ((tour) ? tour.ID : 0), genderID: 1 }}
													title={"Сколько в следующий тур?"}
													form={GoNextCountDancer}
												/>
											</DropdownAction>}
									</div>

									<div>
										<Dropdown.Button style={{ marginBottom: "10px" }} overlay={<Menu>
											<Menu.Item key="1" icon={<PrinterOutlined />}>
												<a target="_blank" rel="noopener noreferrer" href={"/tour/" + ((tour) ? tour.ID : 0) + "/1/printresult"}>Результаты</a>
											</Menu.Item>
											<Menu.Item key="2" icon={<PrinterOutlined />}>
												<a target="_blank" rel="noopener noreferrer" href={"/tour/" + ((tour) ? tour.ID : 0) + "/1/printresultv2"}>Результаты в разрезе судей</a>
											</Menu.Item>
											<Menu.Item key="3" icon={<PrinterOutlined />}>
												<a target="_blank" rel="noopener noreferrer" href={"/api/tour/" + ((tour) ? tour.ID : 0) + "/1/printautoupdateresult"}>Результаты с автообновлением</a>
											</Menu.Item>
										</Menu>}>
											<a target="_blank" rel="noopener noreferrer" href={"/tour/" + ((tour) ? tour.ID : 0) + "/1/printresult"}><PrinterOutlined /></a>
										</Dropdown.Button>
									</div>
								</div>
							</Col>
						</Row>
						<Table pagination={false} scroll={{ x: true }} columns={buildColumnsDancerResult(dancerResultM, (tour) ? tour.tourTypeID : undefined)} dataSource={dancerResultM} locale={{ emptyText: <Empty description={"Нет итогов"}></Empty> }} size="small" rowKey={(r) => r.ID + "M"} />
					</TabPane>
					<TabPane tab="Итоги Женщины" key="5">
						<Row gutter={[5, 5]}>
							<Col span={24}>
								<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
									<div>
										<Button onClick={tourresultw} style={{ marginBottom: "10px" }}>Обновить</Button>
										{(tour && tour.tourTypeID !== 3) && <DropdownAction button={() => (
											<Button style={{ marginLeft: "10px" }}>
												<StarOutlined /> <DownOutlined />
											</Button>)}>
											<Menu.Item key="1" onClick={() => gonextalldancer(((tour) ? tour.ID : 0), 2)}>
												Пропустить всех в следующий тур
    										</Menu.Item>
											<Action {...props}
												modal={{ width: "300px" }}
												action={gonextcountdancer}
												mode={"MenuItem"}
												auth={auth}
												object={{ tourID: ((tour) ? tour.ID : 0), genderID: 2 }}
												title={"Сколько в следующий тур?"}
												form={GoNextCountDancer}
											/>
										</DropdownAction>}
									</div>
									<div>
										<Dropdown.Button style={{ marginBottom: "10px" }} overlay={<Menu>
											<Menu.Item key="1" icon={<PrinterOutlined />}>
												<a target="_blank" rel="noopener noreferrer" href={"/tour/" + ((tour) ? tour.ID : 0) + "/2/printresult"}>Результаты</a>
											</Menu.Item>
											<Menu.Item key="2" icon={<PrinterOutlined />}>
												<a target="_blank" rel="noopener noreferrer" href={"/tour/" + ((tour) ? tour.ID : 0) + "/2/printresultv2"}>Результаты в разрезе судей</a>
											</Menu.Item>
											<Menu.Item key="3" icon={<PrinterOutlined />}>
												<a target="_blank" rel="noopener noreferrer" href={"/api/tour/" + ((tour) ? tour.ID : 0) + "/2/printautoupdateresult"}>Результаты с автообновлением</a>
											</Menu.Item>
										</Menu>}>
											<a target="_blank" rel="noopener noreferrer" href={"/tour/" + ((tour) ? tour.ID : 0) + "/2/printresult"}><PrinterOutlined /></a>
										</Dropdown.Button>
									</div>
								</div>
							</Col>
						</Row>
						<Table pagination={false} scroll={{ x: true }} columns={buildColumnsDancerResult(dancerResultW, (tour) ? tour.tourTypeID : undefined)} dataSource={dancerResultW} locale={{ emptyText: <Empty description={"Нет итогов"}></Empty> }} size="small" rowKey={(r) => r.ID + "W"} />
					</TabPane>
				</Tabs>

			</Card>
		</>
	);
}
