import React, { useEffect } from 'react';
import { Form, InputNumber } from 'antd';

import 'moment/locale/ru';

export default function ChangeDancerNumber(props) {
    const { object } = props;
    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });
    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{
                // name: object.name || "",
                number: object.number
            }}
        >
            <Form.Item
                name={"number"}
                label={"Номер"}
                rules={[{ required: true, message: 'Укажите номер!' }]}
            >
                <InputNumber min={1} style={{width: "100%"}}/>
            </Form.Item>
        </Form>
    );
}
