const initialState = {
    loading: false,
    stat: {}
  }
  
  
  export function mobileReducer(state = initialState, action) {
    switch (action.type) {
      case 'USER':
        return { ...state, user: action.payload }
      case 'loading':
        return { ...state, loading: action.payload }
        case 'stat':
        return { ...state, stat: action.payload }
      default:
        return state
    }
  }
  