import fuuid from 'react-uuid'

const initialState = {

}
  
export function metaReducer(state = initialState, action) {
    switch (action.type) {
      case 'META':
        var arr = Object.values(action.payload).map((item)=>{
          if (!item.uuid) {
            return { ...item, uuid: fuuid() } 
          }
          return item;
        });
        var o = {}
        for (let i = 0; i < arr.length; i++) {
          const element = arr[i];
          o[element.name.toLowerCase()] = element;
        }
        return o
      case 'META_OBJECT_UPDATE':
        var uuid = action.payload.uuid;
        if(!uuid){
          uuid = fuuid()
        }
        var name = uuid.toLowerCase();
        if (!state[name]) {
          var oo = { [name]:action.payload }
          return { ...oo, ...state }
        } else {
          return { ...state, [name]:action.payload }
        }
      default:
        return state
    }
}