//https://medium.com/@faizanv/authentication-for-your-react-and-express-application-w-json-web-tokens-923515826e0#3432
import React, { Component } from 'react';
import AuthService from './AuthService';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
 

export default function withAuth(AuthComponent) {
    const auth = new AuthService();
    const msg = () => {
        // message.error(msg);
    }
    return class AuthWrapped extends Component {
        constructor(props) {
            super(props);
            this.state = {
                user: null,
                // errorShow: false,
                // errorMessage: "",
                // errorColor: ""
            };
            this.ShowMessage = this.ShowMessage.bind(this);
            auth.setHandle({ShowMessage: this.ShowMessage})
        }
        ShowMessage(message){
            msg(message);
            // this.setState((state, props) => ({
            //     ...state,
            //     errorShow: true,
            //     errorMessage: message,
            //     errorColor: color || state.errorColor
            // }));
        }
        // static getDerivedStateFromProps(props, state) {
        componentWillMount() {
            if (!auth.loggedIn()) {
                history.replace('/login')
                history.go(0)
            }
            else {
                try {
                    const profile = auth.getProfile()
                    this.setState((state) => ({
                        ...state,
                        user: profile
                    }))
                }
                catch(err){
                    auth.logout(() => {
                        history.replace('/login')
                        history.go(0)
                    })
                }
            }
            // return null
        }

        render() {
            if (this.state.user) {
                return (
                    <>
                    <AuthComponent auth={auth} user={this.state.user} />
                    {/* <IonToast
                        color={this.state.errorColor}
                        isOpen={this.state.errorShow}
                        onDidDismiss={() => this.setState(() => ({ errorShow: false }))}
                        message= {this.state.errorMessage}
                        duration={4000}
                    >
                    </IonToast> */}
                    </>
                )
            }
            else {
                return null 
            }
        }
    };
}
