import React, { useEffect } from 'react';
import { Form, Input, InputNumber, Checkbox } from 'antd';

import 'moment/locale/ru';

export default function UpdateCriterionParams(props) {
    const { object } = props;

    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });
    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{
                // name: object.name || "",
                criterionID: object.criterionID,
                weight: object.weight,
                name: object.name,
                priorityInCriterion: object.priorityInCriterion,
                maxScoreValue: object.maxScoreValue,
                step: object.step,
                man: object.man,
                woman: object.woman,
            }}
        >
            <Form.Item
                preserve={"true"}
                hidden={true}
                name={"criterionID"}
                label={"Критерий"}
                rules={[{ required: true, message: 'Укажите критерий!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"name"}
                label={"Название"}
                rules={[{ required: true, message: 'Укажите название!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"priorityInCriterion"}
                label={"Приоритет"}
                rules={[{ required: true, message: 'Укажите приоритет!' }]}
            >
                <InputNumber min={1} />
            </Form.Item>
            <Form.Item
                name={"maxScoreValue"}
                label={"Максимальна оценка"}
                rules={[{ required: true, message: 'Укажите максимальную оценку!' }]}
            >
                <InputNumber min={1} />
            </Form.Item>
            <Form.Item
                name={"step"}
                label={"Шаг оценки"}
                rules={[{ required: true, message: 'Укажите шаг оценки!' }]}
            >
                <InputNumber min={0.1} step={0.1} />
            </Form.Item>
            <Form.Item
                name={"weight"}
                label={"Вес"}
                rules={[{ required: true, message: 'Укажите вес!' }]}
            >
                <InputNumber step={0.1} />
            </Form.Item>
            <Form.Item name="man" valuePropName="checked">
                <Checkbox>Применим для мужчин</Checkbox>
            </Form.Item>
            <Form.Item name="woman" valuePropName="checked">
                <Checkbox>Применим для женщин</Checkbox>
            </Form.Item>

        </Form>
    );
}
