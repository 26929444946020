
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Upload, message, Button, Card } from 'antd';

import {
    UploadOutlined
} from '@ant-design/icons';
import { createUseStyles } from 'react-jss'

import 'moment/locale/ru';

const useStyles = createUseStyles({

})

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  

var saveByteArray = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (data, name, type) {
        var blob = b64toBlob(data, type),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());

const conf = (auth) => {
    return {
    name: 'file',
    action: auth.domain+'/api/rating/upload',
    headers: {
        authorization: 'Bearer ' + auth.getToken(),
    },
    onDownload(file){
        console.log(file);
    },
    onChange(info) {
        if (info.file.status === 'done') {
            console.log(info.file, info.fileList);
            saveByteArray([info.file.response], info.file.name, info.file.type);
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }
}};

export default function RatingUpload(props) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const { auth } = props;

    return (
        <>
            <Card size="small" className={classes.block} style={{ width: "100%" }}>
                <Upload {...conf(auth)}>
                    <Button icon={<UploadOutlined />}>Загрузить</Button>
                </Upload>
            </Card>
        </>
    );
}
