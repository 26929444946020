import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Transfer, Switch, Table, Empty, Button, Typography, Card, message } from 'antd';
import { createUseStyles } from 'react-jss'

import Filtered from '../../components/Filtered'
import DropdownAction from '../../components/DropdownAction'
import Action from '../../components/Action'
import FilteredTransfer from '../../components/FilteredTransfer'

import 'moment/locale/ru';

import REDUX_DancerEvent from '../../objects/DancerEvent/reducer'
import CRUDE_DancerEvent from '../../objects/DancerEvent/crude'

import REDUX_Dancer from '../../objects/Dancer/reducer'
import CRUDE_Dancer from '../../objects/Dancer/crude'

import ChangeDancerNumber from '../../forms/ChangeDancerNumber'
import { POST } from '../../components/Tool'



const { Title } = Typography;

const useStyles = createUseStyles({
    transfer: {
        '&.ant-transfer': {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        '&.ant-transfer .ant-transfer-list ': {
            flex: "auto",
            height: "500px",
        }
    }
})

function Btn(props) {
    const dispatch = useDispatch();
    const { auth, object } = props;
    // const odelete = () => {
    //     CRUDE_DancerEvent.destroy(props.auth, (data) => {
    //         dispatch(REDUX_DancerEvent.actions.setArrayByKey(object.levelEvent.ID, dancerevent.filter(item => item.ID !== props.object.ID)));
    //     }, props.object.ID);
    // };
    const changenumber = (done, values, unlock, close) => {
        if (!object) { done(); return; }
        // /api/dancerevent/{id}/number/{number}
        auth.fetch("/api/dancerevent/" + object.ID + "/number/" + values.number).then(res => {
            if (res && res.data) {
                dispatch(REDUX_DancerEvent.actions.updateArrayByKey(object.levelEvent.ID, [res.data]));
                // done();
                close();
                unlock();
            } else if (res && !res.status) {
                message.error(res.message);
                unlock();
            }
        });
        // unlock();
    };
    return (
        <DropdownAction>
            <Action {...props} object={object} action={changenumber} mode={"MenuItem"} title={"Изменить номер"} form={ChangeDancerNumber} />
            {/* <Menu.Item onClick={odelete}>Удалить</Menu.Item> */}
        </DropdownAction>
    )
};

export default function DancerEvent(props) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const { auth, object } = props;

    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);

    const dancerevent = useSelector(REDUX_DancerEvent.selector.arrayByKey(object.ID));
    useEffect(() => {
        CRUDE_DancerEvent.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_DancerEvent.actions.setArrayByKey(object.ID, data));
            }
        }, 'model', 's-dancer.lastname=ASC&s-dancer.firstname=ASC&w-levelEvent.id=' + object.ID);
    }, [object, auth, dispatch]);

    const columns = [
        {
            title: 'Танцор',
            render: (text, record) => {
                return (record.dancer.lastname + " " + record.dancer.firstname);
            }
        },
        {
            title: 'Пол',
            render: (text, record) => {
                return (record.dancer.gender.name);
            }
        },
        {
            title: 'Номер',
            render: (text, record) => {
                return (record.number);
            }
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            fixed: 'right',
            width: 45,
            render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} />),
        },
    ];

    const update = (res) => {
        dispatch(REDUX_DancerEvent.actions.updateArrayByKey(object.ID, res.data));
    };
    const alldancers = () => {
        auth.fetch("/api/event/" + object.ID + "/dancereventgenerate").then(res => {
            if (res && res.data && dispatch) {
                update(res);
            }
        });
    };

    const triggerEditing = edit => {
        setEditing(edit);
    };
    const filters = [
        {
            name: "Танцор",
            value: item => item.dancer.lastname + " " + item.dancer.firstname,
            field: "dancer",
            type: "string",
            sort: true,
            filter: true,
            sorted: "ASC"
        },
        {
            name: "Пол",
            value: item => item.dancer.gender.name,
            field: "gender",
            type: "string",
            sort: true,
            filter: true
        },
        {
            name: "Номер",
            value: item => item.number,
            field: "number",
            type: "string",
            sort: true,
            filter: true
        },
    ];
    const filtersDancer = [
        {
            name: "ФИО",
            value: item => item.lastname + " " + item.firstname,
            field: "dancer",
            type: "string",
            sort: true,
            filter: true,
            sorted: "ASC"
        },
        {
            name: "Пол",
            value: item => item.gender.name,
            field: "gender",
            type: "string",
            sort: true,
            filter: true
        },
    ];
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
                <Title level={4} style={{ marginBottom: "0px" }}>{object.event.name + " (" + object.level.name + ")"}</Title>
                <div>
                    <Switch
                        unCheckedChildren="Редактировать"
                        checkedChildren="Редактировать"
                        checked={editing}
                        onChange={triggerEditing}
                    />
                </div>
            </div>
            {editing &&
                <FilteredTransfer
                    auth={auth}
                    filters={filtersDancer}
                    REDUX_Source={REDUX_Dancer}
                    REDUX_Target={REDUX_DancerEvent}
                    CRUDE_Source={CRUDE_Dancer}
                    CRUDE_Target={CRUDE_DancerEvent}
                    FilterSource={'s-lastname=ASC&s-firstname=ASC&w-level.id=' + object.levelID}
                    FilterTarget={'s-dancer.lastname=ASC&s-dancer.firstname=ASC&w-levelEvent.id=' + object.ID}
                    dropUrl={"/api/dropdancerlistevent"}
                    addUrl={"/api/adddancerlistevent/level-event/" + object.ID}
                    getSourceIDInTarget={(item) => item.dancer.ID}
                    getSourceID={(item) => item.ID}
                    Key={object.ID}
                    titlesTransfer={["Все танцоры (" + object.level.name + ")", 'Танцоры группы']}
                    localeTransfer={{ itemUnit: "танцор", itemsUnit: "танцор(ов)", notFoundContent: "Нет танцоров", searchPlaceholder: "Поиск" }}
                    renderTransfer={item => item.lastname + " " + item.firstname}
                />
            }

            {!editing && <Filtered title={object.event.name + " (" + object.level.name + ")"} filters={filters} data={dancerevent} content={items => (
                <Card size="small" className={classes.block} style={{ width: "100%" }}>
                    <Table loading={loading} pagination={false} columns={columns} dataSource={items} locale={{ emptyText: <Empty description={"Нет танцоров"}></Empty> }} size="small" rowKey={(r) => r.ID} />
                </Card>
            )}
                addition={
                    (
                        <>
                            <div justify="start">
                                <Button onClick={alldancers} style={{ marginLeft: "5px" }}>Добавить всех танцоров</Button>
                            </div>
                        </>
                    )
                } />
            }
        </>
    );
}
