import { createSlice } from '@reduxjs/toolkit';
import Utils from '../../reducers/utils'

const NAME = 'CriterionParamsPriority';

export const slice = createSlice({
name: NAME,
initialState: Utils.initialState,
reducers: {
updateArray: Utils.updateArray,
setArray: Utils.setArray,
setObject: Utils.setObject,
updateArrayBK: Utils.updateArrayBK,

updateArrayPartialBK: Utils.updateArrayPartialBK,
updateArrayPartialGlobalBK: Utils.updateArrayPartialGlobalBK,

setArrayBK: Utils.setArrayBK,
setObjectBK: Utils.setObjectBK,
setArrayMap: (state, action) => {
Utils.setArray(state, { type: "", payload: action.payload.data });
action.payload.data.forEach(value => {
var key = action.payload.fun(value);
Utils.setObjectBK(state, { type: "", payload: { key: key, value: undefined } });
});
action.payload.data.forEach(value => {
var key = action.payload.fun(value);
Utils.updateArrayBK(state, { type: "", payload: { key: key, value: [value] } });
var o = action.payload.object(value);
if(o)
Utils.setObjectBK(state, { type: "", payload: { key: key, value: o } });
});
},
updateArrayMap: (state, action) => {
Utils.updateArray(state, { type: "", payload: action.payload.data });
action.payload.data.forEach(value => {
var key = action.payload.fun(value);
Utils.updateArrayBK(state, { type: "", payload: { key: key, value: [value] } });
var o = action.payload.object(value);
Utils.setObjectBK(state, { type: "", payload: { key: key, value: o } });
});
}
},
});

export const { updateArrayMap, setArrayMap, updateArrayBK, updateArrayPartialBK, updateArrayPartialGlobalBK, setArrayBK, setObjectBK, setArray, setObject, updateArray } = slice.actions;

export const setArrayMapped = (data, fun, object) => setArrayMap({ data: data, fun: fun, object: object });
export const updateArrayMapped = (data, fun, object) => updateArrayMap({ data: data, fun: fun, object: object });
export const setArrayByKey = (key, value) => setArrayBK({ key: key, value: value });
export const setObjectByKey = (key, value) => setObjectBK({ key: key, value: value });
export const updateArrayByKey = (key, value) => updateArrayBK({ key: key, value: value });

export const updateArrayPartialByKey = (key, value, fun) => updateArrayPartialBK({ key: key, value: value, fun: fun });
export const updateArrayPartialGlobalByKey = (value, fun) => updateArrayPartialGlobalBK({ fun: fun, value: value });

export const array = state => state[NAME].array;
export const object = state => state[NAME].object;
export const arrayByKey = key => state => (state[NAME].bykey[key])?state[NAME].bykey[key].array:[];
export const objectByKey = key => state => (state[NAME].bykey[key])?state[NAME].bykey[key].object:undefined;
export const objectFilter = (fun) => state => Object.values(state[NAME].bykey).filter(item=>!!item.object && fun && fun(item.object));

export default {
reducer: slice.reducer,
actions: {
...slice.actions,
setArrayByKey: setArrayByKey,
setObjectByKey: setObjectByKey,
updateArrayByKey: updateArrayByKey,
updateArrayPartialByKey: updateArrayPartialByKey,
updateArrayPartialGlobalByKey: updateArrayPartialGlobalByKey,
setArrayMapped: setArrayMapped,
updateArrayMapped: updateArrayMapped
},
selector: {
array: array,
object: object,
arrayByKey: arrayByKey,
objectByKey: objectByKey,
updateArrayByKey: updateArrayByKey,
objectFilter: objectFilter
}
};