import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Table, Empty, Button, Typography, Switch, Transfer, Card, message } from 'antd';
import { createUseStyles } from 'react-jss'

import Filtered from '../../components/Filtered'
import FilteredTransfer from '../../components/FilteredTransfer'

import 'moment/locale/ru';

import REDUX_JudgeEvent from '../../objects/JudgeEvent/reducer'
import CRUDE_JudgeEvent from '../../objects/JudgeEvent/crude'

import REDUX_Judge from '../../objects/Judge/reducer'
import CRUDE_Judge from '../../objects/Judge/crude'


import { POST } from '../../components/Tool'


const { Title } = Typography;

const useStyles = createUseStyles({
    transfer: {
        '&.ant-transfer': {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        '&.ant-transfer .ant-transfer-list ': {
            flex: "auto",
            height: "500px",
        }
    }
});
export default function JudgeEvent(props) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const { auth, object } = props;

    const [loading, setLoading] = useState(false);

    const Judgeevent = useSelector(REDUX_JudgeEvent.selector.arrayByKey(object.ID));

    const [editing, setEditing] = useState(false);

    const judge = useSelector(REDUX_Judge.selector.array);
    useEffect(() => {
        CRUDE_JudgeEvent.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_JudgeEvent.actions.setArrayByKey(object.ID, data));
            }
        }, 'model', 'w-levelEvent.id=' + object.ID);
    }, [auth, dispatch]);

    const columns = [
        {
            title: 'Судья',
            render: (text, record) => {
                return (record.judge.lastname + " " + record.judge.firstname);
            }
        },
        {
            title: 'Пол',
            render: (text, record) => {
                return (record.judge.gender.name);
            }
        },
        // {
        //     title: '',
        //     dataIndex: '',
        //     key: 'x',
        //     fixed: 'right',
        //     width: 45,
        //     render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} />),
        // },
    ];
    const { getState } = useStore()
    const update = (res) => {
        dispatch(REDUX_JudgeEvent.actions.updateArrayByKey(object.ID, res.data));
    };
    const allJudges = () => {
        auth.fetch("/api/event/" + object.ID + "/judgeeventgenerate").then(res => {
            if (res && res.data && dispatch) {
                update(res);
            }
        });
    };

    const triggerEditing = edit => {
        setEditing(edit);
    };
    const filters = [
        {
            name: "Судья",
            value: item => item.judge.lastname + " " + item.judge.firstname,
            field: "judge",
            type: "string",
            sort: true,
            filter: true,
            sorted: "ASC"
        },
        {
            name: "Пол",
            value: item => item.judge.gender.name,
            field: "gender",
            type: "string",
            sort: true,
            filter: true
        }
    ];
    const filtersJudge = [
        {
            name: "Судья",
            value: item => item.lastname + " " + item.firstname,
            field: "judge",
            type: "string",
            sort: true,
            filter: true,
            sorted: "ASC"
        },
        {
            name: "Пол",
            value: item => item.gender.name,
            field: "gender",
            type: "string",
            sort: true,
            filter: true
        }
    ];
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
                <Title level={4} style={{ marginBottom: "0px" }}>{object.event.name + " (" + object.level.name + ")"}</Title>
                <div>
                    <Switch
                        unCheckedChildren="Редактировать"
                        checkedChildren="Редактировать"
                        checked={editing}
                        onChange={triggerEditing}
                    />
                </div>
            </div>
            {editing &&
                <FilteredTransfer
                    auth={auth}
                    filters={filtersJudge}
                    REDUX_Source={REDUX_Judge}
                    REDUX_Target={REDUX_JudgeEvent}
                    CRUDE_Source={CRUDE_Judge}
                    CRUDE_Target={CRUDE_JudgeEvent}
                    FilterSource={'s-lastname=ASC&s-firstname=ASC'}
                    FilterTarget={'w-levelEvent.id=' + object.ID}
                    dropUrl={"/api/dropjudgelistevent"}
                    addUrl={"/api/addjudgelistevent/level-event/" + object.ID}
                    getSourceIDInTarget={(item) => item.judge.ID}
                    getSourceID={(item) => item.ID}
                    Key={object.ID}
                    titlesTransfer={['Все судьи', 'Судьи группы']}
                    localeTransfer={{ itemUnit: "судья", itemsUnit: "судей", notFoundContent: "Нет судей", searchPlaceholder: "Поиск" }}
                    renderTransfer={item => item.lastname + " " + item.firstname}
                />
            }

            {!editing && <Filtered title={object.event.name + " (" + object.level.name + ")"} filters={filters} data={Judgeevent} content={items => (
                <Card size="small" className={classes.block} style={{ width: "100%" }}>
                    <Table loading={loading} pagination={false} columns={columns} dataSource={items} locale={{ emptyText: <Empty description={"Нет судей"}></Empty> }} size="small" rowKey={(r) => r.ID} />
                </Card>
            )}
                addition={
                    (
                        <>
                            <div justify="start">
                                <Button onClick={allJudges} style={{ marginLeft: "5px" }}>Добавить всех судей</Button>
                            </div>
                        </>
                    )
                } />}

        </>
    );
}
