import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Menu, Card, Divider, Row, Col, Modal, Empty } from 'antd';

import {
	ExclamationCircleOutlined} from '@ant-design/icons';
import { createUseStyles } from 'react-jss'

import 'moment/locale/ru';

import Filtered from '../../components/Filtered'
import DropdownAction from '../../components/DropdownAction'
import Action from '../../components/Action'

import REDUX_Event from '../../objects/Event/reducer';
import CRUDE_Event from '../../objects/Event/crude';

import NewEvent from '../../forms/NewEvent'
import UpdateEvent from '../../forms/UpdateEvent'

const { confirm } = Modal;

function Btn(props) {
	const dispatch = useDispatch();
	const events = useSelector(REDUX_Event.selector.array);
	const odelete = () => {
		CRUDE_Event.destroy(props.auth, () => {
			dispatch(REDUX_Event.actions.setArray(events.filter(item => item.ID !== props.object.ID)));
		}, props.object.ID);
	};
	const update = (done, values, unlock) => {
		CRUDE_Event.update(props.auth, (data) => {
			dispatch(REDUX_Event.actions.updateArray([data]));
			done();
			// CRUDE_Event.read(auth, (data) => {
			// 	if (data.length > 0) {
			// 		dispatch(REDUX_Tour.actions.setArrayByKey((object.levelEvent) ? object.levelEvent.ID : 0, data));
			// 	}
			// 	done();
			// }, 'model', "w-levelEvent.id=" + ((object.levelEvent) ? object.levelEvent.ID : 0));
		}, { ...props.object, ...values });
		if (unlock)
			unlock();
	};
	return (
		<DropdownAction>
			<Action {...props} readonly={false} modal={{ title: "Изменить мероприятие" }} object={props.object} mode={"MenuItem"} action={update} title={"Изменить"} form={UpdateEvent} document={"query-update/event"} />
			<Menu.Divider></Menu.Divider>
			<Menu.Item><a target="_blank" rel="noopener noreferrer" href={"/event/" + ((props.object) ? props.object.ID : 0) + "/printresultcommands"}>Результаты команд</a></Menu.Item>
			<Menu.Divider></Menu.Divider>
			<Menu.Item style={{color: "red"}} onClick={()=> confirm({
				title: 'Вы действительно хотите удалить мероприятие?',
				icon: <ExclamationCircleOutlined />,
				content: 'Удаление мероприятия приведет к потере всех связанных с ним данных',
				okText: 'Удалить',
				cancelText: 'Отмена',
				onOk() {
					odelete();
				},
				onCancel() {
				},
			})}>Удалить</Menu.Item>
		</DropdownAction>
	)
};

const useStyles = createUseStyles({
	divider: {
		'&.ant-divider-horizontal.ant-divider-with-text-left': {
			fontFamily: "-apple-system, BlinkMacSystemFont, Roboto, 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif",
			fontSize: "13px",
			fontWeight: "400",
			color: "rgb(0, 0, 0)",
		},
		'&.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text': {
			padding: "0px",
			marginRight: "10px",
		}
	}
})

export default function CommonEvents(props) {
	const classes = useStyles()
	const dispatch = useDispatch();
	const { auth } = props;
	const [loading, setLoading] = useState(false);
	const filters = [
		{
			name: "Название",
			value: item => item.name,
			field: "name",
			type: "string",
			sort: true,
			filter: true,
			sorted: "DESC"
		}
	];
	useEffect(() => {
		setLoading(true);
		CRUDE_Event.read(auth, (data) => {
			setLoading(false);
			if (data.length > 0) {
				dispatch(REDUX_Event.actions.setArray(data));
			}
		}, 'model');
	}, [auth, dispatch]);

	const data = useSelector(REDUX_Event.selector.array);
	const update = (res) => {
		dispatch(REDUX_Event.actions.updateArray([res.data]));
	};
	const columns = [
		{
			title: 'Название',
			render: (text, record) => {
				return (record.name);
			}
		},
		{
			title: 'Танцоров в оценке команды',
			render: (text, record) => {
				return ((record.countDancerInResult && record.countDancerInResult > 0)?record.countDancerInResult:"");
			}
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			fixed: 'right',
			width: 45,
			render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} />),
		},
	];
	return (
		<>
			<Filtered title={"Мероприятия"} filters={filters} data={data} content={items => (
				<Card size="small" className={classes.block} style={{ width: "100%" }}>
					<Divider className={classes.divider} type="horizontal" orientation="left">
						Мероприятия
                    </Divider>
					<Table loading={loading} pagination={false} columns={columns} dataSource={items} locale={{ emptyText: <Empty description={"Нет мероприятий"}></Empty> }} size="small" rowKey={(r) => r.ID} />
				</Card>
			)}
				addition={
					(
						<Row justify="start">
						{/* <Col flex="auto">
				</Col> */}
						<Col flex="50px">
						<Action {...props} callback={update} title={"Новое мероприятие"} form={NewEvent} document={"query-create/event"} />
						</Col>
					</Row>
					
					)
				} />
		</>
	);
}
