import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Menu, Card, Divider, Row, Col, Empty, Form } from 'antd';

import { createUseStyles } from 'react-jss'

import 'moment/locale/ru';

import Filtered from '../../components/Filtered'
import DropdownAction from '../../components/DropdownAction'
import Action from '../../components/Action'

import REDUX_Translate from '../../objects/Translate/reducer';
import CRUDE_Translate from '../../objects/Translate/crude';

import NewTranslate from '../../forms/NewTranslate'
import UpdateTranslate from '../../forms/UpdateTranslate'
import TextArea from 'antd/lib/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';

function Btn(props) {
	const dispatch = useDispatch();
	const Translates = useSelector(REDUX_Translate.selector.array);
	// const oTranslates = useSelector(REDUX_Translate.selector.object);
	const odelete = () => {
		CRUDE_Translate.destroy(props.auth, () => {
			dispatch(REDUX_Translate.actions.setArray(Translates.filter(item => item.ID !== props.object.ID)));
		}, props.object.ID);
	};
	const update = (done, values, unlock) => {
		CRUDE_Translate.update(props.auth, (data) => {
			dispatch(REDUX_Translate.actions.updateArray([data]));
			done();
		}, { ...props.object, ...values, key: values.ru.toLowerCase().replaceAll(' ', '') });
		if (unlock)
			unlock();
	};

	return (
		<DropdownAction>
			<Action {...props} readonly={false} modal={{ title: "Изменить" }} object={props.object} mode={"MenuItem"} action={update} title={"Изменить"} form={UpdateTranslate} document={"query-update/Translate"} />
			<Menu.Divider></Menu.Divider>

			<Menu.Item onClick={odelete}>Удалить</Menu.Item>
		</DropdownAction>
	)
};

const useStyles = createUseStyles({
	divider: {
		'&.ant-divider-horizontal.ant-divider-with-text-left': {
			fontFamily: "-apple-system, BlinkMacSystemFont, Roboto, 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif",
			fontSize: "13px",
			fontWeight: "400",
			color: "rgb(0, 0, 0)",
		},
		'&.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text': {
			padding: "0px",
			marginRight: "10px",
		}
	}
})

export default function SettingsTranslate(props) {
	const classes = useStyles()
	const dispatch = useDispatch();
	const { auth } = props;
	const [loading, setLoading] = useState(false);
	const filters = [
		{
			name: "Русский",
			value: item => item.ru,
			field: "ru",
			type: "string",
			sort: true,
			filter: true,
			sorted: "ASC"
		},
		{
			name: "Английский",
			value: item => item.en,
			field: "en",
			type: "string",
			sort: true,
			filter: true
		},
		{
			name: "Испанский",
			value: item => item.es,
			field: "es",
			type: "string",
			sort: true,
			filter: true
		}
	];
	useEffect(() => {
		setLoading(true);
		CRUDE_Translate.read(auth, (data) => {
			setLoading(false);
			if (data.length > 0) {
				dispatch(REDUX_Translate.actions.setArray(data));
			}
		}, 'model');
	}, [auth, dispatch]);

	const data = useSelector(REDUX_Translate.selector.array);
	const update = (res) => {
		dispatch(REDUX_Translate.actions.updateArray([res.data]));
	};
	const columns = [
		{
			title: 'Русский',
			render: (text, record) => {
				return (record.ru);
			}
		},
		{
			title: 'Английский',
			render: (text, record) => {
				return (record.en);
			}
		},
		{
			title: 'Испанский',
			render: (text, record) => {
				return (record.es);
			}
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			fixed: 'right',
			width: 45,
			render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} />),
		},
	];
	const load = (done, values, unlock) => {
		let p = JSON.parse(values.dict);
		// console.log(p);
		for (const key in p) {
			if (Object.hasOwnProperty.call(p, key)) {
				const ru = p[key];
				CRUDE_Translate.create(props.auth, (data) => {
					dispatch(REDUX_Translate.actions.updateArray([data]));
				}, {key, ru});
			}
		}
		done();
		// if (unlock)
		// 	unlock();
	};
	return (
		<>
			<Filtered title={"Судьи"} filters={filters} data={data} content={items => (
				<Card size="small" className={classes.block} style={{ width: "100%" }}>
					<Divider className={classes.divider} type="horizontal" orientation="left">
						Переводы
					</Divider>
					<Table loading={loading} pagination={false} columns={columns} dataSource={items} locale={{ emptyText: <Empty description={"Нет переводов"}></Empty> }} size="small" rowKey={(r) => r.ID} />
				</Card>
			)}
				addition={
					(
						<Row justify="start">
							<Col flex="50px">
								<Action {...props}
									//callback={update} 
									action={(done, values, unlock, close) => {
										CRUDE_Translate.create(props.auth, (res) => {
											dispatch(REDUX_Translate.actions.updateArray([res]));
											done();
										}, { ...values, key: values.ru.toLowerCase().replaceAll(' ', '') });
										if (unlock)
											unlock();
									}}
									title={"Новый перевод"}
									form={NewTranslate}
									document={"query-create/translate"} />
							</Col>
							{/* <Col flex="50px" style={{ paddingLeft: "10px" }}>
								<Action {...props}
									action={load}
									title={<UploadOutlined />}
									modal={{ title: "Загрузить" }}
									object={window.dict}
									form={(props) => {
										const { object } = props;
										return (<>
											<Form
												form={props.form}
												onFinish={props.submit}
												labelAlign={"left"}
												layout={"vertical"}
												initialValues={{
													dict: JSON.stringify(object, null, "\t")
												}}
											>
												<Form.Item
													name={"dict"}
													label={"JSON-объект вида { key1: ru1, key2: ru2 }"}
													rules={[{ required: true, message: 'Укажите значение!' }]}
												>
													<TextArea rows={10} />
												</Form.Item>
											</Form>
										</>)
									}}
									document={"query-create/translate"} />
							</Col> */}
						</Row>
					)
				} />
		</>
	);
}
