import React, { useState, useEffect, useRef } from 'react';
import { Layout, Card, Row, Col, Button, Tooltip, Pagination, Checkbox, Empty, Divider, Typography, DatePicker, Tag, Select, Input } from 'antd';
import ReactToPrint from 'react-to-print';
import {
    FilterOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined,
    PrinterOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { createUseStyles } from 'react-jss'

import moment from 'moment-timezone';
import 'moment/locale/ru';
import SortedSet from 'collections/sorted-set'

import locale from 'antd/es/date-picker/locale/ru_RU';
import Print from '../Print'

const { CheckableTag } = Tag;
const { Sider } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const useStyles = createUseStyles({
    button: {
        marginLeft: "10px"
    },
    printLink: {
        '&:hover': {
            color: "#40a9ff !important"
        }
    }
})

const chunk = (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    for (index = 0; index < arrayLength; index += chunk_size) {
        var myChunk = myArray.slice(index, index + chunk_size);
        tempArray.push(myChunk);
    }
    return tempArray;
}

export default function Filtered(props) {
    const classes = useStyles()
    const [current, setCurrent] = useState(1);
    const [count] = useState(15);
    const [sorting, setSorting] = useState({ field: "", order: "ASC", f: () => { return "" } });
    const [filter, setFilter] = useState({});
    const [filtered, setFiltered] = useState(false);
    const [quickFilterValue, setQuickFilterValue] = useState("");

    const { data, filters, content, item, addition, noPagination, noPrint, onPrint, printHref, title, quickFilter } = props;

    var collection = {};
    const setCollection = (obj) => { collection = obj };

    useEffect(() => {
        var fld = filters.find(item => !!item.sorted);
        if (fld && !sorting.field) {
            setSorting({ field: fld.field, order: fld.sorted, f: (fld.value) ? fld.value : () => "" })
        }
    }, [filters]);

    const sortData = (sorting, data) => {
        if (sorting.field) {
            data.sort(function (a, b) {
                if (sorting.order === "ASC" && sorting.f(a) > sorting.f(b)) {
                    return 1;
                }
                if (sorting.order === "ASC" && sorting.f(a) < sorting.f(b)) {
                    return -1;
                }
                if (sorting.order === "DESC" && sorting.f(a) < sorting.f(b)) {
                    return 1;
                }
                if (sorting.order === "DESC" && sorting.f(a) > sorting.f(b)) {
                    return -1;
                }
                // a должно быть равным b
                return 0;
            });
        }
        return data;
    };
    const sortByFilters = (filters, data) => {
        var fld = filters.find(item => !!item.sorted);
        if (fld) {
            if (!sorting.field) {
                //setSorting({ field: fld.field, order: fld.sorted, f: (fld.value) ? fld.value : (fld) => "" });
                return sortData({ field: fld.field, order: fld.sorted, f: (fld.value) ? fld.value : () => "" }, data);
            } else {
                return sortData(sorting, data);
            }
        }
        return data;
    };
    const quickFilterValueChange = (e) => {
        setQuickFilterValue(e);
    };
    const filterByQuickFilter = (value, filters, data) => {
        if (quickFilter === false) return data;
        return data.filter(item => {
            for (let i = 0; i < filters.length; i++) {
                const filter = filters[i];
                // if(filter.type === "string"){
                var v = filter.value(item) + "";
                if (v.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    return true;
                }
                // }
            }
            return false;
        });
    };
    const filterByFilters = (filters, data) => {
        return filterByQuickFilter(quickFilterValue, filters, data).filter(item => {
            for (let i = 0; i < filters.length; i++) {
                const f = filters[i];
                var v = f.value(item);

                if (f.filter && f.type === "string") {
                    var set = collection[f.field];
                    if (!set) {
                        set = new SortedSet();
                    }
                    if (v !== null) {
                        set.push(v);
                    }
                    // else {
                    //     set.push("");
                    // }
                    setCollection({ ...collection, [f.field]: set })
                }

                var fv = filter[f.field];
                switch (f.type) {
                    case "bool":
                        if (filtered && fv && fv !== v) return false;
                        break;
                    case "date":
                        if (filtered && fv && (moment(v) >= moment(fv[1]) || moment(v) <= moment(fv[0]))) return false;
                        break;
                    case "string":
                        if (filtered && fv && fv !== v) return false;
                        break;
                    default:
                        break;
                }
            }
            return true;
        });
    };

    const c = chunk(sortByFilters(filters, filterByFilters(filters, data)), (noPagination === true) ? data.length : count);
    const page = (c.length >= current) ? c[current - 1] : (c.length !== 0) ? c[0] : [];

    const onChange = page => {
        setCurrent(page);
    };
    const onFilterChangeBool = (value, item) => {
        setFilter({ ...filter, [item.field]: value.target.checked })
    };
    function onFilterChangeDate(dateStrings, item) {
        setFilter({ ...filter, [item.field]: dateStrings })
    }
    const onFilterChangeString = (value, item) => {
        setFilter({ ...filter, [item.field]: value })
    };
    const onSortingChangeString = (value, item) => {
        setSorting({ field: value, order: sorting.order, f: (item && item.value) ? item.value : () => "" })
    };
    const sortingOrder = () => {
        if (sorting.order === "ASC") {
            setSorting({ field: sorting.field, order: "DESC", f: sorting.f });
        } else {
            setSorting({ field: sorting.field, order: "ASC", f: sorting.f });
        }
    }
    const filters_ui = filters => {
        const fl = filters.filter(i => i.filter);
        const f = fl.map((item, idx) => {
            const componentValue = item => {
                if (!item.filter) return <></>;
                switch (item.type) {
                    case "bool":
                        return (
                            <Checkbox
                                //indeterminate={filter[item.field] === undefined}
                                checked={filter[item.field]}
                                onChange={(value) => onFilterChangeBool(value, item)}
                            >{item.name}</Checkbox>
                        );
                    case "date":
                        var a = undefined;
                        if (filter[item.field] && filter[item.field][0] && filter[item.field][1]) {
                            a = [];
                            a.push(moment(filter[item.field][0]))
                            a.push(moment(filter[item.field][1]))
                        }
                        return (
                            <RangePicker locale={locale}
                                value={a}
                                onChange={(dates, dateStrings) => onFilterChangeDate(dateStrings, item)}
                            />
                        );
                    case "string":
                        var options = collection[item.field];
                        const oui = [];
                        if (options) {
                            var i = 1;
                            options.forEach((value) => {
                                oui.push(<Option key={i++} value={value}>{value}</Option>);
                            });
                        }
                        return (
                            <Select
                                allowClear={true}
                                showSearch={true}
                                value={filter[item.field]}
                                style={{ width: "100%" }}
                                onChange={(value) => onFilterChangeString(value, item)}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option.children + "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >{oui}</Select>
                        );
                    default:
                        return (<></>);
                }
            }
            return (
                <div key={idx} style={{ marginBottom: "10px" }}>
                    {item.filter && item.type !== "bool" && <Text>{item.name}</Text>}
                    {componentValue(item)}
                </div>
            );
        });
        return (
            <>
                {(fl.length > 0) && <>
                    <Divider type="horizontal"
                        orientation="left"
                        style={{ margin: "12px 0", fontSize: "13px", fontWeight: "600", padding: "0px 15px 0px 5px" }} >
                        Фильтры
                    </Divider>
                    <div style={{ margin: "10px" }}>
                        <div>
                            {f}
                        </div>
                    </div>
                </>}
            </>
        );
    };
    const sorting_ui = filters => {
        const so = filters.filter(f => f.sort);
        const s = so.map((item, idx) => {
            return (
                <Option key={idx} value={item.field}>{item.name}</Option>
            );
        });
        return (
            <>
                {(so.length > 0) && <div>
                    <Divider type="horizontal"
                        orientation="left"
                        style={{ margin: "12px 0", fontSize: "13px", fontWeight: "600", padding: "0px 15px 0px 5px" }} >
                        Сортировка
                    </Divider>
                    <div style={{ margin: "10px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Select
                                allowClear={true}
                                value={sorting.field}
                                onChange={(value) => onSortingChangeString(value, filters.find(i => i.field === value))}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: "100%", marginRight: "5px" }}
                            >{s}</Select>
                            <div>
                                {sorting.order === "ASC" && <Tooltip title="Восходящий">
                                    <Button icon={<SortAscendingOutlined />} onClick={sortingOrder} />
                                </Tooltip>}
                                {sorting.order === "DESC" && <Tooltip title="Нисходящий">
                                    <Button icon={<SortDescendingOutlined />} onClick={sortingOrder} />
                                </Tooltip>}
                            </div>
                        </div>
                    </div>
                </div>}
            </>
        );
    };
    const ui = (page) => {
        if (!page || page.length <= 0) return <div></div>;
        if (item) {
            const list = page.map((item) => {
                return item(page);
            });
            return list;
        }
        if (content) {
            return content(page, filtered);
        }
    };
    const componentRef = useRef();

    return (
        <>
            <div style={{ display: "none" }}><Print ref={componentRef} data={sortByFilters(filters, filterByFilters(filters, data))} filters={filters} title={title} /></div>
            <Row gutter={[5, 5]}>
                <Col span={24}>
                    <Card size="small">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ flex: "auto", paddingRight: "15px" }}>
                                {addition}
                            </div>
                            <div justify="end">
                                {(!noPrint && onPrint) &&
                                    <Tooltip title="Печать">
                                        <a onClick={onPrint} className={classes.printLink} style={{ color: "rgba(0, 0, 0, 0.65)", fontSize: "12px", padding: "0px 7px", marginRight: "5px" }}>
                                            <PrinterOutlined />
                                        </a>
                                    </Tooltip>
                                }
                                {(!noPrint && !onPrint) &&
                                    <Tooltip title="Печать">
                                        {!printHref && <ReactToPrint
                                            trigger={() => {
                                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                // to the root node of the returned component as it will be overwritten.
                                                return <a href="#" className={classes.printLink} style={{ color: "rgba(0, 0, 0, 0.65)", fontSize: "12px", padding: "0px 7px", marginRight: "5px" }}><PrinterOutlined /></a>;
                                            }}
                                            content={() => componentRef.current}
                                        />}
                                        {printHref && <a target="_blank" rel="noopener noreferrer" href={printHref} className={classes.printLink} style={{ color: "rgba(0, 0, 0, 0.65)", fontSize: "12px", padding: "0px 7px", marginRight: "5px" }}>
                                            <PrinterOutlined />
                                        </a>}
                                    </Tooltip>
                                }
                                <Tooltip title="Фильтр и сортировка">
                                    <CheckableTag
                                        style={{ cursor: "pointer" }}
                                        checked={filtered}
                                        onChange={checked => setFiltered(checked)}
                                    >
                                        <FilterOutlined />
                                    </CheckableTag>
                                </Tooltip>

                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Layout style={{ backgroundColor: "transparent" }}>
                <div style={{ width: "100%", marginBottom: "5px" }}>
                    {page.length > 0 ? ui(page) : <Col span={24}><Card size="small"><Empty description={"Нет данных"} image={Empty.PRESENTED_IMAGE_SIMPLE} /></Card></Col>}
                </div>
                {filtered && <Sider width={240} theme={"light"} style={{ margin: "0 1px 5px 5px" }}>
                    {quickFilter && <div style={{ margin: "10px" }}>
                        <Input.Search placeholder="Поиск" allowClear onSearch={quickFilterValueChange} />
                    </div>}
                    {/* <Divider type="horizontal"
                        orientation="left"
                        style={{ margin: "12px 0", fontSize: "13px", fontWeight: "600", padding: "0px 15px 0px 5px" }} >
                        Сортировка
                    </Divider>
                    <div style={{ margin: "10px" }}>
                        {sorting_ui(filters)}
                    </div> */}
                    {sorting_ui(filters)}
                    {/* <Divider type="horizontal"
                        orientation="left"
                        style={{ margin: "12px 0", fontSize: "13px", fontWeight: "600", padding: "0px 15px 0px 5px" }} >
                        Фильтры
                    </Divider>
                    <div style={{ margin: "10px" }}>
                        {filters_ui(filters)}
                    </div> */}
                    {filters_ui(filters)}
                </Sider>}
            </Layout>
            {!noPagination && <Row gutter={[5, 5]}>
                <Col span={24}>
                    <Card size="small" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Pagination size="small" current={current} onChange={onChange} pageSize={count} total={sortByFilters(filters, filterByFilters(filters, data)).length} showSizeChanger={false} />
                    </Card>
                </Col>
            </Row>}
        </>
    );
}

