
export default {
  initialState: {
      array: [],
      bykey: {},
      object: undefined
    },
  updateArray: (state, action) => {
      for (let j = 0; j < action.payload.length; j++) {
        const item = action.payload[j];
        var idx = state.array.findIndex(i => i.ID === item.ID);
        if (idx === -1) {
          //append
          state.array.push(item);
        } else {
          //update
          state.array[idx] = item;
        }
      }
    },
    setArray: (state, action) => {
      state.array = action.payload;
    },
    setObject: (state, action) => {
      state.object = action.payload;
    },
    updateArrayBK: (state, action) => {
      var s = state.bykey[action.payload.key] || { object: undefined, array: [] };
      // s.array = action.payload.value;
      for (let j = 0; j < action.payload.value.length; j++) {
        const item = action.payload.value[j];
        var idx = s.array.findIndex(i => i.ID === item.ID);
        if (idx === -1) {
          //append
          s.array.push(item);
        } else {
          //update
          s.array[idx] = item;
        }
      }
      state.bykey[action.payload.key] = s;
    },
    updateArrayPartialBK: (state, action) => {
      var s = state.bykey[action.payload.key];
      const funall = (obj, value) =>{ return obj };
      var fun = action.payload.fun || funall;
      state.bykey[action.payload.key].array = s.array.map(it=>fun(it, action.payload.value));
    },
    updateArrayPartialGlobalBK: (state, action) => {
      const funall = (obj, value) => { return obj };
      var fun = action.payload.fun || funall;
      var keys = Object.keys(state.bykey);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        var s = state.bykey[key];
        var r = s.array.map(it=>fun(it, action.payload.value));
        // console.log(r)
        state.bykey[key].array = r;
      }
    },
    setArrayBK: (state, action) => {
      var s = state.bykey[action.payload.key] || { object: undefined, array: [] };
      s.array = action.payload.value;
      state.bykey[action.payload.key] = s;
    },
    setObjectBK: (state, action) => {
      var s = state.bykey[action.payload.key] || { object: undefined, array: [] };
      s.object = action.payload.value;
      state.bykey[action.payload.key] = s;
    },
}