import React, { Component } from 'react';
import './index.css';
import AuthService from '../../components/AuthService';
import { createBrowserHistory } from 'history';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const history = createBrowserHistory();

//https://www.youtube.com/watch?v=hjp-JHVsgxQ
//https://github.com/pantharshit00/jwt-react-auth
//https://hptechblogs.com/using-json-web-token-react/
//https://github.com/pantharshit00/jwtAuth


class Login extends Component {
  // constructor(props: Props) {
  //   super(props);
  //   this.state = {
  //     email: "",
  //     password: "",
  //     errorShow: false,
  //     errorMessage: "",
  //     errorColor: ""
  //   };
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }

  static defaultProps = {
    Auth: new AuthService()
  };

  handleSubmit = (values) => {
    // e.preventDefault();
    // this.props.form.validateFields((err, values) => {
    //   if (!err) {
    //     e.preventDefault();
          this.props.Auth.login(values.username, values.password)
          .then(res => {
            if (!res.status) {
              message.error(res.message);
            } else {
              history.replace('/');
              history.go(0);
            }
          })
          .catch(err => {
            message.error(err);
          })
    //     return false;
    //   }
    // });
  };

  render() {
    // const { getFieldDecorator } = this.props.form;
    return (
      <Form name="normal_login" onFinish={this.handleSubmit} className="login-form">
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Пожалуйста, введите имя пользователя!' }]}
        >
            <Input
              prefix={<UserOutlined/>}
              placeholder="Имя пользователя"
            />
        </Form.Item>
        <Form.Item
          name="password"
          rules={ [{ required: true, message: 'Пожалуйста, введите пароль!' }]}
        >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="Пароль"
            />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Войти
          </Button>
          {/* или <a href="/signup">зарегистрироваться!</a> */}
        </Form.Item>
      </Form>
    );
  }
}

// const Login = Form.create({ name: 'normal_login' })(NormalLoginForm);
export default Login;