import React from 'react';

import 'moment/locale/ru';

export default class Print extends React.PureComponent {
    render() {
        const { title, data, filters } = this.props;
        return (
            <div style={{ padding: "25px"}}>
                <div style={{ margin: "25px 15px", textAlign: "center" }}>
                    <h1>
                        {title}
                    </h1>
                </div>
                <table border="1" style={{ margin: "25px 15px", tableLayout: "fixed", border: "2px solid black", borderCollapse: "collapse", verticalAlign: "middle"}}>
                    <thead>
                        <tr style={{ backgroundColor: "#ffffc1", overflowWrap: "anywhere" }}>
                            {filters.map(item => <td key={item.field} style={{ padding: "5px 10px", fontWeight: "600", minWidth: "55px", textAlign: "center", fontSize: "14px" }}>{item.name}</td>)}
                        </tr>
                    </thead>
                    <tbody style={{ fontSize: "16px" }}>
                        {data.map((obj, idx) => <tr key={idx} style={{height: "35px"}}>
                            {filters.map(item => <td key={item.field} style={{ padding: "5px 10px", fontWeight: "600", textAlign: "left" }}>
                                {item.value(obj)}
                            </td>)}
                        </tr>)}
                    </tbody>
                </table>
            </div>
        );
    }
}