import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, Row, Col, Typography } from 'antd';

import 'moment/locale/ru';

import REDUX_Protocol from '../../objects/Protocol/reducer'
import CRUDE_Protocol from '../../objects/Protocol/crude'

import CRUDE_ProtocolDancerParams from '../../objects/ProtocolDancerParams/crude'

import {
    MinusOutlined,
    PlusOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';

const { Option } = Select;
const protocolCriterionParamsCount = (protocol, protocolCriterionParams) => {
    var count = 0;
    protocolCriterionParams.forEach(protocolCriterian => {
        if (protocol.judgeBunch.judgeTour.judgeEvent.judge.genderID === 1) {
            if (protocolCriterian.ParamsMan && protocolCriterian.ParamsMan.length) {
                    count++;
            }
        } else {
            if (protocolCriterian.ParamsWoman && protocolCriterian.ParamsWoman.length) {
                    count++;
            }
        }
    });
    return count;
};
const stat = (protocol, criterionCount) => {
    criterionCount = criterionCount || 0;
    var count = 0;
    var s = {};
    if (!protocol) return;
    if (protocol.judgeBunch.judgeTour.judgeEvent.judge.genderID === 2) {
        count = protocol.judgeBunch.bunch.tour.scoresInCriterionW;
    } else {
        count = protocol.judgeBunch.bunch.tour.scoresInCriterionM;
    }

    protocol.protocolDancer.forEach(dancer => {
        dancer.protocolDancerParams.forEach(item => {
            if (item.value > 0 && protocol.judgeBunch.bunch.tour.protocolValueTypeID === 1) {
                if (!s[item.criterionParams.criterionID]) s[item.criterionParams.criterionID] = { value: 0, good: true, check: true, criterion: item.criterionParams.criterion };
                s[item.criterionParams.criterionID].value += (item.value !== null) ? item.value : 0;
                s[item.criterionParams.criterionID].good = (s[item.criterionParams.criterionID].value !== count) ? false : true;
            } else if (protocol.judgeBunch.bunch.tour.protocolValueTypeID !== 1) {
                if (!s[item.criterionParams.criterionID]) s[item.criterionParams.criterionID] = { value: 0, good: true, check: true, criterion: item.criterionParams.criterion };
                s[item.criterionParams.criterionID].value += (item.value !== null) ? item.value : 0;
                s[item.criterionParams.criterionID].good = (!s[item.criterionParams.criterionID].good || !item.value) ? false : true;

                if (!s[item.criterionParams.criterionID][item.criterionParamsID]) {
                    s[item.criterionParams.criterionID][item.criterionParamsID] = { count: {} };
                }
                var countValue = s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value];
                if (!countValue) {
                    s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value] = 1;
                } else {
                    s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value]++;
                }

                if (s[item.criterionParams.criterionID][item.criterionParamsID].count[item.value] > protocol.judgeBunch.bunch.tour.maxCountEqualScores) {
                    s[item.criterionParams.criterionID].check = false;
                }
            }
        });
    });
    var good = true;
    var check = true;
    if (Object.keys(s).length !== criterionCount) {
        good = false;
    } else {
        Object.keys(s).forEach(i => {
            if (!s[i].good) {
                good = false;
            }
        });
    }

    Object.keys(s).forEach(i => {
        if (!s[i].check) {
            check = false;
        }
    });

    return {
        ...s,
        good: good,
        check: check
    }
};
const mergeProtocolParamValue = (protocol, protocolDancerParam, value) => {
    var ptcl = {
        ...protocol,
        protocolDancer: protocol.protocolDancer.map(p => {
            return {
                ...p,
                protocolDancerParams: p.protocolDancerParams.map(k => {
                    if (k.ID === protocolDancerParam.ID) {
                        return { ...k, value: value }
                    }
                    return k;
                })
            };
        }
        )
    };
    return ptcl;
};
const setCurrentProtocolParamValue = (dispatch, protocol, protocolDancerParam, value) => {
    var ptcl = mergeProtocolParamValue(protocol, protocolDancerParam, value);
    dispatch(REDUX_Protocol.actions.updateArrayByKey(protocol.judgeBunch.bunch.tour.ID, [ptcl]));
    return ptcl;
};

function Value({ auth, value, protocol, protocolDancerParam, criterion, protocolCriterionParams }) {
    const dispatch = useDispatch();

    const change = (value, protocolDancerParam) => {
        var object = { ...protocolDancerParam, value: value };
        CRUDE_ProtocolDancerParams.update(auth, () => {
            var ptcl = setCurrentProtocolParamValue(dispatch, protocol, protocolDancerParam, value);
            dispatch({ type: 'stat', payload: stat(ptcl, protocolCriterionParamsCount(ptcl, protocolCriterionParams))});
        }, object, dispatch);
    };

    if (protocol.judgeBunch.bunch.tour.protocolValueTypeID === 1) {
        return (
            <>
                <Select style={{ width: "100%" }} bordered={false} allowClear
                    value={(value) ? (value) : undefined}
                    onChange={(value) => change(value, protocolDancerParam, criterion)}>
                    <Option value={1}><PlusOutlined /></Option>
                    <Option value={-1}><MinusOutlined /></Option>
                </Select>
            </>
        );
    } else {
        var arr = [];
        var step = criterion.step || 1;
        step = Math.round(step * 100) / 100
        var val = step;
        while (val <= criterion.maxScoreValue) {
            arr.push(val);
            val += step;
            val = Math.round(val * 100) / 100
        }

        return (
            <>
                <Select style={{ width: "100%" }} bordered={false} allowClear
                    value={(value) ? (value) : undefined}
                    onChange={(value) => change(value, protocolDancerParam, criterion)}>
                    {arr.map((item, idx) => <Option key={idx} value={item}>{item}</Option>)}
                    {/* Array.apply(null, {length: criterion.maxScoreValue}).map(Number.call, Number) */}
                </Select>
            </>
        );
    }

}
export default function Protocol(props) {
    const dispatch = useDispatch();
    const { auth, object } = props;

    const statistic = useSelector(state => state.mobile.stat);
    const [protocolCriterionParams, setProtocolCriterionParams] = useState([]);
    useEffect(() => {
        auth.fetch("/api/tour/" + object.judgeBunch.bunch.tour.ID + "/protocolcriterionparams").then(res => {
            if (res && res.data) {
                setProtocolCriterionParams(res.data);
                dispatch({ type: 'stat', payload: stat(object, protocolCriterionParamsCount(object, res.data)) });
            }
        });
    }, [object, auth, dispatch]);
    useEffect(() => {
        dispatch({ type: 'stat', payload: stat(object, protocolCriterionParamsCount(object, protocolCriterionParams)) });
    }, [object, protocolCriterionParams, dispatch]);

    // const protocol = useSelector(REDUX_Protocol.selector.arrayByKey(object.ID));
    // useEffect(() => {
    //     CRUDE_Protocol.read(auth, (data) => {
    //         if (data.length > 0) {
    //             dispatch(REDUX_Protocol.actions.setArrayByKey(object.ID, data));
    //         }
    //     }, 'model', 'w-bunch.id='+object.ID);
    // }, []);
    const headerLine1 = () => {
        var jsx = [];
        
        jsx = protocolCriterionParams.map(current => {
            if (object.judgeBunch.judgeTour.judgeEvent.judge.genderID === 1) {
                
                if (current.ParamsMan) {
                    return (
                        <td key={current.Criterion.ID}
                            colSpan={current.ParamsMan.length}
                            style={{ fontWeight: "600" }}>{current.Criterion.name}</td>
                    );
                }
            } else {
                if (current.ParamsWoman) {
                    return (
                        <td key={current.Criterion.ID}
                            colSpan={current.ParamsWoman.length}
                            style={{ fontWeight: "600" }}>{current.Criterion.name}</td>
                    );
                }
            }
        });
        return jsx;
    };
    const headerLine2 = () => {
        var jsx = [];
        protocolCriterionParams.forEach(protocolCriterian => {
            if (object.judgeBunch.judgeTour.judgeEvent.judge.genderID === 1) {
                if (protocolCriterian.ParamsMan) {
                    protocolCriterian.ParamsMan.forEach(param => {
                        jsx.push(<td key={param.ID} style={{ width: "100px" }}>{param.name}</td>);
                    });
                }
            } else {
                if (protocolCriterian.ParamsWoman) {
                    protocolCriterian.ParamsWoman.forEach(param => {
                        jsx.push(<td key={param.ID} style={{ width: "100px" }}>{param.name}</td>);
                    });
                }
            }
        });
        return (jsx);
    };
    const bodyOuter = () => {
        return object.protocolDancer.map(protocolDancer => {
            return (
                <tr key={protocolDancer.ID}>
                    <td style={{ backgroundColor: "aqua" }}>{protocolDancer.dancerBunch.dancerTour.dancerEvent.number}</td>
                    {object.judgeBunch.bunch.tour.protocolValueTypeID === 1 &&
                        <td>
                            <div style={{ textAlign: "left", paddingRight: "10px", minWidth: "35px", minHeight: "40px", backgroundColor: "transparent", fontSize: "12px", color: "rgb(0,0,0,0.65)" }}>
                                <div>
                                    <div style={{ color: "green", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{}}>
                                            <span style={{ marginLeft: "-1px" }}>+</span>
                                            <span style={{ paddingLeft: "1px" }}>:</span>
                                        </div>
                                        <div>{sumPlus(protocolDancer.protocolDancerParams.map(item => (item.value) ? item.value : 0))}</div>
                                    </div>
                                    <div style={{ color: "red", display: "flex", justifyContent: "space-between" }}>
                                        <div style={{}}>
                                            <span>-</span>
                                            <span style={{ paddingLeft: "3px" }}>:</span>
                                        </div>
                                        <div>{sumMinus(protocolDancer.protocolDancerParams.map(item => (item.value) ? item.value : 0))}</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    }
                    {object.judgeBunch.bunch.tour.protocolValueTypeID !== 1 &&
                        <td>
                            <div style={{ textAlign: "left", paddingRight: "10px", minWidth: "35px", minHeight: "40px", backgroundColor: "transparent", fontSize: "12px", color: "rgb(0,0,0,0.65)" }}>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>{protocolDancer.protocolDancerParams.map(item => (item.value) ? item.value : 0).reduce(reducer)}</div>
                                    </div>
                                </div>
                            </div>

                        </td>
                    }
                    {bodyInner(protocolDancer)}
                </tr>
            );
        });
    };
    const findParam = (protocolDancer, p) => {
        var res = undefined;
        protocolDancer.protocolDancerParams.forEach(param => {
            if (param.criterionParamsID === p.ID) {
                res = param;
                return;
            }
        });
        return res;
    };
    const bodyInner = (protocolDancer) => {
        var jsx = [];
        protocolCriterionParams.forEach(protocolCriterian => {
            if (object.judgeBunch.judgeTour.judgeEvent.judge.genderID === 1) {
                if (protocolCriterian.ParamsMan) {
                    protocolCriterian.ParamsMan.forEach(param => {
                        var pp = findParam(protocolDancer, param);
                        jsx.push(<td key={param.ID}>
                            <Value auth={auth} value={((pp) ? pp.value : undefined)} protocol={object} protocolDancerParam={pp} criterion={param} protocolCriterionParams={protocolCriterionParams}></Value>
                        </td>);
                    });
                }
            } else {
                if (protocolCriterian.ParamsWoman) {
                    protocolCriterian.ParamsWoman.forEach(param => {
                        var pp = findParam(protocolDancer, param);
                        jsx.push(<td key={param.ID}>
                            <Value auth={auth} value={((pp) ? pp.value : undefined)} protocol={object} protocolDancerParam={pp} criterion={param} protocolCriterionParams={protocolCriterionParams}></Value>
                        </td>);
                    });
                }
            }
        });
        return (jsx);
    };

    const sumPlus = (arr) => {
        var acc = 0;
        arr.forEach(item => (item > 0) ? acc += 1 : acc);
        return acc;
    }
    const sumMinus = (arr) => {
        var acc = 0;
        arr.forEach(item => (item < 0) ? acc += 1 : acc);
        return acc;
    }
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    const badge = (statistic) => {
        if (!statistic.good) {
            return <CheckCircleOutlined style={{ color: "red" }} />
        } else {
            if (!statistic.check) {
                return <CheckCircleOutlined style={{ color: "orange" }} />
            } else {
                return <CheckCircleOutlined style={{ color: "green" }} />
            }
        }
    }

    return (
        <>
            {/* <div style={{textAlign: "right"}}> */}
            {/* <Title level={4}>{object.tour.levelEvent.event.name + " ("+object.tour.levelEvent.level.name+"), Тур " + object.tour.number + " (" + object.tour.tourType.name + ")"}</Title> */}
            {/* <Typography>Приложение № 1 к Положению</Typography> */}
            {/* </div> */}
            <Row gutter={[5, 5]}>
                <Col span={8}>

                    <Typography>
                        <span style={{ fontSize: "12px", marginRight: "10px" }}>
                            {badge(statistic)}
                        </span>
                        Судья: {object.judgeBunch.judgeTour.judgeEvent.judge.lastname + " " + object.judgeBunch.judgeTour.judgeEvent.judge.firstname}</Typography>
                </Col>
                <Col span={16}>
                    <Typography>Тур {object.judgeBunch.bunch.tour.number + " (" + object.judgeBunch.bunch.tour.tourType.name + "), " + object.judgeBunch.bunch.tour.levelEvent.event.name + " (" + object.judgeBunch.bunch.tour.levelEvent.level.name + ")"}</Typography>
                </Col>
            </Row>
            <Row gutter={[5, 5]} style={{ marginBottom: "15px" }}>
                <Col span={8}>
                    <Typography></Typography>
                </Col>
                <Col span={16}>
                    <Typography>Заход {object.judgeBunch.bunch.number}</Typography>
                </Col>
            </Row>
            <Row gutter={[5, 5]}>
                <Col span={24}>
                    <table border="1" style={{ width: "100%", textAlign: "center" }}>
                        <thead>
                            <tr>
                                <td rowSpan={2} style={{ width: "50px", backgroundColor: "aqua" }}>№ участника</td>
                                <td rowSpan={2} style={{ width: "50px" }}>Сумма</td>

                                {headerLine1()}
                            </tr>
                            <tr>
                                {headerLine2()}
                            </tr>
                        </thead>
                        <tbody>
                            {bodyOuter()}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>
    );
}
