import React, { useEffect } from 'react';
import { Form, Input } from 'antd';

import 'moment/locale/ru';

export default function NewCriterion(props) {
    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });
    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{
                // name: object.name || "",
            }}
        >
            <Form.Item
                name={"name"}
                label={"Название"}
                rules={[{ required: true, message: 'Укажите название!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"description"}
                label={"Описание"}
                rules={[{ required: false, message: 'Укажите описание!' }]}
            >
                <Input />
            </Form.Item>
        </Form>
    );
}
