import React, { useEffect } from 'react';
import { Form, Input } from 'antd';

import 'moment/locale/ru';

export default function NewCommand(props) {
    const { object } = props;

    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });
    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{
                // name: object.name || "",
                eventID: object.ID
            }}
        >
            <Form.Item
                preserve={"true"}
                hidden={true}
                name={"eventID"}
                label={"Мероприятие"}
                rules={[{ required: true, message: 'Укажите мероприятие!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"name"}
                label={"Название"}
                rules={[{ required: true, message: 'Укажите название!' }]}
            >
                <Input />
            </Form.Item>
        </Form>
    );
}
