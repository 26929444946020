import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Menu, Card, Divider, Row, Col, Modal, Select, message, Empty } from 'antd';

import {
	ExclamationCircleOutlined} from '@ant-design/icons';
import { createUseStyles } from 'react-jss'

import 'moment/locale/ru';

import Filtered from '../../components/Filtered'
import DropdownAction from '../../components/DropdownAction'
import Action from '../../components/Action'

import REDUX_Tour from '../../objects/Tour/reducer';
import CRUDE_Tour from '../../objects/Tour/crude';
import REDUX_LevelEvent from '../../objects/LevelEvent/reducer';
import CRUDE_LevelEvent from '../../objects/LevelEvent/crude';

import NewTour from '../../forms/NewTour'
import UpdateTour from '../../forms/UpdateTour'

import JudgeTour from '../../forms/JudgeTour'
import DancerTour from '../../forms/DancerTour'

const { confirm } = Modal;
const { Option } = Select;

function Btn(props) {
	const dispatch = useDispatch();
	const { auth, object } = props;
	const empty = (done) => {
		if (done) done();
	};
	const tours = useSelector(REDUX_Tour.selector.arrayByKey(object.levelEvent.ID));
	const odelete = () => {
		CRUDE_Tour.destroy(props.auth, () => {
			dispatch(REDUX_Tour.actions.setArrayByKey(object.levelEvent.ID, tours.filter(item => item.ID !== props.object.ID)));
		}, props.object.ID);
	};
	const odone = () => {
		auth.fetch("/api/tour/" + object.ID + "/done").then(res => {
			if (res && res.data) {
				dispatch(REDUX_Tour.actions.updateArrayByKey(object.levelEvent.ID, [res.data]));
			} else if (res && !res.status) {
				message.error(res.message);
			}
		});
		// CRUDE_Tour.update(props.auth, (data) => {
		// 	dispatch(REDUX_Tour.actions.updateArrayByKey(object.levelEvent.ID, [data]));
		// }, {...object, done: true});
	};
	const onotdone = () => {
		auth.fetch("/api/tour/" + object.ID + "/notdone").then(res => {
			if (res && res.data) {
				if(object.levelEvent){
					CRUDE_Tour.read(auth, (data) => {
						if (data.length > 0) {
							dispatch(REDUX_Tour.actions.setArrayByKey((object.levelEvent) ? object.levelEvent.ID : 0, data));
						}
					}, 'model', "w-levelEvent.id=" + ((object.levelEvent) ? object.levelEvent.ID : 0));
				}
			} else if (res && !res.status) {
				message.error(res.message);
			}
		});
	};
	const update = (done, values, unlock) => {
		CRUDE_Tour.update(props.auth, () => {
			if(object.levelEvent){
				CRUDE_Tour.read(auth, (data) => {
					if (data.length > 0) {
						dispatch(REDUX_Tour.actions.setArrayByKey((object.levelEvent) ? object.levelEvent.ID : 0, data));
					}
					done();
				}, 'model', "w-levelEvent.id=" + ((object.levelEvent) ? object.levelEvent.ID : 0));
			}
		}, { ...object, ...values });
		if (unlock)
			unlock();
	};
	return (
		<DropdownAction>
			<Action {...props} readonly={false} modal={{ title: "Изменить тип значения" }} object={props.object} mode={"MenuItem"} action={update} title={"Изменить тип значения"} form={UpdateTour} document={"query-update/tour"} />
			<Menu.Divider></Menu.Divider>
			<Action {...props} readonly={true} modal={{ title: "Танцоры тура", width: "65%" }} object={props.object} mode={"MenuItem"} action={empty} title={"Танцоры тура"} form={DancerTour} document={"dancertour"} />
			<Action {...props} readonly={true} modal={{ title: "Судьи тура", width: "65%" }} object={props.object} mode={"MenuItem"} action={empty} title={"Судьи тура"} form={JudgeTour} document={"judgetour"} />
			{(object && !object.done) ? <Menu.Item style={{ color: "orange" }} onClick={() => confirm({
				title: 'Вы действительно хотите завершить тур мероприятия?',
				icon: <ExclamationCircleOutlined />,
				content: 'После завершения тура нельзя будет изменить связанные с ним данные',
				okText: 'Отметить завершенным',
				cancelText: 'Отмена',
				onOk() {
					odone();
				},
				onCancel() {
				},
			})}>Отметить завершенным</Menu.Item> : <></>}
			{(object && object.done) ? <Menu.Item style={{ color: "blue" }} onClick={() => confirm({
				title: 'Вы действительно хотите убрать у тура статус "Завершен"?',
				icon: <ExclamationCircleOutlined />,
				content: '',
				okText: 'Убрать статус "Завершен"',
				cancelText: 'Отмена',
				onOk() {
					onotdone();
				},
				onCancel() {
				},
			})}>Убрать статус "Завершен"</Menu.Item> : <></>}
			<Menu.Divider></Menu.Divider>
			<Menu.Item style={{ color: "red" }} onClick={() => confirm({
				title: 'Вы действительно хотите удалить тур мероприятия?',
				icon: <ExclamationCircleOutlined />,
				content: 'Удаление тура приведет к потере всех связанных с ним данных',
				okText: 'Удалить',
				cancelText: 'Отмена',
				onOk() {
					odelete();
				},
				onCancel() {
				},
			})}>Удалить</Menu.Item>
		</DropdownAction>
	)
};

const useStyles = createUseStyles({
	divider: {
		'&.ant-divider-horizontal.ant-divider-with-text-left': {
			fontFamily: "-apple-system, BlinkMacSystemFont, Roboto, 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif",
			fontSize: "13px",
			fontWeight: "400",
			color: "rgb(0, 0, 0)",
		},
		'&.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text': {
			padding: "0px",
			marginRight: "10px",
		}
	}
})

export default function CommonTour(props) {
	const classes = useStyles()
	const dispatch = useDispatch();
	const { auth } = props;
	const [loading, setLoading] = useState(false);
	const filters = [
		{
			name: "Номер",
			value: item => item.number,
			field: "number",
			type: "string",
			sort: true,
			filter: true,
			sorted: "ASC"
		},
		// {
		// 	name: "Мероприятие",
		// 	value: item => item.levelEvent.event.name + " ("+ item.levelEvent.level.name +")",
		// 	field: "levelEvent",
		// 	type: "string",
		// 	sort: true,
		// 	filter: true
		// },
		{
			name: "Тип тура",
			value: item => item.tourType.name,
			field: "tourType",
			type: "string",
			sort: true,
			filter: true
		},
		{
			name: "Шаблон критериев",
			value: item => item.template.name,
			field: "template",
			type: "string",
			sort: true,
			filter: true
		},
		{
			name: "Тип значения",
			value: item => item.protocolValueType.name,
			field: "protocolValueType",
			type: "string",
			sort: true,
			filter: true
		},
		{
			name: "Статус",
			value: item => (item.done) ? "Завершен" : "Не завершен",
			field: "done",
			type: "string",
			sort: true,
			filter: true
		}
	];
	const columns = [
		{
			title: 'Номер',
			render: (text, record) => {
				return (record.number);
			}
		},
		// {
		// 	title: 'Мероприятие',
		// 	render: (text, record, index) => {
		// 		return (record.levelEvent.event.name + " ("+ record.levelEvent.level.name +")");
		// 	}
		// },
		{
			title: 'Тип тура',
			render: (text, record) => {
				return (record.tourType.name);
			}
		},
		{
			title: 'Шаблон критериев',
			render: (text, record) => {
				return (record.template.name);
			}
		},
		{
			title: 'Тип значения',
			render: (text, record) => {
				return (record.protocolValueType.name);
			}
		},
		{
			title: 'Статус',
			render: (text, record) => {
				return ((record.done) ? "Завершен" : "");
			}
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			fixed: 'right',
			width: 45,
			render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} />),
		}
	];

	useEffect(() => {
		setLoading(true);
		CRUDE_LevelEvent.read(auth, (data) => {
			setLoading(false);
			if (data.length > 0) {
				dispatch(REDUX_LevelEvent.actions.setArray(data));
			}
		}, 'model');
	}, [auth, dispatch]);

	const edata = useSelector(REDUX_LevelEvent.selector.array);

	const [levelEvent, setLevelEvent] = useState((edata.length <= 0) ? undefined : edata[0]);
	const levelEventChange = (value) => {
		setLevelEvent(edata.find(item => item.ID === value));
	};
	useEffect(() => {
		if (!levelEvent) {
			setLevelEvent((edata.length <= 0) ? undefined : edata[0]);
		}
	}, [edata]);
	const levelEvents = edata.map(item => {
		return (
			<Option value={item.ID} key={item.ID}>{item.event.name + " (" + item.level.name + ")"}</Option>
		);
	});

	useEffect(() => {
		if(levelEvent){
			setLoading(true);
			CRUDE_Tour.read(auth, (data) => {
				
				setLoading(false);
				if (data.length > 0) {
					dispatch(REDUX_Tour.actions.setArrayByKey((levelEvent) ? levelEvent.ID : 0, data));
				}
			}, 'model', "w-levelEvent.id=" + ((levelEvent) ? levelEvent.ID : 0));
		}
	}, [levelEvent,auth, dispatch]);

	const data = useSelector(REDUX_Tour.selector.arrayByKey((levelEvent) ? levelEvent.ID : 0));
	const update = (res) => {
		dispatch(REDUX_Tour.actions.updateArrayByKey(levelEvent.ID, [res.data]));
	};

	return (
		<>
			<Filtered title={"Туры"} filters={filters} data={data} content={items => (
				<Card size="small" className={classes.block} style={{ width: "100%" }}>
					<Divider className={classes.divider} type="horizontal" orientation="left">
						Туры
                    </Divider>
					<Table loading={loading} pagination={false} columns={columns} dataSource={items} locale={{ emptyText: <Empty description={"Нет туров"}></Empty> }} size="small" rowKey={(r) => r.ID} />
				</Card>
			)}
				addition={
					(
						<>
							<Row justify="start">
								<Col flex="auto">
									<Select value={(levelEvent) ? levelEvent.ID : undefined} style={{ width: "100%" }} onChange={levelEventChange}>
										{levelEvents}
									</Select>
								</Col>
								<Col flex="50px" style={{ paddingLeft: "10px" }}>
									<Action {...props} object={levelEvent} modal={{ width: "65%" }} callback={update} title={"Новый тур"} form={NewTour} document={"query-create/tour"} />
								</Col>
							</Row>
						</>
					)
				} />
		</>
	);
}
