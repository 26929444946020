import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Table, Empty, Row, Col, Button, Typography, Tag, message } from 'antd';

import {
    StarOutlined
} from '@ant-design/icons';

import DropdownAction from '../../components/DropdownAction'

import 'moment/locale/ru';

import REDUX_DancerTour from '../../objects/DancerTour/reducer'
import CRUDE_DancerTour from '../../objects/DancerTour/crude'

const { Title } = Typography;
const { CheckableTag } = Tag;


function Btn(props) {
    const dispatch = useDispatch();
    const { object } = props;
    const dancertour = useSelector(REDUX_DancerTour.selector.arrayByKey(object.tour.ID));
    const odelete = () => {
        CRUDE_DancerTour.destroy(props.auth, () => {
            dispatch(REDUX_DancerTour.actions.setArrayByKey(object.tour.ID, dancertour.filter(item => item.ID !== props.object.ID)));
        }, props.object.ID);
    };
    return (
        <DropdownAction>
            <Menu.Item onClick={odelete}>Удалить</Menu.Item>
        </DropdownAction>
    )
};
export default function DancerTour(props) {
    const dispatch = useDispatch();
    const { auth, object } = props;

    const [loading] = useState(false);

    const DancerTour = useSelector(REDUX_DancerTour.selector.arrayByKey(object.ID));
    useEffect(() => {
        CRUDE_DancerTour.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_DancerTour.actions.setArrayByKey(object.ID, data));
            }
        }, 'model', 'w-tour.id=' + object.ID);
    }, [object,auth, dispatch]);
    const star = (record, goNextTour) =>{
        const update = (done, checked) => {
            CRUDE_DancerTour.update(props.auth, (data) => {
                dispatch(REDUX_DancerTour.actions.updateArrayByKey(object.ID, [data]));
            }, { ...record, goNextTour: checked }, dispatch);
        };
        return ( 
        <CheckableTag style={{backgroundColor: "transparent"}}
            checked={goNextTour}
            onChange={checked => update(record, checked)}>
            {goNextTour && <StarOutlined style={{ color: "orange", fontSize: "16px" }} />}
            {!goNextTour && <StarOutlined style={{ fontSize: "16px" }} />}
        </CheckableTag>
        );
    };
    const columns = [
        {
            title: 'Танцор',
            render: (text, record) => {
                return (record.dancerEvent.dancer.lastname + " " + record.dancerEvent.dancer.firstname);
            }
        },
        {
            title: 'Пол',
            render: (text, record) => {
                return (record.dancerEvent.dancer.gender.name);
            }
        },
        {
            title: 'Номер',
            render: (text, record) => {
                return (record.dancerEvent.number);
            }
        },
        {
            title: 'Финалист',
            render: (text, record) => {
                return (
                    star(record, record.goNextTour)
                );
            }
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            fixed: 'right',
            width: 45,
            render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} />),
        },
    ];
    const update = (res) => {
        dispatch(REDUX_DancerTour.actions.updateArrayByKey(object.ID, res.data));
    };
    const alldancers = () => {
        auth.fetch("/api/tour/" + object.ID + "/dancertourgenerate").then(res => {
            if (res && res.data && dispatch) {
                update(res);
            }
        });
    };
    const deletedancers = () => {
        auth.fetch("/api/dropalldancertour/" + object.ID).then(res => {
            if (res && res.status && dispatch) {
                dispatch(REDUX_DancerTour.actions.setArrayByKey(object.ID, []));
            } else if (res && !res.status) {
                message.error(res.message);
            }
        });
    };
    return (
        <>
            <div>
                <Title level={4}>{object.levelEvent.event.name + " (" + object.levelEvent.level.name + "), Тур " + object.number + " (" + object.tourType.name + ")"}</Title>
            </div>

            <Row gutter={[5, 5]} style={{ marginBottom: "15px" }}>
                <Col span={24}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <Button onClick={alldancers}>Добавить всех танцоров</Button>
                        </div>
                        <div>
                            <Button onClick={deletedancers}>Удалить всех танцоров</Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Table loading={loading} pagination={false} columns={columns} dataSource={DancerTour} locale={{ emptyText: <Empty description={"Нет танцоров"}></Empty> }} size="small" rowKey={(r) => r.ID} />
        </>
    );
}
