const build = (meta) => {
    if(!meta) return {};
    
    meta.properties.forEach(field => {
        // relation:
        //     display: {uuid: "", name: "", sep: "", fields: null}
        //     displayFilter: {uuid: "", name: "", sep: "", fields: null}
        //     displayTable: {uuid: "", name: "", sep: "", fields: null}
        //     reference:
        //         object: "RegionalnieOtdeleniya"
        //         property: "ID"
        //     uuid: ""
        //     type: "many-one"
        //     uuid: ""
        // console.log(field.name, field.type, field.label, (field.type==="object")?field.relation:"")
    });
    return {}
};

export default function WorkflowAction(fetch, path, meta, body, callback) {
    return fetch(path, {
        method: 'POST',
        body: JSON.stringify(body(build(meta)))
    }).then(res => callback(res))
}
