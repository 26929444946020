import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Table, Empty, Row, Col, Typography } from 'antd';

import DropdownAction from '../../components/DropdownAction'
import Action from '../../components/Action'

import 'moment/locale/ru';

import REDUX_CriterionParams from '../../objects/CriterionParams/reducer'
import CRUDE_CriterionParams from '../../objects/CriterionParams/crude'

import NewCriterionParams from '../../forms/NewCriterionParams'
import UpdateCriterionParams from '../../forms/UpdateCriterionParams'

const { Title } = Typography;


function Btn(props) {
    const dispatch = useDispatch();
    const { auth, object } = props;
    const CriterionParams = useSelector(REDUX_CriterionParams.selector.arrayByKey(object.criterionID));
    const odelete = () => {
        CRUDE_CriterionParams.destroy(auth, () => {
            dispatch(REDUX_CriterionParams.actions.setArrayByKey(object.criterionID, CriterionParams.filter(item => item.ID !== object.ID)));
        }, object.ID);
    };
    const update = (done, values, unlock) => {
		CRUDE_CriterionParams.update(props.auth, (data) => {
			dispatch(REDUX_CriterionParams.actions.updateArrayByKey(object.criterionID, [data]));
			done();
		}, { ...props.object, ...values });
		if (unlock)
			unlock();
	};
    return (
        <DropdownAction>
            <Action {...props} readonly={false} modal={{ title: "Изменить параметр" }} object={props.object} mode={"MenuItem"} action={update} title={"Изменить"} form={UpdateCriterionParams} document={"query-update/criterionparams"} />
			<Menu.Divider></Menu.Divider>
            <Menu.Item onClick={odelete}>Удалить</Menu.Item>
        </DropdownAction>
    )
};
export default function CriterionParams(props) {
    const dispatch = useDispatch();
    const { auth, object } = props;

    const [loading] = useState(false);

    const CriterionParams = useSelector(REDUX_CriterionParams.selector.arrayByKey(object.ID));
    useEffect(() => {
        CRUDE_CriterionParams.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_CriterionParams.actions.setArrayByKey(object.ID, data));
            }
        }, 'model', 's-priorityInCriterion=ASC&w-criterion.id='+object.ID);
    }, [object,auth, dispatch]);
    const columns = [
        {
            title: 'Приоритет',
            render: (text, record) => {
                return (record.priorityInCriterion);
            }
        },
        {
            title: 'Название',
            render: (text, record) => {
                return (record.name);
            }
        },
        {
            title: 'Мужчины',
            render: (text, record) => {
                return ((record.man)?"Да":"");
            }
        },
        {
            title: 'Женщины',
            render: (text, record) => {
                return ((record.woman)?"Да":"");
            }
        },
        {
            title: 'Максимальная оценка',
            render: (text, record) => {
                return (record.maxScoreValue);
            }
        },
        {
            title: 'Шаг оценки',
            render: (text, record) => {
                return (record.step);
            }
        },
        {
            title: 'Вес',
            render: (text, record) => {
                return (record.weight);
            }
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            fixed: 'right',
            width: 45,
            render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} />),
        },
    ];
    const update = (res) => {
        dispatch(REDUX_CriterionParams.actions.updateArrayByKey(object.ID, [res.data]));
    };
    return (
        <>
            <div>
                <Title level={4}>{"Праметры критерия \""+ object.name+"\""}</Title>
            </div>
            <Row gutter={[5, 5]} style={{ marginBottom: "15px" }}>
                <Col span={24}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Action {...props} action={undefined} readonly={false} callback={update} mode="" dancers={CriterionParams} modal={{ title: "Добавить параметр в критерий \""+ object.name+"\"" }} object={object} title={"Добавить параметр"} form={NewCriterionParams} document={"query-create/CriterionParams"} />
                    </div>
                </Col>
            </Row>
            <Table loading={loading} pagination={false} columns={columns} dataSource={CriterionParams} locale={{ emptyText: <Empty description={"Нет параметров"}></Empty> }} size="small" rowKey={(r) => r.ID} />
        </>
    );
}
