import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Menu, Card, Divider, Row, Col, Empty } from 'antd';

import { createUseStyles } from 'react-jss'

import 'moment/locale/ru';

import Filtered from '../../components/Filtered'
import DropdownAction from '../../components/DropdownAction'
import Action from '../../components/Action'

import REDUX_Judge from '../../objects/Judge/reducer';
import CRUDE_Judge from '../../objects/Judge/crude';

import NewJudge from '../../forms/NewJudge'
import UpdateJudge from '../../forms/UpdateJudge'

function Btn(props) {
	const dispatch = useDispatch();
	const judges = useSelector(REDUX_Judge.selector.array);
	const odelete = () => {
		CRUDE_Judge.destroy(props.auth, () => {
			dispatch(REDUX_Judge.actions.setArray(judges.filter(item => item.ID !== props.object.ID)));
		}, props.object.ID);
	};
	const update = (done, values, unlock) => {
		CRUDE_Judge.update(props.auth, (data) => {
			dispatch(REDUX_Judge.actions.updateArray([data]));
			done();
		}, { ...props.object, ...values });
		if (unlock)
			unlock();
	};
	return (
		<DropdownAction>
			<Action {...props} readonly={false} modal={{ title: "Изменить судью" }} object={props.object} mode={"MenuItem"} action={update} title={"Изменить"} form={UpdateJudge} document={"query-update/judge"} />
			<Menu.Divider></Menu.Divider>
			<Menu.Item onClick={odelete}>Удалить</Menu.Item>
		</DropdownAction>
	)
};

const useStyles = createUseStyles({
	divider: {
		'&.ant-divider-horizontal.ant-divider-with-text-left': {
			fontFamily: "-apple-system, BlinkMacSystemFont, Roboto, 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif",
			fontSize: "13px",
			fontWeight: "400",
			color: "rgb(0, 0, 0)",
		},
		'&.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text': {
			padding: "0px",
			marginRight: "10px",
		}
	}
})

export default function CommonJudge(props) {
	const classes = useStyles()
	const dispatch = useDispatch();
	const { auth } = props;
	const [loading, setLoading] = useState(false);
	const filters = [
		{
			name: "Фамилия",
			value: item => item.lastname,
			field: "lastname",
			type: "string",
			sort: true,
			filter: true,
			sorted: "ASC"
		},
		{
			name: "Имя",
			value: item => item.firstname,
			field: "firstname",
			type: "string",
			sort: true,
			filter: true
		},
		{
			name: "Отчество",
			value: item => item.patronymic,
			field: "patronymic",
			type: "string",
			sort: true,
			filter: true
		},
		{
			name: "Пол",
			value: item => item.gender.name,
			field: "gender",
			type: "string",
			sort: true,
			filter: true
		}
	];
	useEffect(() => {
		setLoading(true);
		CRUDE_Judge.read(auth, (data) => {
			setLoading(false);
			if (data.length > 0) {
				dispatch(REDUX_Judge.actions.setArray(data));
			}
		}, 'model');
	}, [auth, dispatch]);

	const data = useSelector(REDUX_Judge.selector.array);
	const update = (res) => {
		dispatch(REDUX_Judge.actions.updateArray([res.data]));
	};
	const columns = [
		{
			title: 'Фамилия',
			render: (text, record) => {
				return (record.lastname);
			}
		},
		{
			title: 'Имя',
			render: (text, record) => {
				return (record.firstname);
			}
		},
		{
			title: 'Отчество',
			render: (text, record) => {
				return (record.patronymic);
			}
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			fixed: 'right',
			width: 45,
			render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} />),
		},
	];
	return (
		<>
			<Filtered title={"Судьи"} filters={filters} data={data} content={items => (
				<Card size="small" className={classes.block} style={{ width: "100%" }}>
					<Divider className={classes.divider} type="horizontal" orientation="left">
						Судьи
                    </Divider>
					<Table loading={loading} pagination={false} columns={columns} dataSource={items} locale={{ emptyText: <Empty description={"Нет судей"}></Empty> }} size="small" rowKey={(r) => r.ID} />
				</Card>
			)}
				addition={
					(
						<Row justify="start">
							{/* <Col flex="auto">
							</Col> */}
							<Col flex="50px">
								<Action {...props} callback={update} title={"Новый судья"} form={NewJudge} document={"query-create/judge"} />
							</Col>
						</Row>
					)
				} />
		</>
	);
}
