import React, { Component } from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomeMobile from './pages/HomeMobile'
import Login from './pages/Login'

import withAuth from './components/withAuth';
import { Provider } from 'react-redux';

import configureStore from './store/configureStore';
const store = configureStore();


// https://kamranicus.com/posts/2018-07-26-react-router-custom-transition-ui
const getUserConfirmation = (message, callback) => {
	console.log(message);
	callback(false);
}

class AppJudge extends Component {
	render() {
		return (
			<div>
				<Provider store={store}>
					<Router getUserConfirmation={getUserConfirmation}>
						<Switch>
							<Route path="/login" component={Login} exact />
							<Route path="/"  component={withAuth(HomeMobile)} />
						</Switch>
					</Router>
				</Provider>
			</div>
		)
	}
}

export default AppJudge;