export function GetMetaProperties(meta) {
    var xmeta = GetMeta(meta)
    if(typeof xmeta === "object" && !Array.isArray(xmeta)) {
        return xmeta.properties;
    } else if(typeof xmeta === "object" && Array.isArray(xmeta)) {
        return xmeta;
    } else {
        console.warn("Не удалось определить метаданные из переданного параметра");
    }
};

export function GetMeta(meta) {
    if(meta && meta !== null && typeof meta === "function"){
        let m = meta();
        if(m && m !== null && typeof m === "function"){
            return GetMeta(m);
        } else if(typeof m === "object"){
            return m;
        }
    }
    return meta;
};