import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Select, InputNumber } from 'antd';

import 'moment/locale/ru';

import REDUX_TourType from '../../objects/TourType/reducer'
import CRUDE_TourType from '../../objects/TourType/crude'

import REDUX_Tour from '../../objects/Tour/reducer'
import CRUDE_Tour from '../../objects/Tour/crude'

import REDUX_ProtocolValueType from '../../objects/ProtocolValueType/reducer'
import CRUDE_ProtocolValueType from '../../objects/ProtocolValueType/crude'

import REDUX_Template from '../../objects/Template/reducer'
import CRUDE_Template from '../../objects/Template/crude'

const { Option } = Select;

export default function UpdateTour(props) {
    const { auth, object } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        CRUDE_TourType.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_TourType.actions.setArray(data));
            }
        }, 'model', 's-id=ASC');
    }, [auth, dispatch]);
    const protocolValueType = useSelector(REDUX_ProtocolValueType.selector.array);
    useEffect(() => {
        CRUDE_ProtocolValueType.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_ProtocolValueType.actions.setArray(data));
            }
        }, 'model', 's-id=ASC');
    }, [auth, dispatch]);
    const protocolValueTypeOptions = protocolValueType.map(i => (
        <Option key={i.ID} value={i.ID}>{i.name}</Option>
    ));
    const template = useSelector(REDUX_Template.selector.array);
    useEffect(() => {
        CRUDE_Template.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_Template.actions.setArray(data));
            }
        }, 'model', 's-id=ASC');
    }, [auth, dispatch]);
    const optionsTemplate = template.map(i => (
        <Option key={i.ID} value={i.ID}>{i.name}</Option>
    ));
    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });
    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{
                templateID: object.templateID,
                protocolValueTypeID: object.protocolValueTypeID,
                scoresInCriterionM: object.scoresInCriterionM,
                scoresInCriterionW: object.scoresInCriterionW,
                topScoreIgnore: object.topScoreIgnore,
                bottomScoreIgnore: object.bottomScoreIgnore,
                maxCountEqualScores: object.maxCountEqualScores
                // done: object.done,
                // prevTourID: object.prevTourID,
                // tourTypeID: object.tourTypeID,
                // levelEventID: object.levelEventID,
            }}
        >
            {/* <Form.Item
                preserve={"true"}
                hidden={true}
                name={"levelEventID"}
                label={"Мероприятие"}
                rules={[{ required: true, message: 'Укажите мероприятие!' }]}
            >
                <Input disabled />
            </Form.Item>
            <Form.Item
                preserve={"true"}
                hidden={true}
                name={"prevTourID"}
                label={"Предыдущий тур"}
            >
                <Select showSearch
                    onChange={prevtourChange}
                    allowClear
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {prevtourOptions}
                </Select>
            </Form.Item>
            <Form.Item
                preserve={"true"}
                hidden={true}
                name={"number"}
                label={"Номер"}
                rules={[{ required: true, message: 'Укажите номер!' }]}
            >
                <InputNumber />
            </Form.Item>
            <Form.Item
                preserve={"true"}
                hidden={true}
                name={"tourTypeID"}
                label={"Тип тура"}
                rules={[{ required: true, message: 'Укажите тип тура!' }]}
            >
                <Select showSearch
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {tourtypeOptions}
                </Select>
            </Form.Item> */}
            <Form.Item
                name={"templateID"}
                label={"Шаблон критериев"}
                rules={[{ required: true, message: 'Укажите шаблон критериев!' }]}
            >
                <Select showSearch
                disabled={!!object.templateCloneID}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {optionsTemplate}
                </Select>
            </Form.Item>
            <Form.Item
                name={"protocolValueTypeID"}
                label={"Тип значения"}
                rules={[{ required: true, message: 'Укажите тип значения протокола!' }]}
            >
                <Select showSearch
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {protocolValueTypeOptions}
                </Select>
            </Form.Item>
            <Form.Item
                name={"scoresInCriterionM"}
                label={"Количество плюсов в протоколе (муж)"}
            >
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item
                name={"scoresInCriterionW"}
                label={"Количество плюсов в протоколе (жен)"}
            >
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item
                name={"maxCountEqualScores"}
                label={"Максимально количество одинаковых оценок"}
            >
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item
                name={"topScoreIgnore"}
                label={"Количество игнорируемых максимальных оценок"}
            >
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item
                name={"bottomScoreIgnore"}
                label={"Количество игнорируемых минимальных оценок"}
            >
                <InputNumber min={0} />
            </Form.Item>
        </Form>
    );
}
