import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Menu, Card, Divider, Row, Col, Modal, Select, Empty } from 'antd';

import {
	ExclamationCircleOutlined} from '@ant-design/icons';
import { createUseStyles } from 'react-jss'

import 'moment/locale/ru';

import Filtered from '../../components/Filtered'
import DropdownAction from '../../components/DropdownAction'
import Action from '../../components/Action'

import REDUX_Event from '../../objects/Event/reducer';
import CRUDE_Event from '../../objects/Event/crude';

import REDUX_Command from '../../objects/Command/reducer';
import CRUDE_Command from '../../objects/Command/crude';

import NewCommand from '../../forms/NewCommand'

import DancerCommand from '../../forms/DancerCommand'

const { confirm } = Modal;
const { Option } = Select;

function Btn(props) {
	const dispatch = useDispatch();
	const { auth, object } = props;
	const empty = (done) => {
		if (done) done();
	};
	const commands = useSelector(REDUX_Command.selector.arrayByKey(object.event.ID));
	const odelete = () => {
		CRUDE_Command.destroy(auth, () => {
			dispatch(REDUX_Command.actions.setArrayByKey(object.event.ID, commands.filter(item => item.ID !== object.ID)));
		}, props.object.ID);
	};
	return (
		<DropdownAction>
			<Action {...props} readonly={true} modal={{ title: "Танцоры команды", width: "65%" }} object={object} mode={"MenuItem"} action={empty} title={"Танцоры команды"} form={DancerCommand} document={"dancercommand"} />
			{/* <Action {...props} readonly={true} modal={{ title: "Судьи группы", width: "65%" }} object={object} mode={"MenuItem"} action={empty} title={"Судьи группы"} form={JudgeEvent} document={"judgeevent"} /> */}
			{/* <Menu.Item><a target="_blank" href={"/levelevent/" + ((object) ? object.ID : 0) + "/printresultevent"}>Результаты</a></Menu.Item> */}
			<Menu.Divider></Menu.Divider>
			<Menu.Item style={{color: "red"}} onClick={()=> confirm({
				title: 'Вы действительно хотите удалить команду?',
				icon: <ExclamationCircleOutlined />,
				content: 'Удаление команды приведет к потере всех связанных с ней данных',
				okText: 'Удалить',
				cancelText: 'Отмена',
				onOk() {
					odelete();
				},
				onCancel() {
				},
			})}>Удалить</Menu.Item>
		</DropdownAction>
	)
};

const useStyles = createUseStyles({
	divider: {
		'&.ant-divider-horizontal.ant-divider-with-text-left': {
			fontFamily: "-apple-system, BlinkMacSystemFont, Roboto, 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif",
			fontSize: "13px",
			fontWeight: "400",
			color: "rgb(0, 0, 0)",
		},
		'&.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text': {
			padding: "0px",
			marginRight: "10px",
		}
	}
})

export default function CommonCommands(props) {
	const classes = useStyles()
	const dispatch = useDispatch();
	const { auth } = props;
	const [loading, setLoading] = useState(false);
	const filters = [
		{
			name: "Название",
			value: item => item.name,
			field: "name",
			type: "string",
			sort: true,
			filter: true
		}
	];
	const events = useSelector(REDUX_Event.selector.array);
	const [event, setEvent] = useState((events.length <= 0) ? undefined : events[0]);
	const eventChange = (value) => {
		setEvent(events.find(item => item.ID === value));
	};
	useEffect(() => {
		setLoading(true);
		CRUDE_Event.read(auth, (data) => {
			setLoading(false);
			if (data.length > 0) {
				dispatch(REDUX_Event.actions.setArray(data));
			}
		}, 'model');
	}, [auth, dispatch]);
	useEffect(() => {
		setLoading(true);
		CRUDE_Command.read(auth, (data) => {
			setLoading(false);
			if (data.length > 0) {
				dispatch(REDUX_Command.actions.setArrayByKey((event)?event.ID:0, data));
			}
		}, 'model',"s-id=ASC&w-event.id="+((event)?event.ID:0));
	}, [event,auth, dispatch]);
	
	useEffect(() => {
		if (!event) {
			setEvent((events.length <= 0) ? undefined : events[0]);
		}
	}, [events, event]);
	const optionsEvents = events.map(item => {
		return (
			<Option value={item.ID} key={item.ID}>{item.name}</Option>
		);
	});

	const data = useSelector(REDUX_Command.selector.arrayByKey((event)?event.ID:0));
	const update = (res) => {
		dispatch(REDUX_Command.actions.updateArrayByKey((event)?event.ID:0,[res.data]));
	};
	const columns = [
		{
			title: 'Название',
			render: (text, record) => {
				return (record.name);
			}
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			fixed: 'right',
			width: 45,
			render: (text, record, index) => (<Btn auth={auth} index={index + 1} object={record} />),
		},
	];
	return (
		<>
			<Filtered title={"Команды"} filters={filters} data={data} content={items => (
				<Card size="small" className={classes.block} style={{ width: "100%" }}>
					<Divider className={classes.divider} type="horizontal" orientation="left">
						Команды
                    </Divider>
					<Table showHeader={false} loading={loading} pagination={false} columns={columns} dataSource={items} locale={{ emptyText: <Empty description={"Нет команд"}></Empty> }} size="small" rowKey={(r) => r.ID} />
				</Card>
			)}
				addition={
					(
						<>
						<Row justify="start">
							<Col flex="auto">
								<Select value={(event) ? event.ID : undefined} style={{ width: "100%" }} onChange={eventChange}>
									{optionsEvents}
								</Select>
							</Col>
							<Col flex="50px" style={{paddingLeft: "10px"}}>
								{event && <Action {...props} object={event} callback={update} title={"Новая команда"} form={NewCommand} document={"query-create/command"} />}
							</Col>
						</Row>
					</>
					)
				} />
		</>
	);
}
