import React, { useEffect } from 'react';
import { Form, Input, InputNumber } from 'antd';

import 'moment/locale/ru';

export default function UpdateEvent(props) {
    const { object } = props;
    useEffect(() => {
        if (props.form) {
            props.form.resetFields();
        }
    });
    return (
        <Form
            form={props.form}
            onFinish={props.submit}
            labelAlign={"left"}
            layout={"vertical"}
            initialValues={{
                name: object.name || "",
                countDancerInResult: object.countDancerInResult
            }}
        >
            <Form.Item
                name={"name"}
                label={"Название"}
                rules={[{ required: true, message: 'Укажите название!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"countDancerInResult"}
                label={"Количество танцоров в оценке команды"}
                rules={[{ required: true, message: 'Укажите количество танцоров в оценке команды!' }]}
                // tooltip={{
                //     title: 'Укажите 0 для учета всех танцоров в оценке команды',
                //     icon: <InfoCircleOutlined />,
                // }}
            >
                <InputNumber min={0}/>
            </Form.Item>
        </Form>
    );
}
