import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss'

import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import {
    Form,
    Input,
    Select,
    Checkbox,
    DatePicker,
    InputNumber} from 'antd';
import { GetMeta, GetMetaProperties } from './Meta';

const { Option } = Select;

const useStyles = createUseStyles({
    FormItem: {
        // width: "100%",
    },
    Field: {},
    Unknown: {},
    Obj: {},
    Time: {},
    Boolean: {},
    Float: {},
    Integer: {},
    String: {},
    Frm: {},
})

function Unknown({ item }) {
    return (
        <div key={item.name}>
            {item.label} - {item.name}
        </div>
    )
}

const Read = (auth, callback, detail, filter, name) => {
    if (!detail) detail = 'none';
    filter = (!filter) ? '' : '&' + filter;
    auth.fetch('/api/query/' + name + '?detail=' + detail + filter).then(res => {
        if (res && res.data) {
            if (res.status===true && callback) {
                callback(res.data);
            }
        }
    });
};

function Obj({ auth, item, options }) {
    const [data, setData] = useState([]);
    useEffect(() => {
        Read(auth, (data) => {
            if (data.length > 0) {
                setData(data);
            }
        }, options.detail || 'model', options.filter || '', item.name);
    }, [options, auth, item]);
    const elements = data.map(i => (
        <Option key={i.ID} value={i.ID}>{i.name}</Option>
    ));
    return (
        <Form.Item
            key={item.name}
            name={item.name}
            label={item.label}
            rules={[{ required: item.required, message: 'Укажите ' + item.label.toLowerCase() + '!' }]}
        >
            <Select showSearch
                filterOption={(input, element) =>
                    element.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {elements}
            </Select>
        </Form.Item>
    )
}

function Time({ item }) {
    const classes = useStyles()
    return (
        <Form.Item className={classes.FormItem}
            key={item.name}
            name={item.name}
            label={item.label}
            rules={[{ required: item.required, message: 'Укажите ' + item.label.toLowerCase() + '!' }]}
        >
            <DatePicker format="DD.MM.YYYY" locale={locale} />
        </Form.Item>
    )
}

function Boolean({ item }) {
    const classes = useStyles()
    return (
        <Form.Item className={classes.FormItem}
            key={item.name}
            name={item.name}
            rules={[{ required: item.required, message: 'Укажите ' + item.label.toLowerCase() + '!' }]}
        >
            <Checkbox>
                {item.label}
            </Checkbox>
        </Form.Item>
    )
}

function Float({ item }) {
    const classes = useStyles()
    return (
        <Form.Item className={classes.FormItem}
            key={item.name}
            name={item.name}
            label={item.label}
            rules={[{ required: item.required, message: 'Укажите ' + item.label.toLowerCase() + '!' }]}
        >
            <InputNumber />
        </Form.Item>
    )
}

function Integer({ item }) {
    const classes = useStyles()
    return (
        <Form.Item className={classes.FormItem}
            key={item.name}
            name={item.name}
            label={item.label}
            rules={[{ required: item.required, message: 'Укажите ' + item.label.toLowerCase() + '!' }]}
        >
            <InputNumber style={{ width: "100%" }} />
        </Form.Item>
    )
}

function String({ item }) {
    const classes = useStyles()
    return (
        <Form.Item className={classes.FormItem}
            key={item.name}
            name={item.name}
            label={item.label}
            rules={[{ required: item.required, message: 'Укажите ' + item.label.toLowerCase() + '!' }]}
        >
            <Input />
        </Form.Item>
    )
}

function Field({ auth, form, meta, item, options }) {
    const classes = useStyles()
    switch (item.type) {
        case "string":
            return (<String className={classes.String} auth={auth} form={form} meta={meta} item={item} options={options}></String>)
        case "int":
            return (<Integer className={classes.Integer} auth={auth} form={form} meta={meta} item={item} options={options}></Integer>)
        case "uint":
            return (<Integer className={classes.Integer} auth={auth} form={form} meta={meta} item={item} options={options}></Integer>)
        case "integer":
            return (<Integer className={classes.Integer} auth={auth} form={form} meta={meta} item={item} options={options}></Integer>)
        case "int64":
            return (<Integer className={classes.Integer} auth={auth} form={form} meta={meta} item={item} options={options}></Integer>)
        case "int32":
            return (<Integer className={classes.Integer} auth={auth} form={form} meta={meta} item={item} options={options}></Integer>)
        case "uint64":
            return (<Integer className={classes.Integer} auth={auth} form={form} meta={meta} item={item} options={options}></Integer>)
        case "uint32":
            return (<Integer className={classes.Integer} auth={auth} form={form} meta={meta} item={item} options={options}></Integer>)
        case "double":
            return (<Float className={classes.Float} auth={auth} form={form} meta={meta} item={item} options={options}></Float>)
        case "float":
            return (<Float className={classes.Float} auth={auth} form={form} meta={meta} item={item} options={options}></Float>)
        case "float64":
            return (<Float className={classes.Float} auth={auth} form={form} meta={meta} item={item} options={options}></Float>)
        case "float32":
            return (<Float className={classes.Float} auth={auth} form={form} meta={meta} item={item} options={options}></Float>)
        case "boolean":
            return (<Boolean className={classes.Boolean} auth={auth} form={form} meta={meta} item={item} options={options}></Boolean>)
        case "bool":
            return (<Boolean className={classes.Boolean} auth={auth} form={form} meta={meta} item={item} options={options}></Boolean>)
        case "time":
            return (<Time className={classes.Time} auth={auth} form={form} meta={meta} item={item} options={options}></Time>)
        case "date":
            return (<Time className={classes.Time} auth={auth} form={form} meta={meta} item={item} options={options}></Time>)
        case "datetime":
            return (<Time className={classes.Time} auth={auth} form={form} meta={meta} item={item} options={options}></Time>)
        case "time.Time":
            return (<Time className={classes.Time} auth={auth} form={form} meta={meta} item={item} options={options}></Time>)
        case "object":
            return (<Obj className={classes.Obj} auth={auth} form={form} meta={meta} item={item} options={options}></Obj>)
        default:
            return (<Unknown className={classes.Unknown} auth={auth} form={form} meta={meta} item={item} options={options}></Unknown>)
    }
}

function Frm({ auth, form, meta, options }) {
    const classes = useStyles()
    var properties = GetMetaProperties(meta);
    // console.log(properties);
    if (!properties) return <></>;
    return (
        <Form form={form}
            // onFinish={props.submit}
            {...options}
            labelAlign={"left"}
            layout={"vertical"}>
            {properties.map((item) =>
                <Field className={classes.Field} key={item.name} auth={auth} form={form} meta={meta} item={item} options={options}></Field>
            )}
        </Form>
    )
}

export default function ({ auth, meta, options }) {
    const classes = useStyles()
    const [form] = Form.useForm();
    // const closePopup = useCallback(() => {
    // form.resetFields();
    // setOpened(false);
    // }, [form]);
    // useEffect(()=>{
    // form.resetFields();
    // });

    var xmeta = GetMeta(meta);
    if (!xmeta) return <></>;
    return (
        <>
            {<Frm className={classes.Frm} auth={auth} form={form} meta={meta} options={options}></Frm>}
        </>
    )
};