import React from 'react';
import { useSelector } from 'react-redux';
import { Space, Avatar, Button, Card, Row, Col, Typography, Empty } from 'antd';

import {
	SettingOutlined,
	UserOutlined} from '@ant-design/icons';

import 'moment/locale/ru';


import Model from '../../components/AbstractBuilder/Model'

const { Title, Paragraph, Text } = Typography;


export default function CommonMain(props) {
	const { auth } = props;

	const city = useSelector(state => (state.meta) ? state.meta.city : undefined);

	if (!city) return <></>;
	return (
		<>
			<Card size="small" style={{ padding: "10px", marginBottom: "10px" }}>
				<Empty
					image="../../party-3.svg"
					imageStyle={{
						height: 60,
					}}
					description={
						<span>
							Проведем крутое мероприятие?
						</span>
					}
					style={{ padding: "100px 0px" }}
				>
					<Button type="primary">Погнали!</Button>
				</Empty>
				<Row>
					<Col span={24}>
						<Title style={{ textAlign: "center" }} level={2}>I Этап Осеннего Кубка "Bachata Autumn Cup 2020"</Title>
					</Col>
				</Row>
				<Row>
					<Col span={8}>
						<Title style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.85)" }} level={4}>Новички</Title>
					</Col>
					<Col span={8}>
						<Title style={{ textAlign: "center", color: "#d8d8d8" }} level={4}>Любители</Title>
					</Col>
					<Col span={8}>
						<Title style={{ fontSize: "18px", color: "#d8d8d8" }}>Добавить группу</Title>
						<Button type="dashed" block>+</Button>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Title style={{ fontSize: "18px" }}>Тур 1</Title>
					</Col>
				</Row>
				<Row style={{ padding: "0px 0px 25px 0px" }}>
					<Col span={12} style={{ padding: "5px 0px" }}>
						<div style={{ display: "flex", justifyContent: "left"}}>
							<Text strong>Заход 1</Text>
							<Button style={{width: "20px", height: "20px"}} type="dashed" block><SettingOutlined/></Button>
						</div>
						<div style={{ padding: "5px 0px" }}>
							{[1, 2, 3].map(() => {
								return (
									<>
										<div style={{ padding: "2.5px 0px" }}>
											<Avatar size="small" style={{ backgroundColor: "deeppink" }} icon={<UserOutlined />} />
											<Text style={{ paddingLeft: "8px" }}>Якушева Юлия</Text>
										</div>
										<div style={{ padding: "2.5px 0px" }}>
											<Avatar size="small" style={{ backgroundColor: "dodgerblue" }} icon={<UserOutlined />} />
											<Text style={{ paddingLeft: "8px" }}>Щепкин Владислав</Text>
										</div>
										<div style={{ padding: "2.5px 0px" }}>
											<Avatar size="small" icon={<UserOutlined />} />
											<Text style={{ paddingLeft: "8px" }}>Толкачева Анастасия</Text>
										</div>
										<div style={{ padding: "2.5px 0px" }}>
											<Avatar size="small" icon={<UserOutlined />} />
											<Text style={{ paddingLeft: "8px" }}>Сироха Александр</Text>
										</div>
										<div style={{ padding: "2.5px 0px" }}>
											<Avatar size="small" icon={<UserOutlined />} />
											<Text style={{ paddingLeft: "8px" }}>Толкачева Анастасия</Text>
										</div>
										<div style={{ padding: "2.5px 0px" }}>
											<Avatar size="small" icon={<UserOutlined />} />
											<Text style={{ paddingLeft: "8px" }}>Сироха Александр</Text>
										</div>
									</>
								)
							})}
						</div>
					</Col>
					<Col span={12} style={{ padding: "5px 0px" }}>
						<Text strong>Заход 2</Text>
						<div style={{ padding: "5px 0px" }}>
							<div style={{ padding: "2.5px 0px" }}>
								<Avatar size="small" style={{ backgroundColor: "deeppink" }} icon={<UserOutlined />} />
								<Text style={{ paddingLeft: "8px" }}>Якушева Юлия</Text>
							</div>
							<div style={{ padding: "2.5px 0px" }}>
								<Avatar size="small" style={{ backgroundColor: "dodgerblue" }} icon={<UserOutlined />} />
								<Text style={{ paddingLeft: "8px" }}>Щепкин Владислав</Text>
							</div>
							<div style={{ padding: "2.5px 0px" }}>
								<Avatar size="small" icon={<UserOutlined />} />
								<Text style={{ paddingLeft: "8px" }}>Толкачева Анастасия</Text>
							</div>
							<div style={{ padding: "2.5px 0px" }}>
								<Avatar size="small" icon={<UserOutlined />} />
								<Text style={{ paddingLeft: "8px" }}>Сироха Александр</Text>
							</div>
						</div>
					</Col>
					<Col span={12} style={{ padding: "5px 0px" }}>
						<Text strong>Заход 3</Text>
						<div style={{ padding: "5px 0px" }}>
							<div style={{ padding: "2.5px 0px" }}>
								<Avatar size="small" icon={<UserOutlined />} />
								<Text style={{ paddingLeft: "8px" }}>Якушева Юлия</Text>
							</div>
							<div style={{ padding: "2.5px 0px" }}>
								<Avatar size="small" icon={<UserOutlined />} />
								<Text style={{ paddingLeft: "8px" }}>Щепкин Владислав</Text>
							</div>
							<div style={{ padding: "2.5px 0px" }}>
								<Avatar size="small" icon={<UserOutlined />} />
								<Text style={{ paddingLeft: "8px" }}>Толкачева Анастасия</Text>
							</div>
							<div style={{ padding: "2.5px 0px" }}>
								<Avatar size="small" icon={<UserOutlined />} />
								<Text style={{ paddingLeft: "8px" }}>Сироха Александр</Text>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Title style={{ fontSize: "18px" }}>Тур 2</Title>
					</Col>
				</Row>
				<Row style={{ padding: "0px 0px 25px 0px" }}>
					<Col span={12} style={{ padding: "5px 0px" }}>
						<Text strong>Заход 1</Text>
						<div style={{ padding: "5px 0px" }}>
							<div style={{ padding: "2.5px 0px" }}>
								<Avatar size="small" icon={<UserOutlined />} />
								<Text style={{ paddingLeft: "8px" }}>Якушева Юлия</Text>
							</div>
							<div style={{ padding: "2.5px 0px" }}>
								<Avatar size="small" icon={<UserOutlined />} />
								<Text style={{ paddingLeft: "8px" }}>Щепкин Владислав</Text>
							</div>
						</div>
					</Col>
					<Col span={12} style={{ padding: "5px 0px" }}>
						<Text strong>Заход 2</Text>
						<div style={{ padding: "5px 0px" }}>
							<div style={{ padding: "2.5px 0px" }}>
								<Avatar size="small" icon={<UserOutlined />} />
								<Text style={{ paddingLeft: "8px" }}>Толкачева Анастасия</Text>
							</div>
							<div style={{ padding: "2.5px 0px" }}>
								<Avatar size="small" icon={<UserOutlined />} />
								<Text style={{ paddingLeft: "8px" }}>Сироха Александр</Text>
							</div>
						</div>
					</Col>
				</Row>
			</Card>
			<Card size="small" style={{ padding: "10px" }}>
				<Model auth={auth} meta={city} options={{}}></Model>
			</Card>
			<Card size="small">
				<Title>h1. Ant Design</Title>
				<Title level={2}>h2. Ant Design</Title>
				<Title level={3}>h3. Ant Design</Title>
				<Title level={4}>h4. Ant Design</Title>
				<Space direction="vertical">
					<Text>Ant Design (default)</Text>
					<Text type="secondary">Ant Design (secondary)</Text>
					<Text type="success">Ant Design (success)</Text>
					<Text type="warning">Ant Design (warning)</Text>
					<Text type="danger">Ant Design (danger)</Text>
					<Text disabled>Ant Design (disabled)</Text>
					<Text mark>Ant Design (mark)</Text>
					<Text code>Ant Design (code)</Text>
					{/* <Text keyboard>Ant Design (keyboard)</Text> */}
					<Text underline>Ant Design (underline)</Text>
					<Text delete>Ant Design (delete)</Text>
					<Text strong>Ant Design (strong)</Text>
					{/* <Link href="https://ant.design" target="_blank">
						Ant Design (Link)
    				</Link> */}
				</Space>
				<Paragraph ellipsis>
					Ant Design, a design language for background applications, is refined by Ant UED Team. Ant
					Design, a design language for background applications, is refined by Ant UED Team. Ant Design,
					a design language for background applications, is refined by Ant UED Team. Ant Design, a
					design language for background applications, is refined by Ant UED Team. Ant Design, a design
					language for background applications, is refined by Ant UED Team. Ant Design, a design
					language for background applications, is refined by Ant UED Team.
    			</Paragraph>
				<Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
					Ant Design, a design language for background applications, is refined by Ant UED Team. Ant
					Design, a design language for background applications, is refined by Ant UED Team. Ant Design,
					a design language for background applications, is refined by Ant UED Team. Ant Design, a
					design language for background applications, is refined by Ant UED Team. Ant Design, a design
					language for background applications, is refined by Ant UED Team. Ant Design, a design
					language for background applications, is refined by Ant UED Team.
    			</Paragraph>
				<Row gutter={[8, 8]}>
					<Col span={4} />
					<Col span={4} />
					<Col span={4} />
					<Col span={4} />
					<Col span={4} />
					<Col span={4} />
				</Row>
				<Row gutter={[8, 8]}>
					<Col span={4} />
					<Col span={4} />
					<Col span={4} />
					<Col span={4} />
					<Col span={4} />
					<Col span={4} />
				</Row>
			</Card>
		</>
	);
}
