import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Transfer, Switch, Table, Empty, Typography, Card, message } from 'antd';
import { createUseStyles } from 'react-jss'

import Filtered from '../../components/Filtered'
import FilteredTransfer from '../../components/FilteredTransfer'

import 'moment/locale/ru';

import REDUX_DancerCommand from '../../objects/DancerCommand/reducer'
import CRUDE_DancerCommand from '../../objects/DancerCommand/crude'

import REDUX_DancerEvent from '../../objects/DancerEvent/reducer'
import CRUDE_DancerEvent from '../../objects/DancerEvent/crude'

import { POST } from '../../components/Tool'

const { Title } = Typography;

const useStyles = createUseStyles({
    transfer: {
        '&.ant-transfer': {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        '&.ant-transfer .ant-transfer-list ': {
            flex: "auto",
            height: "500px",
        }
    }
})

export default function DancerCommand(props) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const { auth, object } = props;

    const [loading, setLoading] = useState(false);

    const dancercommand = useSelector(REDUX_DancerCommand.selector.arrayByKey(object.ID));

    const [editing, setEditing] = useState(false);

    const dancerevent = useSelector(REDUX_DancerEvent.selector.arrayByKey(object.eventID));
    useEffect(() => {
        CRUDE_DancerCommand.read(auth, (data) => {
            if (data.length > 0) {
                dispatch(REDUX_DancerCommand.actions.setArrayByKey(object.ID, data));
            }
        }, 'model', 's-dancerEvent.dancer.lastname=ASC&s-dancerEvent.dancer.firstname=ASC&w-command.id=' + object.ID);
    }, [object, auth, dispatch]);

    const DancerEventName = (item) => {
        return item.dancer.lastname + " " + item.dancer.firstname + " (" + item.dancer.level.name + ")";
    };
    const columns = [
        {
            title: 'Танцор',
            render: (text, record) => {
                return (DancerEventName(record.dancerEvent));
            }
        }
    ];
    const filters = [
        {
            name: "Танцор",
            value: item => DancerEventName(item.dancerEvent),
            field: "name",
            type: "string",
            sort: true,
            filter: true,
            sorted: "ASC"
        }
    ];
    const filtersDancerEvent = [
        {
            name: "Танцор",
            value: item => DancerEventName(item),
            field: "name",
            type: "string",
            sort: true,
            filter: true,
            sorted: "ASC"
        }
    ];

    const triggerEditing = edit => {
        setEditing(edit);
    };
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
                <Title level={4} style={{ marginBottom: "0px" }}>{object.name}</Title>
                <div>
                    <Switch
                        unCheckedChildren="Редактировать"
                        checkedChildren="Редактировать"
                        checked={editing}
                        onChange={triggerEditing}
                    />
                </div>
            </div>
            {editing &&
                <FilteredTransfer
                    auth={auth}
                    filters={filtersDancerEvent}
                    REDUX_Source={REDUX_DancerEvent}
                    REDUX_Target={REDUX_DancerCommand}
                    CRUDE_Source={CRUDE_DancerEvent}
                    CRUDE_Target={CRUDE_DancerCommand}
                    FilterSource={'s-dancer.lastname=ASC&s-dancer.firstname=ASC&w-levelEvent.event.ID=' + object.eventID}
                    FilterTarget={'s-dancerEvent.dancer.lastname=ASC&s-dancerEvent.dancer.firstname=ASC&w-command.id=' + object.ID}
                    dropUrl={"/api/dropdancercommand"}
                    addUrl={"/api/adddancercommand/command/" + object.ID}
                    getSourceIDInTarget={(item) => item.dancerEvent.ID}
                    getSourceID={(item) => item.ID}
                    Key={object.ID}
                    titlesTransfer={["Все танцоры", 'Танцоры в команде']}
                    localeTransfer={{ itemUnit: "танцор", itemsUnit: "танцоры", notFoundContent: "Нет танцоров", searchPlaceholder: "Поиск" }}
                    renderTransfer={item => DancerEventName(item)}
                />
            }

            {!editing && <Filtered title={object.name} filters={filters} data={dancercommand} content={items => (
                <Card size="small" className={classes.block} style={{ width: "100%" }}>
                    <Table loading={loading} pagination={false} columns={columns} dataSource={items} locale={{ emptyText: <Empty description={"Нет танцоров"}></Empty> }} size="small" rowKey={(r) => r.ID} />
                </Card>
            )}
                addition={
                    (
                        <>
                            <div justify="start">
                            </div>
                        </>
                    )
                } />}

        </>
    );
}
